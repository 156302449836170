import $api from "../http";

export default class SettingsService {

    static async getSettings(fields) {
        return await $api.get('/settings/read', { params: { keys: fields }});
    }

    static async setSettings(data) {
        return await $api.post('/settings/update', { settings: data});
    }
}