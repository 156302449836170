import React, {useState} from 'react';
import RegisterStep1 from "./RegisterStep1";
import RegisterStep2 from "./RegisterStep2";
import RegisterStep3 from "./RegisterStep3";
import RegisterStep4 from "./RegisterStep4";

const RegisterContent = () => {


    //Получение данных
    const nullItem = {
        ref: Number( localStorage.getItem('REF_USER_ID') ?? '0' ),
        email: '',
        phone: '',
        last_name: '',
        first_name: '',
        password: '',
        password_confirmation: '',
    };

    const [item, setItem] = useState(nullItem);


    //Шаг регистрации
    const [step, setStep] = useState(1);


    //Вывод компонента
    return (
        <div id="p_login_reg">

            <div id="p_login_reg_fon"></div>

            <div id="p_login_reg_form">
                {
                    step === 1
                        ?
                        <RegisterStep1
                            item={item}
                            setItem={setItem}
                            step={step}
                            setStep={setStep}
                        />
                        :
                        <></>
                }
                {
                    step === 2
                        ?
                        <RegisterStep2
                            item={item}
                            setItem={setItem}
                            step={step}
                            setStep={setStep}
                        />
                        :
                        <></>
                }
                {
                    step === 3
                        ?
                        <RegisterStep3
                            item={item}
                            setItem={setItem}
                            step={step}
                            setStep={setStep}
                        />
                        :
                        <></>
                }
                {
                    step === 4
                        ?
                        <RegisterStep4
                            item={item}
                            setItem={setItem}
                            step={step}
                            setStep={setStep}
                        />
                        :
                        <></>
                }
            </div>

            <div id="p_login_form_copy">
                ⓒ 2023
            </div>

        </div>
    );
};

export default RegisterContent;