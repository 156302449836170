import React from 'react';
import ManagementAnalyticsContent from "../../../components/Pages/Management/Analytics/ManagementAnalyticsContent";
import {managementMenu} from "../../../menu/ManagementMenu";
import ManagementMenu from "../../../components/Workarea/ManagementMenu/ManagementMenu";

const ManagementAnalyticsPage = () => {
    return (
        <div>
            <ManagementMenu menu={managementMenu}/>
            <div id="p_account_workarea_title">Аналитика</div>
            <ManagementAnalyticsContent />
        </div>
    );
};

export default ManagementAnalyticsPage;