import React, {useState} from 'react';
import ImgBack from "../../../assets/img/p_login_back.svg";
import {Link} from "react-router-dom";
import FormInputClear from "../../Interface/Forms/FormInputClear";
import NotificationsList from "../../Interface/Notifications/NotificationsList";
import {NotificationsArrAdd} from "../../../utils/NotificationsAdd";

const RestoreStep1 = ({item, setItem, step, setStep}) => {


    //Уведомления
    const [notifications, setNotifications] = useState([]);


    const handleSubmit = async () => {

        //
        let errBeforeArr = [];
        let errAfterArr = [];

        //
        if(item.email === '')
        {
            errBeforeArr.push('Поле «Почта» не заполнено');
        }

        //
        if(errBeforeArr.length > 0)
        {
            NotificationsArrAdd(notifications, setNotifications, errBeforeArr, 'not1');
        }
        else
        {
            //Переход на следующий шаг
            setStep(2);
        }

        //
        if(errAfterArr.length > 0)
        {
            NotificationsArrAdd(notifications, setNotifications, errAfterArr, 'not2');
        }

    }


    //Вывод компонента
    return (
        <>

            <Link to="/login" id="p_login_back">
                <img src={ImgBack} alt="" />
            </Link>

            <div id="p_login_form_title">
                Восстановление<br />
                пароля
            </div>
            <div id="p_login_form_desc">
                Введите вашу почту, указанную<br />
                при регистрации
            </div>

            <div id="p_login_rec_form_body">

                <div className="field_tr">
                    <div className="field_td field_td1">
                        <div className="label">Почта <span className="zv">*</span></div>
                        <FormInputClear
                            name="email"
                            value={item.email}
                            setValue={setItem}
                            item={item}
                        />
                    </div>
                </div>

                <div className="field_tr btn_tr">
                    <div className="field_td field_td1">

                        <input
                            type="submit"
                            className="btn"
                            value="Продолжить"
                            onClick={ () => handleSubmit() }
                        />

                    </div>
                </div>

                <div className="last_btn_label last_btn_label_center">
                    Вспомнили пароль? <Link to="/login">К авторизации</Link>
                </div>

            </div>


            <NotificationsList
                items={notifications}
                setItems={setNotifications}
            />


        </>
    );
};

export default RestoreStep1;