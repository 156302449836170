import React, {useContext, useState} from 'react';
import {AuthContext} from "../../../context/AuthContext";
import ImgPanelItem3 from "../../../assets/img/p_account_bonuses_actions_item3_img.svg";
import NotificationsList from "../../Interface/Notifications/NotificationsList";
import {NotificationsAdd} from "../../../utils/NotificationsAdd";


const BonusesPanelItem3 = () => {


    //Получение пользователя
    const {AppAuthStore} = useContext(AuthContext);


    //Уведомления
    const [notifications, setNotifications] = useState([]);


    //Ссылка для приглашения
    const RefLinkParam = Number(process.env.REACT_APP_REF_DELTA) + AppAuthStore.user.id;
    const RefLink =  process.env.REACT_APP_BASE_URL + '?ref=' + RefLinkParam;


    //Поделиться ссылкой
    const shareLink = (e, Link) => {
        e.preventDefault();

        //Копирование в буфер
        navigator.clipboard.writeText(Link);

        //Вывод уведомления
        NotificationsAdd(notifications, setNotifications, 'Реферальная ссылка была успешно скопирована!', 'ok');
    }


    //Вывод компонента
    return (
        <>


            <div className="p_account_bonuses_actions_item_out">
                <div className="p_account_bonuses_actions_item p_account_bonuses_actions_item3">
                    <div className="p_account_bonuses_actions_label">Ссылка для приглашения</div>
                    <div className="p_account_bonuses_actions_title">Поделиться ссылкой</div>
                    <div className="p_account_bonuses_actions_panel">
                        <span>{RefLink}</span>
                        <a
                            href=""
                            onClick={ e => shareLink(e, RefLink) }
                        >Скопировать</a>
                    </div>
                    <div className="p_account_bonuses_actions_img">
                        <img src={ImgPanelItem3} alt=""/>
                    </div>
                </div>
            </div>


            <NotificationsList
                items={notifications}
                setItems={setNotifications}
            />


        </>
    );
};

export default BonusesPanelItem3;