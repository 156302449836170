import React from 'react';
import ManagementPromoUniversalContent from "../../../../components/Pages/Management/Promo/Universal/ManagementPromoUniversalContent";
import {managementMenu} from "../../../../menu/ManagementMenu";
import ManagementMenu from "../../../../components/Workarea/ManagementMenu/ManagementMenu";
import {promoTabsMenu} from "../../../../menu/PromoTabsMenu";
import TabsMenu from "../../../../components/Workarea/TabsMenu/TabsMenu";

const ManagementPromoUniversalPage = () => {
    return (
        <div>
            <ManagementMenu menu={managementMenu}/>
            <div id="p_account_workarea_title">Промокоды</div>
            <TabsMenu menu={promoTabsMenu}/>
            <ManagementPromoUniversalContent />
        </div>
    );
};

export default ManagementPromoUniversalPage;