import React from 'react';
import TemplatesService from "../../../../services/TemplatesService";
import ModalWindow from "../../../Interface/ModalWindow/ModalWindow";

const ManagementTemplatesDelForm = ({modal, setModal, tekItemId, setUpdTrigger}) => {


    //Удаление
    const handleSubmit = async () => {

        try {
            let response = await TemplatesService.deleteById(tekItemId);

            if(response.data.status === 'ok')
            {
                setUpdTrigger( Date.now() );
            }
        }
        catch (e) {
            //
        }

        setModal(false);

    }


    //Отмена удаления
    const cancelSubmit = () => {
        setModal(false);
    }


    //Вывод компонента
    return (
        <ModalWindow title="Удаление шаблона" active={modal} setActive={setModal} type="del">

            <div className="modal_action_text">
                Вы действительно хотите удалить шаблон?<br />
            </div>

            <div className="modal_action_btns">
                <div className="btn btn_c5" onClick={ () => handleSubmit() }>Удалить</div>
                <div className="btn btn_c6" onClick={ () => cancelSubmit() }>Отменить</div>
            </div>

        </ModalWindow>
    );
};

export default ManagementTemplatesDelForm;