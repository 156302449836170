export const isChecked = (list, value) => {

    if( list.includes(value) )
    {
        return 'checked';
    }

    return '';
}


export const checkboxChecked = (event, list, value) => {

    let index = list.indexOf(value);

    if(event.target.checked === true)
    {
        if (index === -1)
        {
            list.push(value);
        }
    }
    else
    {
        if (index !== -1)
        {
            list.splice(index, 1);
        }
    }

    return [...list];
}