import React from 'react';

const ManagementBannersContent = () => {
    return (
        <div>
            ManagementBannersContent
        </div>
    );
};

export default ManagementBannersContent;