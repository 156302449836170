import React, {useEffect, useState} from 'react';
import TemplatesService from "../../../../services/TemplatesService";
import {useFetching} from "../../../../hooks/useFetching";
import ManagementTemplatesList from "./ManagementTemplatesList";
import Preloader from "../../../Interface/Preloader";
import ManagementTemplatesAdd from "./ManagementTemplatesAdd";
import ManagementTemplatesDelForm from "./ManagementTemplatesDelForm";
import ScriptsService from "../../../../services/ScriptsService";


const ManagementTemplatesContent = () => {

    //Список записей
    const [items, setItems] = useState([]);

    //Параметры редактора
    const [tekItemId, setTekItemId] = useState(0);
    const [updTrigger, setUpdTrigger] = useState(0);

    //Дополнительные списки
    const [scriptsList, setScriptsList] = useState([]);


    //Модальные окна
    const [modalDel, setModalDel] = useState(false);


    //Получение доп списков
    const [fetchAdds, isAddsLoading, addsError] = useFetching(
        async () => {
            const responseAdd = await ScriptsService.getAll(1, 100, 'id', 'desc');
            setScriptsList(responseAdd.data.data.items);
        }
    );


    useEffect(() => {
        fetchAdds();
    }, []);


    //Передача доп. списков
    const references = {
        scriptsList: scriptsList
    };


    //Получение основного списка
    const [fetchItems, isItemsLoading, itemsError] = useFetching(
        async () => {
            const response = await TemplatesService.getAll(1, 100, 'id', 'desc');
            setItems(response.data.data.items);
        }
    );


    //
    useEffect(() => {
        fetchItems();
    }, [updTrigger, updTrigger]);


    //Удаление записи
    const delItemInit = (id) => {
        setTekItemId(id);
        setModalDel(true);
    }


    //Передача функций
    const func = {
        del: delItemInit
    }

    //Вывод компонента
    return (
        <>

            {
                itemsError
                    ?
                    <div>Произошла ошибка ${itemsError}</div>
                    :
                    <></>
            }


            <div id="p_manager_templates_list">

                <ManagementTemplatesList
                    items={items}
                    isLoading={isItemsLoading}
                    references={references}
                    func={func}
                    setUpdTrigger={setUpdTrigger}
                />

                <ManagementTemplatesAdd
                    setUpdTrigger={setUpdTrigger}
                />

            </div>


            {
                isItemsLoading
                    ?
                    <Preloader />
                    :
                    <></>
            }


            <ManagementTemplatesDelForm
                modal={modalDel}
                setModal={setModalDel}
                tekItemId={tekItemId}
                setUpdTrigger={setUpdTrigger}
            />


        </>
    );
};

export default ManagementTemplatesContent;