import React, {useState} from 'react';
import ImgNums from "../../../assets/img/p_account_scripts_item_svodka_item_nums.svg";
import ImgTime from "../../../assets/img/p_account_scripts_item_svodka_item_time.svg";
import ImgMBtn from "../../../assets/img/p_account_scripts_item_mbtn.svg";
import ImgPay from "../../../assets/img/submenu_pay.svg";
import {daysAgo} from "../../../utils/DaysAgo";
import SubMenu from "../../Interface/SubMenu/SubMenu";
import {Link} from "react-router-dom";
import {ItemsCountTitle} from "../../../utils/ItemsCountTitle";

const ScriptsItem = ({item, func, references}) => {


    //Состояние меню
    const [submenu, setSubmenu] = useState(false);


    //Открытие меню
    const submenuToggle = (e) => {

        if(!submenu)
        {
            setSubmenu(true);
        }
        else
        {
            setSubmenu(false);
        }
    }


    //Вывод компонента
    return (
        <div className="p_account_scripts_item_out">
            <div className="p_account_scripts_item">


                <div className="p_account_scripts_item_title">{item.name}</div>


                <div className="p_account_scripts_item_svodka">
                    <div className="p_account_scripts_item_svodka_item">
                        <img src={ImgNums} alt="" />
                        <span>{item.replics_num} {ItemsCountTitle(item.replics_num, ['карточек', 'карточка', 'карточки', 'карточек'])}</span>
                    </div>
                    <div className="p_account_scripts_item_svodka_item">
                        <img src={ImgTime} alt="" />
                        <span>{daysAgo(item.created_at, 1, 'назад', 'Сегодня')}</span>
                    </div>
                </div>


                <div className="p_account_scripts_item_btns">
                    <div className="p_account_scripts_item_btns_left">
                        <Link to={"/scripts/" + item.id + "/view"} className="btn btn1">
                            Открыть скрипт
                        </Link>
                    </div>
                    <div className="p_account_scripts_item_btns_right">
                        <Link to={"/scripts/" + item.id + "/edit"} className="btn btn1 btn_c2">
                            Редактировать
                        </Link>
                    </div>
                </div>


                <div className="p_account_scripts_item_mbtn_out">

                    <div className="p_account_scripts_item_mbtn" onClick={ () => submenuToggle() }>
                        <img src={ImgMBtn} alt="" />
                    </div>

                    <div className="p_account_scripts_item_submenu">

                        <SubMenu active={submenu} setActive={setSubmenu}>
                            <ul>
                                <li>
                                    <a
                                        href=""
                                        onClick={e => func.download(e, item.id)}
                                        className="submenu_menu_with_img"
                                    >
                                        Скачать скрипт
                                        <img src={ImgPay} alt=""/>
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href=""
                                        onClick={ e => func.share(e, item.id) }
                                    >Поделиться ссылкой</a>
                                </li>
                            </ul>
                            <div className="submenu_menu_razd"></div>
                            <ul>
                                <li>
                                    <a
                                        href=""
                                        onClick={ e => func.rename(e, item.id) }
                                    >Переименовать скрипт</a>
                                </li>
                                <li>
                                    <a
                                        href=""
                                        onClick={ e => func.dupl(e, item.id) }
                                    >Создать дубликат</a>
                                </li>
                                <li>
                                    <a
                                        href=""
                                        onClick={ e => func.move(e, item.id) }
                                    >Переместить в...</a>
                                </li>
                                <li>
                                    <a
                                        href=""
                                        onClick={ e => func.del(e, item.id) }
                                    >Удалить скрипт</a>
                                </li>
                            </ul>
                        </SubMenu>

                    </div>

                </div>


            </div>
        </div>
    );
};

export default ScriptsItem;