export const RunTime = (timeSecTek, setTimeSec, start = 0) => {

    if(timeSecTek > 0)
    {
        const timeSecNew = timeSecTek - 1;
        setTimeSec(timeSecNew);

        setTimeout(
            () => {
                RunTime(timeSecNew, setTimeSec)
            },
            1000
        );
    }

}