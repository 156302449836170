import React from 'react';
import ModalWindow from "../../../Interface/ModalWindow/ModalWindow";
import {inputChange2d} from "../../../../utils/InputChange2d";
import FormSelect from "../../../Interface/Forms/FormSelect";

const ManagementUsersFiltersForm = ({modal, setModal, filters, setFilters, references}) => {



    //Вывод компонента
    return (
        <ModalWindow title="Фильтры" active={modal} setActive={setModal}>


            <div id="p_manager_users_modal_filtres">

                <div className="field_tr">
                    <div className="field_td field_td1">
                        <div className="label">Фамилия</div>
                        <input
                            type="text"
                            name="last_name"
                            className="field"
                            value={filters.last_name.v}
                            onChange={e => setFilters(inputChange2d(e, filters, 'v'))}
                            autoComplete="off"
                        />
                    </div>
                </div>

                <div className="field_tr">
                    <div className="field_td field_td1">
                        <div className="label">Имя</div>
                        <input
                            type="text"
                            name="first_name"
                            className="field"
                            value={filters.first_name.v}
                            onChange={e => setFilters(inputChange2d(e, filters, 'v'))}
                            autoComplete="off"
                        />
                    </div>
                </div>

                <div className="field_tr">
                    <div className="field_td field_td1">
                        <div className="label">Почта</div>
                        <input
                            type="text"
                            name="email"
                            className="field"
                            value={filters.email.v}
                            onChange={e => setFilters(inputChange2d(e, filters, 'v'))}
                            autoComplete="off"
                        />
                    </div>
                </div>

                <div className="field_tr">
                    <div className="field_td field_td1">
                        <div className="label">Телефон</div>
                        <input
                            type="text"
                            name="phone"
                            className="field"
                            value={filters.phone.v}
                            onChange={e => setFilters(inputChange2d(e, filters, 'v'))}
                            autoComplete="off"
                        />
                    </div>
                </div>

                <div className="field_tr">
                    <div className="field_td field_td1">
                        <div className="label">Тариф</div>
                        <FormSelect
                            name="tarif_id"
                            options={references.tarifsList}
                            defaultValue="Выберите значение"
                            value={filters.tarif_id.v}
                            onChange={e => setFilters(inputChange2d(e, filters, 'v'))}
                            valueKey="0"
                            nameKey="1"
                            defValue="null"
                            className="field"
                        />
                    </div>
                </div>

            </div>


        </ModalWindow>
    );

};

export default ManagementUsersFiltersForm;