import React, {useContext}  from 'react';
import {observer} from "mobx-react-lite"
import {AuthContext} from "../../context/AuthContext";
import {Navigate} from "react-router-dom";
import LoginContent from "../../components/Pages/Login/LoginContent";

const LoginPage = () => {

    //
    const {AppAuthStore} = useContext(AuthContext);

    //
    if(!AppAuthStore.isCheckAuth)
    {
        return '';
    }


    //
    return (

        AppAuthStore.isAuth
            ?
            <Navigate to="/" replace />
            :
            <LoginContent />

    );
};

export default observer(LoginPage);