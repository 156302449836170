import React from 'react';

const Footer = () => {
    return (
        <div className="blockline" id="p_account_footer_line">
            <div className="block" id="p_account_footer">

            </div>
        </div>
    );
};

export default Footer;