import React, {useEffect, useState} from 'react';
import {useFetching} from "../../../hooks/useFetching";
import TemplatesService from "../../../services/TemplatesService";
import ScriptsShopList from "./ScriptsShopList";
import ScriptsShopHideForm from "./ScriptsShopHideForm";

const ScriptsShop = () => {

    //Параметры выборки
    const [items, setItems] = useState([]);


    //Параметры редактора
    const [tekItemId, setTekItemId] = useState(0);
    const [updTrigger, setUpdTrigger] = useState(0);


    //Модальные окна
    const [modalHide, setModalHide] = useState(false);


    //Передача доп. списков
    const references = {

    };


    //Скрытие шаблона
    const hideItemInit = (id) => {
        setTekItemId(id);
        setModalHide(true);
    }


    //Передача функций
    const func = {
        hide: hideItemInit,
    }


    //Определение выборки списка
    const [fetchItems, isItemsLoading, itemsError] = useFetching(
        async () => {
            const response = await TemplatesService.getAll(1, 100, 'id', 'desc', {}, '', '', 'project');
            setItems(response.data.data.items);
        }
    );


    //Инициализация выборки списка
    useEffect(() => {
        fetchItems();
    }, [
        updTrigger,
        updTrigger
    ]);


    //Вывод компонента
    return (
        <>

            <ScriptsShopList
                items={items}
                isLoading={isItemsLoading}
                func={func}
                references={references}
            />


            <ScriptsShopHideForm
                modal={modalHide}
                setModal={setModalHide}
                tekItemId={tekItemId}
                setUpdTrigger={setUpdTrigger}
            />


        </>
    );
};

export default ScriptsShop;