import React from 'react';
import ImgToolsEdit from "../../../assets/img/tools_edit.svg";
import ImgToolsDelete from "../../../assets/img/tools_delete.svg";

const ScriptsToolsItem = ({item, func, filters}) => {

    //Вывод компонента
    return (
        <div
            className={filters.rubric_id.v === item.id ? 'workarea_tools_menu_item active' : 'workarea_tools_menu_item'}
            onClick={ () => func.rubric(item.id) }
        >

            {item.name}

            {
                filters.rubric_id.v === item.id
                    ?
                    <>

                        &nbsp;
                        &nbsp;

                        <img
                            src={ImgToolsEdit}
                            alt=""
                            onClick={ () => func.edit(item.id) }
                        />

                        <img
                            src={ImgToolsDelete}
                            alt=""
                            onClick={ () => func.del(item.id) }
                        />

                    </>
                    :
                    <></>
            }

        </div>
    );
};

export default ScriptsToolsItem;