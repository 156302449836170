import React, {useState, useEffect} from 'react';
import ModalWindow from "../../Interface/ModalWindow/ModalWindow";
import {Errors422} from "../../../utils/Errors422";
import ScriptsService from "../../../services/ScriptsService";

const ScriptsMoveForm = ({modal, setModal, tekItemId, setUpdTrigger, references}) => {


    //Данные сохранения
    const nullItem = {
        rubric_id: 0
    };

    //
    const [item, setItem] = useState(nullItem);

    const [success, setSuccess] = useState(false);
    const [errors, setErrors] = useState([]);


    //Получение данных
    useEffect(() => {

        if(modal) {
            if (tekItemId === 0) {
                setItem(nullItem);
            } else {
                const getItem = async (id) => {
                    const response = await ScriptsService.getById(id);
                    setItem(response.data.data);
                }
                getItem(tekItemId);
            }
        }

    }, [modal, modal]);


    //Сохранение данных
    const handleSubmit = async () => {

        //
        let errArr = [];


        //
        if(errArr.length === 0)
        {
            try {
                //
                let response = await ScriptsService.updateById(tekItemId, item);

                //
                if(response.data.status === 'ok')
                {
                    //
                    setSuccess(true);
                    setTimeout(
                        () => {
                            setSuccess(false);
                            setModal(false);
                        },
                        1500
                    );

                    //
                    setUpdTrigger( Date.now() );
                }
                else
                {
                    errArr.push('Ошибка сохранения скрипта.');
                }
            }
            catch (e) {
                Errors422(e, errArr, 'Ошибка сохранения скрипта.');
            }

        }

        //
        setErrors(errArr);
    }


    //Отмена
    const cancelSubmit = () => {
        setModal(false);
    }


    //Переключить рубрику
    const switchRubric = (rubricId) => {
        setItem({...item, ...{['rubric_id']: rubricId}});
    }


    //Вывод компонента
    return (
        <ModalWindow
            title="Переместить скрипт"
            active={modal}
            setActive={setModal}
            footer={
                {
                    leftBtn: {
                        title: 'Отменить',
                        func: cancelSubmit
                    },
                    rightBtn: {
                        title: 'Переместить скрипт',
                        func: handleSubmit
                    }
                }
            }
        >

            <div id="p_account_scripts_modal_remove">


                <div className="field_tr">
                    <div className="field_td field_td1">
                        <div className="label">Расположение</div>

                        <div className="field_select_label">
                            <div
                                className={item.rubric_id === 0 ? 'field_select_label_item active' : 'field_select_label_item'}
                                onClick={ () => switchRubric(0) }
                            >Все скрипты</div>
                            {
                                references.rubrics.map(
                                    (rubricsItem, rubricsIndex) =>
                                        <div
                                            className={item.rubric_id === rubricsItem.id ? 'field_select_label_item active' : 'field_select_label_item'}
                                            key={rubricsItem.id}
                                            onClick={ () => switchRubric(rubricsItem.id) }
                                        >{rubricsItem.name}</div>
                                )
                            }
                        </div>

                    </div>
                </div>

            </div>

        </ModalWindow>
    );
};

export default ScriptsMoveForm;