import React from 'react';

const ScriptsEditLeftItemAnswersItem = ({item, func, setters}) => {

    //Вывод компонента
    return (
        <div

            onClick={e => func.select(e, item.id, setters.setReplicId) }
            className="p_editor_left_item_answers_item"
        >
            {item.name}
        </div>
    );
};

export default ScriptsEditLeftItemAnswersItem;