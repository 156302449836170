import React from 'react';
import {searchInArray} from "../../../../../utils/SearchInArray";
import {dateFormat} from "../../../../../utils/DateFormat";
import ToolsDelete from "../../../../../assets/img/tools_delete.svg";

const ManagementPromoStandardItem = ({item, func, references}) => {

    //Вывод компонента
    return (
        <tr>
            <td>{item.code}</td>
            <td>{item.days}</td>
            <td>
                { searchInArray(references.statusList, item.status, 0, 1) }
                {
                    item.status === 1
                    ?
                        <> <span className="p_manager_codes_table_status_user">
                            ({item.user.last_name} {item.user.first_name})
                        </span></>
                        :
                        <></>
                }
            </td>
            <td>{ dateFormat(item.created_at, 'd.m.Y в H:i') }</td>
            <td>{ dateFormat(item.date_active, 'd.m.Y в H:i') }</td>
            <td>

                <div className="p_manager_table_action_out">

                    <span
                        className="p_manager_table_action"
                        onClick={ () => func.del(item.id) }
                    >
                        <img src={ToolsDelete} alt=''/>
                    </span>

                </div>

            </td>
        </tr>
    );
};

export default ManagementPromoStandardItem;