import React from 'react';
import EditorStartFonImg from "../../../../assets/img/p_editor_start_fon.svg";
import EditorStartImgImg from "../../../../assets/img/p_editor_start_img.svg";

const ScriptsEditWorkAreaStart = ({firstReplicId, setReplicId}) => {


    //Переход к первой реплике
    const goFirstReplic = (e) => {
        e.preventDefault();
        setReplicId(firstReplicId);
    }


    //Вывод компонента
    return (
        <div className="p_editor_center_start">

            <div className="p_editor_center_start_picture">

                <div className="p_editor_center_start_fon">
                    <img src={EditorStartFonImg} alt="" />
                </div>

                <div className="p_editor_center_start_buble">
                    Добро пожаловать в конструктор
                    скриптов продаж ScriptOn!
                </div>

                <div className="p_editor_center_start_img">
                    <img src={EditorStartImgImg} alt="" />
                </div>

            </div>

            <div className="p_editor_center_start_text">
                Здесь все довольно просто. Нажми на карточку в левой рабочей зоне и начинай составление
                скрипта продаж. При необходимости мы тебе все подскажем. Для этого достаточно вызвать
                помощника, нажав на кнопку в правом нижнем углу.
            </div>

            <div className="p_editor_center_start_btn">

                <a
                    href=""
                    className="btn"
                    onClick={ e => goFirstReplic(e) }
                >Перейти к карточке</a>

            </div>

        </div>
    );
};

export default ScriptsEditWorkAreaStart;
