import React from 'react';
import ModalWindow from "../../../Interface/ModalWindow/ModalWindow";
import ReplicsService from "../../../../services/ReplicsService";
import {NotificationsAdd} from "../../../../utils/NotificationsAdd";

const ScriptsEditLeftDelForm = ({modal, setModal, tekItemId, setUpdTrigger, notifications, setNotifications, firstReplicId, setReplicId}) => {


    //Удаление
    const handleSubmit = async () => {

        try {
            //
            let response = await ReplicsService.deleteById(tekItemId);

            if(response.data.status === 'ok')
            {
                setUpdTrigger( Date.now() );
            }

            //
            setReplicId(firstReplicId);

            //
            NotificationsAdd(notifications, setNotifications, 'Карточка ответа была успешно удалена.', 'ok');
        }
        catch (e) {
            //
        }

        setModal(false);
    }


    //Отмена
    const cancelSubmit = () => {
        setModal(false);
    }


    //Вывод компонента
    return (
        <ModalWindow title="Удалить ответ" active={modal} setActive={setModal} type="del">

            <div className="modal_action_text">
                Вы действительно хотите удалить ответ?<br />
                После удаления действие невозможно будет отменить.
            </div>

            <div className="modal_action_btns">
                <div className="btn btn_c5" onClick={ () => handleSubmit() }>Удалить</div>
                <div className="btn btn_c6" onClick={ () => cancelSubmit() }>Отменить</div>
            </div>

        </ModalWindow>
    );
};

export default ScriptsEditLeftDelForm;