import $api from "../http";

export default class PromosService {

    static async getAll(page = 1, limit = 100, sort = 'id', direction = 'asc', filters = {}, search = '', fields = '') {
        return await $api.get('/promos/list', {
            params: {
                with: 'user',
                page: page,
                limit: limit,
                sort: sort,
                direction: direction,
                filters: filters,
                search: search,
            }
        });
    }

    static async create(data) {
        return await $api.post('/promos/create', data);
    }

    static async getById(id) {
        return await $api.get('/promos/' + id + '/read');
    }

    static async updateById(id, data) {
        return await $api.post('/promos/' + id + '/update', data);
    }

    static async deleteById(id) {
        return await $api.delete('/promos/' + id + '/delete');
    }

}