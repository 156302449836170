import React, {useState} from 'react';
import ImgBack from "../../../assets/img/p_login_back.svg";
import FormInputClear from "../../Interface/Forms/FormInputClear";
import NotificationsList from "../../Interface/Notifications/NotificationsList";
import {NotificationsArrAdd} from "../../../utils/NotificationsAdd";

const RegisterStep3 = ({item, setItem, step, setStep}) => {


    //Уведомления
    const [notifications, setNotifications] = useState([]);


    const handleSubmit = async () => {

        //
        let errBeforeArr = [];
        let errAfterArr = [];

        //
        if(item.last_name === '')
        {
            errBeforeArr.push('Поле «Фамилия» не заполнено');
        }

        //
        if(item.first_name === '')
        {
            errBeforeArr.push('Поле «Имя» не заполнено');
        }


        //
        if(errBeforeArr.length > 0)
        {
            NotificationsArrAdd(notifications, setNotifications, errBeforeArr, 'not1');
        }
        else
        {
            //Переход на следующий шаг
            setStep(4);
        }

        //
        if(errAfterArr.length > 0)
        {
            NotificationsArrAdd(notifications, setNotifications, errAfterArr, 'not2');
        }


    }


    //Вернуться назад
    const goBack = () => {
        setStep(2);
    }


    //Вывод компонента
    return (
        <>

            <div id="p_login_back" onClick={ () => goBack() }>
                <img src={ImgBack} alt=""/>
            </div>

            <div id="p_login_form_title">Личные данные</div>
            <div id="p_login_form_desc">
                Укажите вашу фамилию и имя<br/>
                в полях ввода ниже
            </div>

            <div id="p_login_reg_form_body">

                <div className="field_tr">
                    <div className="field_td field_td1">
                        <div className="label">Фамилия <span className="zv">*</span></div>
                        <FormInputClear
                            name="last_name"
                            value={item.last_name}
                            setValue={setItem}
                            item={item}
                        />
                    </div>
                </div>

                <div className="field_tr">
                    <div className="field_td field_td1">
                        <div className="label">Имя <span className="zv">*</span></div>
                        <FormInputClear
                            name="first_name"
                            value={item.first_name}
                            setValue={setItem}
                            item={item}
                        />
                    </div>
                </div>

                <div className="field_tr btn_tr">
                    <div className="field_td field_td1">

                        <input
                            type="submit"
                            className="btn"
                            value="Продолжить"
                            onClick={ () => handleSubmit() }
                        />

                    </div>
                </div>

            </div>


            <NotificationsList
                items={notifications}
                setItems={setNotifications}
            />


        </>
    );
};

export default RegisterStep3;