import React from 'react';
import {Link, useMatch} from "react-router-dom";

const ManagementMenuItem = (props) => {
    return (
        <li className={useMatch(props.to + '/*') ? 'active' : ''}>
            <Link to={ '/' + props.to}>
                {props.title}
            </Link>
        </li>
    );
};

export default ManagementMenuItem;