import React, {useEffect, useState} from 'react';
import ModalWindow from "../../../Interface/ModalWindow/ModalWindow";
import UsersService from "../../../../services/UsersService";
import FormSuccess from "../../../Interface/FormMessage/FormSuccess";
import FormErrors from "../../../Interface/FormMessage/FormErrors";
import {InputChange} from "../../../../utils/InputChange";
import {Errors422} from "../../../../utils/Errors422";
import FormSelect from "../../../Interface/Forms/FormSelect";
import {dateFormat} from "../../../../utils/DateFormat";

const ManagementUsersTarifForm = ({modal, setModal, tekItemId, setUpdTrigger, references}) => {

    //Данные сохранения
    const nullItem = {
        date_end: '',
        tarif_id: '',
    };

    const [item, setItem] = useState(nullItem);

    const [success, setSuccess] = useState(false);
    const [errors, setErrors] = useState([]);


    //Получение данных
    useEffect(() => {

        if(modal)
        {
            const getItem = async (id) => {
                const response = await UsersService.getById(id);
                setItem(response.data.data);
            }
            getItem(tekItemId);
        }

    }, [modal, modal]);


    //Сохранение данных
    const handleSubmit = async () => {

        //Ошибки
        let errArr = [];

        //
        if (item.last_name === '')
        {
            errArr.push('Поле «Тариф» не заполнено');
        }

        //Отправка запроса
        if(errArr.length === 0)
        {
            try {
                //
                let response = await UsersService.updateById(tekItemId, item);

                if(response.data.status === 'ok')
                {
                    //
                    setSuccess(true);
                    setTimeout(
                        () => {
                            setSuccess(false);
                            setModal(false);
                        },
                        1500
                    );

                    //
                    setUpdTrigger( Date.now() );
                }
                else
                {
                    errArr.push('Ошибка сохранения пользователя.');
                }
            }
            catch (e) {
                Errors422(e, errArr, 'Ошибка сохранения пользователя.');
            }

        }

        //
        setErrors(errArr);
    }


    //Отмена сохранения
    const cancelSubmit = () => {
        setModal(false);
    }


    //Вывод компонента
    return (
        <ModalWindow
            title="Смена тарифа"
            active={modal}
            setActive={setModal}
            footer={
                {
                    leftBtn: {
                        title: 'Отменить',
                        func: cancelSubmit
                    },
                    rightBtn: {
                        title: 'Сменить тариф',
                        func: handleSubmit
                    }
                }
            }
        >

            <div id="p_manager_users_modal_tarif">

                <FormSuccess ok={success} text="Тариф изменен." fading="true"/>

                <FormErrors errors={errors}/>

                <div className="field_tr">

                    <div className="field_td field_td1">
                        <div className="label">Тариф</div>
                        <FormSelect
                            name="tarif_id"
                            options={references.tarifsList}
                            defaultValue="Выберите значение"
                            value={item.tarif_id}
                            onChange={ e => setItem(InputChange(e, item)) }
                            valueKey="0"
                            nameKey="1"
                            defValue="null"
                            className="field"
                        />
                    </div>

                </div>

                <div className="field_tr">

                    <div className="field_td field_td1">
                        <div className="label">Доступ до</div>
                        <input
                            type="date"
                            name="date_end"
                            className="field"
                            value={ dateFormat(item.date_end, 'Y-m-d') }
                            onChange={ e => setItem(InputChange(e, item)) }
                        />
                    </div>

                </div>

            </div>

        </ModalWindow>
    );
};

export default ManagementUsersTarifForm;