import React from 'react';
import TableSort from "../../../../Interface/Table/TableSort";
import ManagementPromoStandardItem from "./ManagementPromoStandardItem";

const ManagementPromoStandardList = ({items, isLoading, func, references, sort, direction}) => {

    //
    if (!items.length && !isLoading) {
        return (
            <div>Записи не найдены!</div>
        )
    }

    //Вывод компонента
    return (
        <div id="p_manager_codes_table">

            <div className="table_wrap table_wrap_v2">

                <table cellPadding="0" cellSpacing="0" border="0">
                    <thead>
                    <tr>
                        <th>
                            Промокод
                            <TableSort field="code" sort={sort} direction={direction} setSort={func.setOrderBy}/>
                        </th>
                        <th>
                            Кол-во дней
                            <TableSort field="days" sort={sort} direction={direction} setSort={func.setOrderBy}/>
                        </th>
                        <th>
                            Статус промокода
                            <TableSort field="status" sort={sort} direction={direction} setSort={func.setOrderBy}/>
                        </th>
                        <th>
                            Дата создания
                            <TableSort field="createt_at" sort={sort} direction={direction} setSort={func.setOrderBy}/>
                        </th>
                        <th>
                            Дата активации
                            <TableSort field="date_active" sort={sort} direction={direction} setSort={func.setOrderBy}/>
                        </th>
                        <th>
                            Действия
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        items.map(
                            (item, index) =>
                                <ManagementPromoStandardItem
                                    key={item.id}
                                    item={item}
                                    func={func}
                                    references={references}
                                />
                        )
                    }
                    </tbody>
                </table>


            </div>

        </div>
    );
};

export default ManagementPromoStandardList;