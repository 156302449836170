import React, {useState, useEffect} from 'react';
import FormInputSearch from "../../Interface/Forms/FormInputSearch";
import ScriptsToolsList from "./ScriptsToolsList";
import ScriptsToolsDelForm from "./ScriptsToolsDelForm";
import ScriptsToolsEditForm from "./ScriptsToolsEditForm";


const ScriptsTools = ({references, updTrigger, setUpdTrigger, search, setSearch, filters, switchRubric, limitRubrics}) => {

    //Параметры редактора
    const [tekItemId, setTekItemId] = useState(0);


    //Модальные окна
    const [modalEdit, setModalEdit] = useState(false);
    const [modalDel, setModalDel] = useState(false);


    //Добавление записи
    const addItemInit = () => {
        setTekItemId(0);
        setModalEdit(true);
    }


    //Редактирование записи
    const editItemInit = (id) => {
        setTekItemId(id);
        setModalEdit(true);
    }


    //Удаление записи
    const delItemInit = (id) => {
        setTekItemId(id);
        setModalDel(true);
    }


    //Передача функций
    const func = {
        add: addItemInit,
        edit: editItemInit,
        del: delItemInit,
        rubric: switchRubric
    }


    //Вывод компонента
    return (
        <>

            <div className="workarea_tools">

                <div className="workarea_tools_left" id="p_account_scripts_menu">
                    <div id="workarea_tools_menu">

                        <div
                            className={filters.rubric_id.v === 'null' ? 'workarea_tools_menu_item active' : 'workarea_tools_menu_item'}
                            onClick={ () => switchRubric('null') }
                        >Все скрипты</div>

                        <ScriptsToolsList
                            items={references.rubrics}
                            func={func}
                            filters={filters}
                            limit={limitRubrics}
                        />

                    </div>
                </div>

                <div className="workarea_tools_right" id="p_account_scripts_searsh">

                    <FormInputSearch
                        name='search'
                        value={search}
                        setValue={setSearch}
                    />

                </div>

            </div>


            <ScriptsToolsDelForm
                modal={modalDel}
                setModal={setModalDel}
                tekItemId={tekItemId}
                setUpdTrigger={setUpdTrigger}
            />

            <ScriptsToolsEditForm
                modal={modalEdit}
                setModal={setModalEdit}
                tekItemId={tekItemId}
                setUpdTrigger={setUpdTrigger}
            />

        </>
    );
};

export default ScriptsTools;