import {useLocation, matchRoutes} from "react-router-dom";
import {privateRoutes, privateRoutesWL, publicRoutes} from "../routes";

const usePageTitle = (defTitle) => {
    const location = useLocation();
    const allRoutes = [...privateRoutes, ...privateRoutesWL, ...publicRoutes];
    let [{ route }] = matchRoutes(allRoutes, location);
    return route.title + ' - ' + defTitle;
}

export default usePageTitle;