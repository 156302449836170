import React from 'react';

const ScriptsEditWorkAreaReplicBack = ({replicId, setReplicId, firstReplicId}) => {

    //Переход к первой реплике
    const goFirstReplic = (e) => {
        e.preventDefault();
        setReplicId(firstReplicId);
    }


    //Вывод компонента
    return (
        <>
            {
                replicId !== firstReplicId
                    ?
                    <a
                        href=""
                        className="p_editor_center_item_back"
                        onClick={e => goFirstReplic(e)}
                    >
                        Вернуться к началу разговора
                    </a>
                    :
                    <></>
            }
        </>
    );
};

export default ScriptsEditWorkAreaReplicBack;