import React, {useEffect, useState} from 'react';
import ImgBack from "../../../assets/img/p_login_back.svg";
import {MaskEmail} from "../../../utils/MaskEmail";
import {InputChange} from "../../../utils/InputChange";
import {RunTime} from "../../../utils/RunTime";
import RegisterService from "../../../services/RegisterService";
import {stepPinCode} from "../../../utils/StepPinCode";
import NotificationsList from "../../Interface/Notifications/NotificationsList";
import {NotificationsArrAdd} from "../../../utils/NotificationsAdd";

const RegisterStep2 = ({item, setItem, step, setStep}) => {

    //Объект кода
    const nullCode = {
        char1: '',
        char2: '',
        char3: '',
        char4: '',
    };

    const [code, setCode] = useState(nullCode);

    const [emailCode, setEmailCode] = useState();


    //Обратный отсчет
    const [timeStart, setTimeStart] = useState();
    const [timeSec, setTimeSec] = useState(0);


    //Генерация кода
    const RunCode = async () => {

        try {
            //
            let response = await RegisterService.sendVeriCode({'email': item.email})

            if(response.data.status === 'ok')
            {
                setEmailCode(response.data.data.code);
            }
            else
            {
                //
            }
        }
        catch (e) {
            //
        }

    }


    //Запуск отсчета
    useEffect(() => {
        RunCode();
        RunTime(60, setTimeSec);
    }, [
        timeStart,
        timeStart,
    ]);


    //Уведомления
    const [notifications, setNotifications] = useState([]);


    const handleSubmit = async () => {

        //
        let errBeforeArr = [];
        let errAfterArr = [];

        //
        if(code.char1 === '' || code.char2 === '' || code.char3 === '' || item.char4 === '')
        {
            errBeforeArr.push('Необходимо ввести код подтверждения полностью');
        }


        //
        if(errBeforeArr.length > 0)
        {
            NotificationsArrAdd(notifications, setNotifications, errBeforeArr, 'not1');
        }
        else
        {
            //
            const CodeStr = code.char1 + code.char2 + code.char3 + code.char4;

            if(CodeStr === emailCode)
            {
                //Переход на следующий шаг
                setStep(3);
            }
            else
            {
                errAfterArr.push('Неверный код подтверждения.');
            }
        }

        //
        if(errAfterArr.length > 0)
        {
            NotificationsArrAdd(notifications, setNotifications, errAfterArr, 'not2');
        }

    }


    //Вернуться назад
    const goBack = () => {
        setStep(1);
    }


    //Запуск таймера
    const startTime = (e) => {
        e.preventDefault();
        setTimeStart( Date.now() );
        setCode(nullCode);
    }


    //Переход по поля кода
    const myStepPinCode = (event) => {

        //Изменение кода
        setCode(InputChange(event, code));

        //Переходы
        stepPinCode(event);
    }


    //Вывод компонента
    return (
        <>

            <div id="p_login_back" onClick={ () => goBack() }>
                <img src={ImgBack} alt=""/>
            </div>

            <div id="p_login_form_title">Подтверждение</div>

            <div id="p_login_form_desc">
                Мы отправили письмо, для подтверждения<br/>
                вашей почты <span>{ MaskEmail(item.email) }</span>.<br/>
                Пожалуйста, введите полученный код ниже
            </div>

            <div id="p_login_reg_form_body">

                <div className="field_tr field_veri_code">
                    <div className="field_td field_td4">
                        <input
                            type="text"
                            name="char1"
                            className="field"
                            value={code.char1}
                            onChange={ e => myStepPinCode(e) }
                            maxLength="1"
                            tabIndex="1"
                        />
                    </div>
                    <div className="field_td field_td4">
                        <input
                            type="text"
                            name="char2"
                            className="field"
                            value={code.char2}
                            onChange={ e => myStepPinCode(e) }
                            maxLength="1"
                            tabIndex="2"
                        />
                    </div>
                    <div className="field_td field_td4">
                        <input
                            type="text"
                            name="char3"
                            className="field"
                            value={code.char3}
                            onChange={ e => myStepPinCode(e) }
                            maxLength="1"
                            tabIndex="3"
                        />
                    </div>
                    <div className="field_td field_td4">
                        <input
                            type="text"
                            name="char4"
                            className="field"
                            value={code.char4}
                            onChange={ e => myStepPinCode(e) }
                            maxLength="1"
                            tabIndex="4"
                        />
                    </div>

                </div>


                {
                    timeSec > 0
                        ?
                        <div className="field_tr btn_tr">
                            <div className="field_td field_td1">

                                <input
                                    type="submit"
                                    className="btn"
                                    value="Продолжить"
                                    onClick={ e => handleSubmit() }
                                />

                            </div>
                        </div>
                        :
                        <div className="field_tr btn_tr">
                            <div className="field_td field_td1">

                                <div className="last_btn_label last_btn_label_center">

                                    <a
                                        href=""
                                        onClick={ e => startTime(e) }
                                    >Отправить</a> код заново

                                </div>

                            </div>
                        </div>
                }


                <div className="last_btn_label last_btn_label_center">
                    <span>{timeSec} сек.</span>
                </div>

            </div>


            <NotificationsList
                items={notifications}
                setItems={setNotifications}
            />


        </>
    );
};

export default RegisterStep2;