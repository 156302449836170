import React, {useContext} from 'react';
import MainMenuItem from "./MainMenuItem";
import {mainMenu} from "../../menu/MainMenu";
import {AuthContext} from "../../context/AuthContext";

const MainMenu = () => {

    //Получение пользователя
    const {AppAuthStore} = useContext(AuthContext);
    const userType = AppAuthStore.user.type;

    //Доступ к пункту
    const viewMenuItem = (adminFlag) => {

        if(userType === 0)
        {
            if(adminFlag === 1)
            {
                return false;
            }
        }

        return true;
    }


    //Вывод компонента
    return (
        <div id="p_account_menu">
            <ul>
                {
                    mainMenu.filter(
                        (menu) => viewMenuItem(menu.admin)
                    ).map(
                        (route, index) =>
                            <MainMenuItem
                                to={route.path}
                                icon={route.icon}
                                title={route.title}
                                key={index}
                            />
                    )
                }
            </ul>
        </div>
    );
};

export default MainMenu;