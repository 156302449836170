import React from 'react';
import {searchInArray} from "../../../utils/SearchInArray";
import {dateFormat} from "../../../utils/DateFormat";
import {SumFormat} from "../../../utils/SumFormat";

const BonusesOperationsItem = ({item, func, references}) => {


    //Вывод компонента
    return (
        <tr onClick={ () => func.view(item.type, item.id) }>
            <td>
                <span className={"p_account_bonuses_table_type" + item.type}>
                    { searchInArray(references.typesList, item.type, 0, 1) }
                </span>
            </td>
            <td>{item.note}</td>
            <td>
                {
                    item.user !== null
                        ?
                        <>
                            {item.user.last_name} {item.user.first_name}
                        </>
                        :
                        <>0 карточек</>
                }
            </td>
            <td>
                {
                    item.sum > 0
                        ?
                        <>{ SumFormat(item.sum) } ₽</>
                        :
                        <>-</>
                }
            </td>
            <td>{ dateFormat(item.created_at, 'd.m.Y в H:i') }</td>
            <td>
                <span className={"p_account_bonuses_table_status" + item.status}>
                    { searchInArray(references.statusList, item.status, 0, 1) }
                </span>
            </td>
        </tr>
    );
};

export default BonusesOperationsItem;