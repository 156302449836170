import React from 'react';
import ManagementMenuItem from "./ManagementMenuItem";

const ManagementMenu = (props) => {
    return (
        <div id="p_manager_menu">
            <ul>
                {
                    props.menu.map(
                        (route, index) =>
                            <ManagementMenuItem
                                to={route.path}
                                title={route.title}
                                key={index}
                            />
                    )
                }
            </ul>
        </div>
    );
};

export default ManagementMenu;