import React from 'react';
import ManagementTemplatesContent from "../../../components/Pages/Management/Templates/ManagementTemplatesContent";
import {managementMenu} from "../../../menu/ManagementMenu";
import ManagementMenu from "../../../components/Workarea/ManagementMenu/ManagementMenu";

const ManagementTemplatesPage = () => {
    return (
        <div>
            <ManagementMenu menu={managementMenu}/>
            <div id="p_account_workarea_title">Шаблоны</div>
            <ManagementTemplatesContent />
        </div>
    );
};

export default ManagementTemplatesPage;