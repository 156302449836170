import React from 'react';
import ToolsDelete from "../../../../../assets/img/tools_delete.svg";
import {dateFormat} from "../../../../../utils/DateFormat";

const ManagementPromoUniversalItem = ({item, func, references}) => {
    return (
        <tr>
            <td>{item.code}</td>
            <td>{item.days}</td>
            <td>{item.period}</td>
            <td>{item.count}</td>
            <td>{item.status}</td>
            <td>{ dateFormat(item.created_at, 'd.m.Y в H:i') }</td>
            <td>

                <div className="p_manager_table_action_out">

                    <span
                        className="p_manager_table_action"
                        onClick={ () => func.del(item.id) }
                    >
                        <img src={ToolsDelete} alt=''/>
                    </span>

                </div>

            </td>
        </tr>
    );
};

export default ManagementPromoUniversalItem;