import React, {useContext} from 'react';
import {Outlet} from "react-router-dom";

import {observer} from "mobx-react-lite"
import {AuthContext} from "../context/AuthContext";
import AccessDeniedPage from "../pages/AccessDeniedPage";


const WorkArea = () => {

    const {AppAuthStore} = useContext(AuthContext);

    return (
        <div className="blockline" id="p_account_workarea_line">
            <div className="block" id="p_account_workarea">
                {
                    AppAuthStore.isAccess
                        ?
                        <Outlet/>
                        :
                        <AccessDeniedPage/>
                }
            </div>
        </div>
    );
};

export default observer(WorkArea);