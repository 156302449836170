import React from 'react';
import ImgFieldClear from "../../../assets/img/field_clear.svg"
import {InputChange} from "../../../utils/InputChange";

const FormInputClear = ({name, value, setValue, item, placeholder, disabled}) => {

    //Очистка поля
    const fieldClear = (targetName) => {

        if( (item === null) || (item === undefined) )
        {
            setValue('');
        }
        else
        {
            setValue({...item, ...{[targetName]: ''}});
        }

    }

    //Изменение значения
    const onChange = (e) => {

        if( (item === null) || (item === undefined) )
        {
            setValue(e.target.value);
        }
        else
        {
            setValue(InputChange(e, item));
        }

    }

    //Вывод компонента
    return (
        <div className="field_ico_out">
            <input
                type="text"
                name={name}
                className="field"
                value={value}
                onChange={ e => onChange(e) }
                autoComplete="off"
                placeholder={placeholder}
                disabled={disabled}
            />
            {
                disabled !== 'disabled'
                    ?
                    <div
                        className="field_ico"
                        onClick={() => fieldClear(name)}
                    >
                        <img src={ImgFieldClear} alt=""/>
                    </div>
                    :
                    <></>
            }
        </div>
    );
};

export default FormInputClear;