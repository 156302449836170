import React, {useState, useEffect} from 'react';
import ModalWindow from "../../Interface/ModalWindow/ModalWindow";
import OperationsService from "../../../services/OperationsService";
import {searchInArray} from "../../../utils/SearchInArray";
import {SumFormat} from "../../../utils/SumFormat";
import {dateFormat} from "../../../utils/DateFormat";

const BonusesOperationsViewType2 = ({modal, setModal, tekItemId, references}) => {

    //Данные операции
    const [item, setItem] = useState({});


    //Получение данных
    useEffect(() => {

        if(modal)
        {
            const getItem = async (id) => {
                const response = await OperationsService.getById(id);
                setItem(response.data.data);
            }
            getItem(tekItemId);
        }

    }, [modal, modal]);


    //Вывод компонента
    return (
        <ModalWindow
            title="Информация по операции"
            active={modal}
            setActive={setModal}
        >

            <div className="p_account_bonuses_modal_info">

                <div className="modal_info_title">Общая информация</div>

                <div className="modal_info_desc">
                    Показывает основную информацию, а также отображает статус данной операции
                </div>

                <div className="modal_info_body">


                    <div className="field_tr">
                        <div className="field_td field_td3">
                            <div className="label">Тип операции</div>

                            <input
                                type="text"
                                name="name"
                                className="field"
                                value={
                                    item.type
                                        ?
                                        searchInArray(references.typesList, item.type, 0, 1)
                                        :
                                        ''
                                }
                                readOnly
                            />

                        </div>
                        <div className="field_td field_td3">
                            <div className="label">Примечание</div>

                            <input
                                type="text"
                                name="name"
                                className="field"
                                value={ item.note || '' }
                                readOnly
                            />

                        </div>
                        <div className="field_td field_td3">
                            <div className="label">Пользователь</div>

                            <input
                                type="text"
                                name="name"
                                className="field"
                                value={
                                    item.user_id
                                        ?
                                        item.user.last_name + ' ' + item.user.first_name
                                        :
                                        ''
                                }
                                readOnly
                            />

                        </div>
                    </div>

                    <div className="field_tr">
                        <div className="field_td field_td3">
                            <div className="label">Сумма операции</div>

                            <input
                                type="text"
                                name="name"
                                className="field"
                                value={
                                    item.sum
                                        ?
                                        SumFormat(item.sum) + '  ₽'
                                        :
                                        ''
                                }
                                readOnly
                            />

                        </div>
                        <div className="field_td field_td3">
                            <div className="label">Дата операции</div>

                            <input
                                type="text"
                                name="name"
                                className="field"
                                value={
                                    item.created_at
                                        ?
                                        dateFormat(item.created_at, 'd.m.Y в H:i')
                                        :
                                        ''
                                }
                                readOnly
                            />

                        </div>
                        <div className="field_td field_td3">
                            <div className="label">Статус</div>

                            <input
                                type="text"
                                name="name"
                                className="field"
                                value={
                                    item.status
                                        ?
                                        searchInArray(references.statusList, item.status, 0, 1)
                                        :
                                        ''
                                }
                                readOnly
                            />

                        </div>
                    </div>

                    <div className="modal_info_razd"></div>

                    <div className="field_tr">
                        <div className="field_td field_td3">
                            <div className="label">Паспортные данные</div>

                            <input
                                type="text"
                                name="name"
                                className="field"
                                value={
                                    item.content
                                        ?
                                        item.content.pasport_num
                                        :
                                        ''
                                }
                                readOnly
                            />

                        </div>
                        <div className="field_td field_td3x2">
                            <div className="label">&nbsp;</div>

                            <input
                                type="text"
                                name="name"
                                className="field"
                                value={
                                    item.content
                                        ?
                                        item.content.pasport_who
                                        :
                                        ''
                                }
                                readOnly
                            />

                        </div>
                    </div>

                    <div className="field_tr">
                        <div className="field_td field_td3x2">

                            <input
                                type="text"
                                name="name"
                                className="field"
                                value={
                                    item.content
                                        ?
                                        item.content.pasport_fio
                                        :
                                        ''
                                }
                                readOnly
                            />

                        </div>
                        <div className="field_td field_td3">

                            <input
                                type="text"
                                name="name"
                                className="field"
                                value={
                                    item.content
                                        ?
                                        item.content.pasport_birth
                                        :
                                        ''
                                }
                                readOnly
                            />

                        </div>
                    </div>

                    <div className="field_tr">
                        <div className="field_td field_td1">

                            <input
                                type="text"
                                name="name"
                                className="field"
                                value={
                                    item.content
                                        ?
                                        item.content.pasport_adres
                                        :
                                        ''
                                }
                                readOnly
                            />

                        </div>
                    </div>

                    <div className="modal_info_razd"></div>

                    <div className="field_tr">
                        <div className="field_td field_td3">
                            <div className="label">Банковские реквизиты</div>

                            <input
                                type="text"
                                name="name"
                                className="field"
                                value={
                                    item.content
                                        ?
                                        item.content.bank_name
                                        :
                                        ''
                                }
                                readOnly
                            />

                        </div>
                        <div className="field_td field_td3">
                            <div className="label">&nbsp;</div>

                            <input
                                type="text"
                                name="name"
                                className="field"
                                value={
                                    item.content
                                        ?
                                        item.content.bank_bik
                                        :
                                        ''
                                }
                                readOnly
                            />

                        </div>
                        <div className="field_td field_td3">
                            <div className="label">&nbsp;</div>

                            <input
                                type="text"
                                name="name"
                                className="field"
                                value={
                                    item.content
                                        ?
                                        item.content.bank_inn
                                        :
                                        ''
                                }
                                readOnly
                            />

                        </div>
                    </div>

                    <div className="field_tr">
                        <div className="field_td field_td2">

                            <input
                                type="text"
                                name="name"
                                className="field"
                                value={
                                    item.content
                                        ?
                                        item.content.bank_kor
                                        :
                                        ''
                                }
                                readOnly
                            />

                        </div>
                        <div className="field_td field_td2">

                            <input
                                type="text"
                                name="name"
                                className="field"
                                value={
                                    item.content
                                        ?
                                        item.content.bank_ras
                                        :
                                        ''
                                }
                                readOnly
                            />

                        </div>
                    </div>


                </div>

            </div>

        </ModalWindow>
    );
};

export default BonusesOperationsViewType2;