import React, {useState, useEffect} from 'react';
import ModalWindow from "../../../Interface/ModalWindow/ModalWindow";
import FormInputClear from "../../../Interface/Forms/FormInputClear";
import EditorRollUpImg from "../../../../assets/img/p_editor_roll_up.svg";
import EditorRollDownImg from "../../../../assets/img/p_editor_roll_down.svg";
import ReplicsService from "../../../../services/ReplicsService";
import {NotificationsAdd, NotificationsArrAdd} from "../../../../utils/NotificationsAdd";
import FormTinyMCEEditor from "../../../Interface/Forms/FormTinyMCEEditor";
import {Errors422} from "../../../../utils/Errors422";
import ScriptsEditLeftFormAnswersList from "./FormsComponents/ScriptsEditLeftFormAnswersList";
import ScriptsEditLeftFormAnswersAdd from "./FormsComponents/ScriptsEditLeftFormAnswersAdd";
import ScriptsEditLeftFormLinksList from "./FormsComponents/ScriptsEditLeftFormLinksList";
import {Find2List} from "../../../../utils/Find2List";


const ScriptsEditLeftEditForm = ({modal, setModal, projectId, userId, tekItemId, updTrigger, setUpdTrigger, notifications, setNotifications, firstReplicId}) => {

    //Данные создания реплик
    const nullReplicItem = {
        user_id: userId,
        project_id: projectId,
        names: '',
        parent_replic_id: tekItemId,
    };


    //Данные сохранения
    const nullItem = {
        name: '',
        desc: '',
        childs_replics_num: 0,
        parents_replics_num: 0,
        parents_replics: [],
        childs_replics: [],
    };

    //
    const [item, setItem] = useState(nullItem);
    const [itemAnswers, setItemAnswers] = useState('');
    const [replicItem, setReplicItem] = useState(nullReplicItem);
    const [replicItemStatus, setReplicItemStatus] = useState(0);


    //Прикрепление
    const [items, setItems] = useState([]);
    const [parentsStatus, setParentsStatus] = useState({});
    const [childsStatus, setChildsStatus] = useState({});


    //Состояние скрытия
    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);


    //Обнулить статус
    useEffect(() => {
        setReplicItemStatus(0);
    }, [modal, modal]);


    //Получение данных
    useEffect(() => {

        if(modal) {

            //
            if (tekItemId === 0)
            {
                setItems([]);
                setItem(nullItem);
                setParentsStatus({});
                setChildsStatus({});
            }
            else
            {
                const getData = async (id) => {


                    //Все реплики
                    const response1 = await ReplicsService.getAll(1, 1000, 'position', 'asc', {project_id: {v:projectId, t:'='}});
                    setItems(response1.data.data.items);


                    //Данные текущей реплики
                    const response2 = await ReplicsService.getById(id, 'parents_replics,childs_replics');
                    //Если повторная выборка, сохраняем поля ввода
                    if(replicItemStatus === 1)
                    {
                        response2.data.data.name = item.name;
                        response2.data.data.desc = item.desc;
                    }
                    setItem(response2.data.data);


                    //Статус родительских реплик
                    setParentsStatus(
                        Find2List(response1.data.data.items, response2.data.data.parents_replics)
                    );

                    //Статус дочерних реплик
                    setChildsStatus(
                        Find2List(response1.data.data.items, response2.data.data.childs_replics)
                    );
                }
                getData(tekItemId);
            }

            //
            setReplicItem(nullReplicItem);
        }

    }, [modal, updTrigger]);


    //Сохранение данных
    const handleSubmit = async () => {

        //
        let errBeforeArr = [];
        let errAfterArr = [];

        //
        if(item.name === '')
        {
            errBeforeArr.push('Поле «Название ответа» не заполнено.');
        }


        //
        if(tekItemId === firstReplicId && item.name !== 'Начало разговора')
        {
            errBeforeArr.push('Переименование ответа «Начало разговора» запрещено.');
        }


        if(errBeforeArr.length > 0)
        {
            NotificationsArrAdd(notifications, setNotifications, errBeforeArr, 'not1');
        }
        else
        {
            try {
                //
                let response1 = await ReplicsService.updateById(tekItemId, item);
                let response2 = await ReplicsService.linksUpdate(tekItemId, 'parents', parentsStatus);
                let response3 = await ReplicsService.linksUpdate(tekItemId, 'childs', childsStatus);

                //
                if( (response1.data.status === 'ok') && (response2.data.status === 'ok') && (response3.data.status === 'ok') )
                {
                    setModal(false);
                    NotificationsAdd(notifications, setNotifications, 'Карточка ответа была успешна изменена.', 'ok');
                    setUpdTrigger( Date.now() );
                }
                else
                {
                    errAfterArr.push('Ошибка сохранения карточки ответа.');
                }
            }
            catch (e) {
                Errors422(e, errAfterArr, 'Ошибка сохранения скрипта.');
            }
        }

        //
        if(errAfterArr.length > 0)
        {
            NotificationsArrAdd(notifications, setNotifications, errAfterArr, 'not2');
        }

    }


    //Отмена
    const cancelSubmit = () => {
        setModal(false);
    }


    //Отмена
    const addReplics = async (e) => {

        //
        e.preventDefault();

        //
        let errBeforeArr = [];
        let errAfterArr = [];


        //
        if(replicItem.names === '')
        {
            errBeforeArr.push('Поле «Добавить новые ответы» не заполнено');
        }

        if(errBeforeArr.length > 0)
        {
            NotificationsArrAdd(notifications, setNotifications, errBeforeArr, 'not1');
        }
        else
        {
            try {
                //
                let response = await ReplicsService.createMass(replicItem);

                //
                if(response.data.status === 'ok')
                {
                    NotificationsAdd(notifications, setNotifications, 'Новые карточки ответа были успешно созданы .', 'ok');
                    setReplicItemStatus(1);
                    setUpdTrigger( Date.now() );
                    setReplicItem(nullReplicItem);
                }
                else
                {
                    errAfterArr.push('Ошибка создания карточек ответа.');
                }
            }
            catch (e) {
                Errors422(e, errAfterArr, 'Ошибка создания карточек ответа.');
            }

            if(errAfterArr.length > 0)
            {
                NotificationsArrAdd(notifications, setNotifications, errAfterArr, 'not2');
            }

        }

    }


    //Открытие/Скрытие
    const open1Toggle = () => {
        setOpen1(!open1);
    }

    const open2Toggle = () => {
        setOpen2(!open2);
    }


    //Смена статуса - родителькие
    const changeAttachParents = (id) => {

        //Определить новый статус
        let newStatus = 1;
        if( (parentsStatus[id] !== undefined) && (parentsStatus[id] === 1) )
        {
            newStatus = 0;
        }

        //Сохранить новый статус
        setParentsStatus({...parentsStatus, ...{[id]: newStatus}});
    }


    //Смена статуса - дочерние
    const changeAttachChilds = (id) => {

        //Определить новый статус
        let newStatus = 1;
        if( (childsStatus[id] !== undefined) && (childsStatus[id] === 1) )
        {
            newStatus = 0;
        }

        //Сохранить новый статус
        setChildsStatus({...childsStatus, ...{[id]: newStatus}});
    }


    //Передача функций
    const parents_replics_func = {
        changeAttach: changeAttachParents,
    }


    //Передача функций
    const childs_replics_func = {
        changeAttach: changeAttachChilds,
    }


    //Вывод компонента
    return (
        <ModalWindow
            title="Управление карточкой"
            active={modal}
            setActive={setModal}
            footer={
                {
                    leftBtn: {
                        title: 'Отменить',
                        func: cancelSubmit
                    },
                    rightBtn: {
                        title: 'Сохранить',
                        func: handleSubmit
                    }
                }
            }
        >

            <div className="p_editor_modal_form" id="p_editor_modal_form_edit">

                <div className="field_tr">
                    <div className="field_td field_td1">
                        <div className="label">Название ответа</div>

                        <FormInputClear
                            name="name"
                            value={item.name}
                            setValue={setItem}
                            item={item}
                            placeholder="Введите название ответа"
                            disabled={tekItemId === firstReplicId ? 'disabled' : ''}
                        />

                    </div>
                </div>


                <div className="field_tr">
                    <div className="field_td field_td1">
                        <div className="label">Реплика менеджера</div>
                        {
                            item.desc === '' || item.desc === null
                                ?
                                <div className="sub_label">
                                    Введите реплику, который должен произнести менеджер
                                </div>
                                :
                                <></>
                        }
                        <FormTinyMCEEditor
                            name="desc"
                            value={item.desc}
                            setValue={setItem}
                            item={item}
                        />
                    </div>
                </div>


                <div className="field_tr">
                    <div className="field_td field_td1">
                        <div className="label">Добавить новые ответы</div>
                        <div className="sub_label">Вводите новые варианты ответа для добавления их в скрипт продаж,
                            разделяя их запятой
                        </div>

                        <div className="field_with_btn_out">

                            <ScriptsEditLeftFormAnswersAdd
                                name="names"
                                value={replicItem.names}
                                setValue={setReplicItem}
                                item={replicItem}
                                items={items}
                                placeholder="Введите список новых ответов через запятую"
                            />

                            <a
                                href=""
                                onClick={ e => addReplics(e) }
                                className="btn"
                            >Добавить</a>
                        </div>

                    </div>
                </div>


                <div className="field_tr">
                    <div className={"field_td field_td1 p_editor_roll__state " + (open1 === true ? 'p_editor_roll__open' : 'p_editor_roll__close') }>
                        <div className="label">
                            Прикрепленные ответы к карточке
                            <span className="label_count">({item.childs_replics_num})</span>
                            <div className="p_editor_roll">
                                <div
                                    className="p_editor_roll_elem p_editor_roll_down"
                                    onClick={ () => open1Toggle() }
                                >
                                    Развернуть
                                    <img src={EditorRollDownImg} alt=""/>
                                </div>
                                <div
                                    className="p_editor_roll_elem p_editor_roll_up"
                                    onClick={ () => open1Toggle() }
                                >
                                    Свернуть
                                    <img src={EditorRollUpImg} alt=""/>
                                </div>
                            </div>
                        </div>
                        <div className="p_editor_left_item_answers_list">

                            <ScriptsEditLeftFormAnswersList
                                items={items}
                                func={childs_replics_func}
                                status={childsStatus}
                                excludeId={tekItemId}
                            />

                        </div>
                    </div>
                </div>


                <div className="field_tr">
                    <div className={"field_td field_td1 p_editor_roll__state " + (open2 === true ? 'p_editor_roll__open' : 'p_editor_roll__close') }>
                        <div className="label">Связи карточки с другими
                            <span className="label_count">({item.parents_replics_num})</span>
                            <div className="p_editor_roll">
                                <div
                                    className="p_editor_roll_elem p_editor_roll_down"
                                    onClick={ () => open2Toggle() }
                                >
                                    Развернуть
                                    <img src={EditorRollDownImg} alt=""/>
                                </div>
                                <div
                                    className="p_editor_roll_elem p_editor_roll_up"
                                    onClick={ () => open2Toggle() }
                                >
                                    Свернуть
                                    <img src={EditorRollUpImg} alt=""/>
                                </div>
                            </div>
                        </div>
                        <div className="sub_label">Здесь показывается к каким еще карточкам прикреплен данный ответ
                        </div>

                        <div className="p_editor_left_item_answers_list">

                            <ScriptsEditLeftFormLinksList
                                items={items}
                                func={parents_replics_func}
                                status={parentsStatus}
                                excludeId={tekItemId}
                            />

                        </div>
                    </div>
                </div>


            </div>

        </ModalWindow>
    );
};

export default ScriptsEditLeftEditForm;