import React, {useState} from 'react';
import {useParams} from "react-router-dom";
import ScriptsViewHeader from "./ScriptsViewHeader";
import ScriptsViewLeft from "./ScriptsViewLeft";
import ScriptsViewWorkArea from "./ScriptsViewWorkArea";
import ScriptsViewFooter from "./ScriptsViewFooter";

import "../../../assets/css/view.css";
import NotificationsList from "../../Interface/Notifications/NotificationsList";

const ScriptsViewContent = () => {

    //Получить ID скрипта
    const {id} = useParams();


    //Выбранная реплика
    const [replicId, setReplicId] = useState(0);


    //Первая реплика
    const [firstReplicId, setFirstReplicId] = useState(0);


    //Уведомления
    const [notifications, setNotifications] = useState([]);


    //Вывод компонента
    return (
        <div id="p_view_page">

            <ScriptsViewHeader
                id={id}
                replicId={replicId}
                setReplicId={setReplicId}
                setFirstReplicId={setFirstReplicId}
                notifications={notifications}
                setNotifications={setNotifications}
            />

            <ScriptsViewLeft
                id={id}
                replicId={replicId}
                setReplicId={setReplicId}
            />

            <ScriptsViewWorkArea
                id={id}
                replicId={replicId}
                setReplicId={setReplicId}
                firstReplicId={firstReplicId}
                notifications={notifications}
                setNotifications={setNotifications}
            />

            <ScriptsViewFooter
                id={id}
            />

            <NotificationsList
                items={notifications}
                setItems={setNotifications}
            />

        </div>
    );
};

export default ScriptsViewContent;