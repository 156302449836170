import React from 'react';
import {Link, useMatch} from "react-router-dom";

const MainMenuItem = (props) => {
    return (
        <li className={useMatch(props.to + '/*') ? 'active' : ''}>
            <Link to={props.to}>
                {props.icon}
                <span>{props.title}</span>
            </Link>
        </li>
    );
};

export default MainMenuItem;