import React from 'react';
import TariffsContent from "../../components/Pages/Tariffs/TariffsContent";

const TariffsPage = () => {
    return (
        <div>
            <div id="p_account_workarea_title">Тарифы и оплата</div>
            <TariffsContent />
        </div>
    );
};

export default TariffsPage;