import React, {useContext, useState} from 'react';
import {AuthContext} from "../../../context/AuthContext";
import ModalWindow from "../../Interface/ModalWindow/ModalWindow";
import FormInputHide from "../../Interface/Forms/FormInputHide";
import ProfileService from "../../../services/ProfileService";
import {Errors422} from "../../../utils/Errors422";
import NotificationsList from "../../Interface/Notifications/NotificationsList";
import {NotificationsAdd, NotificationsArrAdd} from "../../../utils/NotificationsAdd";


const ProfilePass = ({modal, setModal}) => {

    //Получение пользователя
    const {AppAuthStore} = useContext(AuthContext);


    //Получение данных
    const nullItem = {
        tek_password: '',
        password: '',
        password_confirmation: '',
    };

    const [pass, setPass] = useState(nullItem);


    //Уведомления
    const [notifications, setNotifications] = useState([]);


    //Сохранение данных
    const handleSubmit = async () => {

        //
        let errBeforeArr = [];
        let errAfterArr = [];

        //
        if(pass.tek_password === '')
        {
            errBeforeArr.push('Поле «Текущий пароль» не заполнено');
        }

        //
        if(pass.password === '')
        {
            errBeforeArr.push('Поле «Новый пароль» не заполнено');
        }

        //
        if(pass.password_confirmation === '')
        {
            errBeforeArr.push('Поле «Повторите новый пароль» не заполнено');
        }

        //
        if( (pass.password !== '') && (pass.password_confirmation !== '') && (pass.password !== pass.password_confirmation) )
        {
            errBeforeArr.push('Поля «Новый пароль» и «Повторите новый пароль» не совпадают');
        }


        if(errBeforeArr.length > 0)
        {
            NotificationsArrAdd(notifications, setNotifications, errBeforeArr, 'not1');
        }
        else
        {

            //
            try {
                const response = await ProfileService.passwordChangeById(AppAuthStore.user.id, pass);

                if(response.data.status === 'ok')
                {
                    //
                    setPass(nullItem);

                    //
                    setModal(false);

                    //
                    NotificationsAdd(notifications, setNotifications, 'Пароль от профиля был успешно изменен!', 'ok');
                }
                else
                {
                    errAfterArr.push('Ошибка смены пароля.');
                }
            }
            catch (e) {
                Errors422(e, errAfterArr, 'Ошибка смены пароля.', 1);
            }

        }

        //
        if(errAfterArr.length > 0)
        {
            NotificationsArrAdd(notifications, setNotifications, errAfterArr, 'not2');
        }

    }


    //Отмена сохранения
    const cancelSubmit = () => {
        setModal(false);
    }


    //Вывод компонента
    return (
        <>

            <ModalWindow
                title="Изменить пароль"
                active={modal}
                setActive={setModal}
                footer={
                    {
                        leftBtn: {
                            title: 'Отменить',
                            func: cancelSubmit
                        },
                        rightBtn: {
                            title: 'Сохранить',
                            func: handleSubmit
                        }
                    }
                }
            >

                <div id="p_account_personal_passchange">

                    <div className="field_tr">
                        <div className="field_td field_td1">

                            <div id="form_msg">

                                <div className="form_msg_title">При создании пароля мы рекомендуем:</div>

                                <div className="form_msg_text">
                                    <ul>
                                        <li>Составить пароль минимум от 6 символов</li>
                                        <li>Указать минимум одну заглавную букву</li>
                                    </ul>
                                </div>

                            </div>

                        </div>
                    </div>

                    <form>

                        <div className="field_tr">
                            <div className="field_td field_td1">
                                <div className="label">Текущий пароль <span className="zv">*</span></div>
                                <FormInputHide
                                    name="tek_password"
                                    value={pass.tek_password}
                                    setValue={setPass}
                                    item={pass}
                                />
                            </div>
                        </div>

                        <div className="field_tr">
                            <div className="field_td field_td1">
                                <div className="label">Новый пароль <span className="zv">*</span></div>
                                <FormInputHide
                                    name="password"
                                    value={pass.password}
                                    setValue={setPass}
                                    item={pass}
                                />
                            </div>
                        </div>

                        <div className="field_tr">
                            <div className="field_td field_td1">
                                <div className="label">Повторите новый пароль <span className="zv">*</span></div>
                                <FormInputHide
                                    name="password_confirmation"
                                    value={pass.password_confirmation}
                                    setValue={setPass}
                                    item={pass}
                                />
                            </div>
                        </div>

                    </form>

                </div>

            </ModalWindow>


            <NotificationsList
                items={notifications}
                setItems={setNotifications}
            />


        </>
    );
};

export default ProfilePass;