//Выбор реплики
const selectReplic = (e, id, setReplicId) => {
    e.preventDefault();
    e.stopPropagation();
    setReplicId(id);
}


//Удаление записи
const delItemInit = (e, id, setTekItemId, setModal) => {
    e.preventDefault();
    setTekItemId(id);
    setModal(true);
}


//Редактирование записи
const editItemInit = (e, id, setTekItemId, setModal) => {
    e.preventDefault();
    setTekItemId(id);
    setModal(true);
}


//Редактирование записи
const attachItemInit = (e, id, setTekItemId, setModal) => {
    e.preventDefault();
    setTekItemId(id);
    setModal(true);
}



//Передача функций
export const ScriptsEditReplicFunc = {
    select: selectReplic,
    del: delItemInit,
    edit: editItemInit,
    attach: attachItemInit,
}
