import React from 'react';
import ModalWindowEmpty from "./ModalWindowEmpty";
import ActionDelImg from "../../../assets/img/modal_action_del.svg";
import ActionForbiddenImg from "../../../assets/img/modal_action_forbidden.svg";
import CloseImg from "../../../assets/img/modal_window_close.svg";
import ModalWindowFooter from "./ModalWindowFooter";

const ModalWindow = ({title, children, footer, active, setActive, type}) => {

    //Вывод компонента
    return (
        <ModalWindowEmpty active={active} setActive={setActive} type={type}>

            <div className="modal_window">

                {
                    type === 'del'
                    ?
                        <div className="modal_action_img">
                            <img src={ActionDelImg} alt="" />
                        </div>
                        :
                        <></>
                }

                {
                    type === 'forbidden'
                        ?
                        <div className="modal_action_img">
                            <img src={ActionForbiddenImg} alt="" />
                        </div>
                        :
                        <></>
                }

                <div className="modal_window_head">
                    {title}
                    <div className="modal_window_close" onClick={() => setActive(false)}>
                        <img src={CloseImg} />
                    </div>
                </div>
                <div className="modal_window_body">
                    {children}
                </div>
                {
                    footer
                    ?
                        <ModalWindowFooter obj={footer}/>
                        :
                        <></>
                }
            </div>

        </ModalWindowEmpty>
    );
};

export default ModalWindow;