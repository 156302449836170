import {useLocation} from "react-router-dom";


const useRef = () => {

    const param = useLocation();
    const ref = new URLSearchParams(param.search).get("ref");

    if(ref !== null && ref !== '')
    {
        const ref_user_id = Number(ref) - Number(process.env.REACT_APP_REF_DELTA) ;

        localStorage.setItem('REF_USER_ID', ref_user_id);
    }

}

export default useRef;


