import React from 'react';
import ScriptsContent from "../../components/Pages/Scripts/ScriptsContent";

const ScriptsPage = () => {
    return (
        <div>
            <div id="p_account_workarea_title">Скрипты продаж</div>
            <ScriptsContent />
        </div>
    );
};

export default ScriptsPage;