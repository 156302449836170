import React, {useState, useEffect} from 'react';
import ScriptsEditWorkAreaReplicLoad from "./ScriptsEditWorkAreaReplicLoad";
import ReplicsService from "../../../../services/ReplicsService";
import ScriptsEditWorkAreaReplicNull from "./ScriptsEditWorkAreaReplicNull";
import ScriptsEditWorkAreaReplicContent from "./ScriptsEditWorkAreaReplicContent";
import ScriptsEditWorkAreaReplicBack from "./ScriptsEditWorkAreaReplicBack";

const ScriptsEditWorkAreaReplic = ({id, replicId, setReplicId, firstReplicId, updTrigger, setUpdTrigger, notifications, setNotifications}) => {

    //Данные реплики
    const nullItem = {
        name: '',
        desc: '',
        childs_replics: [],
    };

    //
    const [item, setItem] = useState(nullItem);

    //Загрузка реплики
    const [isItemLoading, setIsAItemLoading] = useState(true);


    //Получение данных
    useEffect(() => {

        const getItem = async (id) => {

            try {
                //
                const response = await ReplicsService.getById(id, 'childs_replics');

                //
                if(response.data.status === 'ok')
                {
                    setItem(response.data.data);

                    setIsAItemLoading(false);
                }
            }
            catch (e) {
                //
            }
        }

        getItem(replicId);

    }, [replicId, updTrigger]);


    //Вывод компонента
    return (
        <>
            {
                isItemLoading
                    ?
                    <ScriptsEditWorkAreaReplicLoad />
                    :
                    <>

                        {
                            (item.desc === '' || item.desc === null) && item.childs_replics_num === 0
                                ?
                                <ScriptsEditWorkAreaReplicNull
                                    item={item}
                                />
                                :
                                <ScriptsEditWorkAreaReplicContent
                                    item={item}
                                    setReplicId={setReplicId}
                                />
                        }

                        <ScriptsEditWorkAreaReplicBack
                            replicId={replicId}
                            setReplicId={setReplicId}
                            firstReplicId={firstReplicId}
                        />

                    </>
            }
        </>
    );
};

export default ScriptsEditWorkAreaReplic;