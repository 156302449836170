import React from 'react';
import ScriptsToolsItem from "./ScriptsToolsItem";
import ScriptsToolsAddBtn from "./ScriptsToolsAddBtn";


const ScriptsToolsList = ({items, func, filters, limit}) => {


    //Вывод компонента
    return (
        <>

            {
                items.map(
                    (item, index) =>
                        <ScriptsToolsItem
                            key={item.id}
                            item={item}
                            func={func}
                            filters={filters}
                        />
                )
            }

            {
                items.length < limit
                ?
                    <ScriptsToolsAddBtn
                        func={func}
                    />
                    :
                    <></>
            }

        </>
    );
};

export default ScriptsToolsList;