import React from 'react';
import ScriptsEditLeftItemAnswersItem from "./ScriptsEditLeftItemAnswersItem";

const ScriptsEditLeftItemAnswersList = ({items, func, setters}) => {

    //Вывод компонента
    return (
        <>
            {
                items.map(
                    (item, index) =>
                        <ScriptsEditLeftItemAnswersItem
                            key={item.id}
                            item={item}
                            func={func}
                            setters={setters}
                        />
                )
            }
        </>
    );
};

export default ScriptsEditLeftItemAnswersList;