import React, {useState, useEffect} from 'react';
import ModalWindow from "../../Interface/ModalWindow/ModalWindow";
import FormInputClear from "../../Interface/Forms/FormInputClear";
import ScriptsService from "../../../services/ScriptsService";
import {Errors422} from "../../../utils/Errors422";
import NotificationsList from "../../Interface/Notifications/NotificationsList";
import {NotificationsAdd, NotificationsArrAdd} from "../../../utils/NotificationsAdd";


const ScriptsRenameForm = ({modal, setModal, tekItemId, setUpdTrigger}) => {


    //Данные сохранения
    const nullItem = {
        name: ''
    };

    //
    const [item, setItem] = useState(nullItem);


    //Уведомления
    const [notifications, setNotifications] = useState([]);


    //Получение данных
    useEffect(() => {

        if(modal) {
            if (tekItemId === 0) {
                setItem(nullItem);
            } else {
                const getItem = async (id) => {
                    const response = await ScriptsService.getById(id);
                    setItem(response.data.data);
                }
                getItem(tekItemId);
            }
        }

    }, [modal, modal]);


    //Сохранение данных
    const handleSubmit = async () => {

        //
        let errBeforeArr = [];
        let errAfterArr = [];


        //
        if(item.name === '')
        {
            errBeforeArr.push('Поле «Название скрипта продаж» не заполнено');
        }


        if(errBeforeArr.length > 0)
        {
            NotificationsArrAdd(notifications, setNotifications, errBeforeArr, 'not1');
        }
        else
        {
            try {
                //
                let response = await ScriptsService.updateById(tekItemId, item);

                //
                if(response.data.status === 'ok')
                {
                    //
                    setModal(false);

                    //
                    NotificationsAdd(notifications, setNotifications, 'Скрипт был успешно переименован.', 'ok');

                    //
                    setUpdTrigger( Date.now() );
                }
                else
                {
                    errAfterArr.push('Ошибка сохранения скрипта.');
                }
            }
            catch (e) {
                Errors422(e, errAfterArr, 'Ошибка сохранения скрипта.', 1);
            }

        }

        //
        if(errAfterArr.length > 0)
        {
            NotificationsArrAdd(notifications, setNotifications, errAfterArr, 'not2');
        }

    }


    //Отмена
    const cancelSubmit = () => {
        setModal(false);
    }


    //Вывод компонента
    return (
        <>

            <ModalWindow
                title="Переименовать скрипт"
                active={modal}
                setActive={setModal}
                footer={
                    {
                        leftBtn: {
                            title: 'Отменить',
                            func: cancelSubmit
                        },
                        rightBtn: {
                            title: 'Переименовать скрипт',
                            func: handleSubmit
                        }
                    }
                }
            >

                <div id="p_account_scripts_modal_rename">


                    <div className="field_tr">

                        <div className="field_td field_td1">
                            <div className="label">Название скрипта продаж</div>

                            <FormInputClear
                                name="name"
                                value={item.name}
                                setValue={setItem}
                                item={item}
                            />

                        </div>
                    </div>

                </div>

            </ModalWindow>


            <NotificationsList
                items={notifications}
                setItems={setNotifications}
            />


        </>
    );
};

export default ScriptsRenameForm;
