import React from 'react';

const ScriptsEditLeftFormAnswersItem = ({item, func, status}) => {


    //Доп. классы
    let classNameAdd = '';

    if(status !== undefined)
    {
        if( (status[item.id] === undefined) || (status[item.id] === 0) )
        {
            classNameAdd = ' disabled';
        }
    }


    //Вывод компонента
    return (
        <div
            onClick={e => func.changeAttach(item.id)}
            className={'p_editor_left_item_answers_item' + classNameAdd}
            value={item.name}
        >
            {item.name}
        </div>
    );
};

export default ScriptsEditLeftFormAnswersItem;