import React, {useState, useEffect} from 'react';
import {Link} from "react-router-dom";
import ScriptsService from "../../../services/ScriptsService";
import EditorDownImg from '../../../assets/img/p_editor_down.svg';
import SubMenu from "../../Interface/SubMenu/SubMenu";
import {NotificationsAdd} from "../../../utils/NotificationsAdd";
import EditorBackImg from "../../../assets/img/p_editor_back.svg";
import LogoImg from "../../../assets/img/Logo.svg";
import ShareImg from "../../../assets/img/Share.svg";

const ScriptsViewHeader = ({id, replicId, setReplicId, setFirstReplicId, notifications, setNotifications}) => {

    //Данные скрипта
    const nullItem = {
        name: ''
    };

    //
    const [item, setItem] = useState(nullItem);


    //Состояние меню
    const [submenu, setSubmenu] = useState(false);


    //Открытие меню
    const submenuToggle = (e) => {

        if(!submenu)
        {
            setSubmenu(true);
        }
        else
        {
            setSubmenu(false);
        }
    }


    //Получение данных
    useEffect(() => {

        const getItem = async (id) => {

            try {
                //
                const response = await ScriptsService.getById(id);

                //
                if(response.data.status === 'ok')
                {
                    setItem(response.data.data);
                    setReplicId(response.data.data.first_replic.id);
                    setFirstReplicId(response.data.data.first_replic.id);
                }
            }
            catch (e) {
                //
                if(e.code === 'ERR_BAD_REQUEST')
                {
                    //
                }
            }
        }

        getItem(id);

    }, []);


    //Поделиться ссылкой
    const shareLink = (e, id, notifications, setNotifications) => {
        e.preventDefault();

        //Ссылка на просмотр
        const LinkStr = process.env.REACT_APP_BASE_URL + '/scripts/' + id + '/view';

        //Копирование в буфер
        navigator.clipboard.writeText(LinkStr);

        //Вывод уведомления
        NotificationsAdd(
            notifications,
            setNotifications,
            'Ссылка на скрипт скопирована в буфер обмена.',
            'ok'
        );
    }


    //Вывод компонента
    return (
        <div id="p_view_header">

            <Link to="/scripts" id="p_view_header_back">
                <img src={EditorBackImg} alt=""/>
            </Link>

            <Link to="/scripts" id="p_view_header_logo">
                <img src={LogoImg} alt=""/>
            </Link>


            <div id="p_view_header_name_line">

                <div id="p_view_header_name_text">
                    {item.name}
                </div>

                <div id="p_editor_header_name_down_out">

                    <div id="p_editor_header_name_down" onClick={() => submenuToggle()}>
                        <img src={EditorDownImg} alt=""/>
                    </div>

                    <div id="p_editor_header_name_submenu">
                        <SubMenu active={submenu} setActive={setSubmenu}>
                            <ul>
                                <li>
                                    <Link to={"/scripts/" + id + "/edit"}>Открыть редактор</Link>
                                </li>
                            </ul>
                        </SubMenu>
                    </div>

                </div>

            </div>


            <a
                href=""
                onClick={e => shareLink(e, id, notifications, setNotifications)}
                id="p_view_header_play"
            >
                <img src={ShareImg} alt=""/>
                Поделиться
            </a>


        </div>
    );
};

export default ScriptsViewHeader;