import React  from 'react';
import LoginForm from "./LoginForm";
import ImgVK from "../../../assets/img/p_login_auth_form_vk.svg";
import ImgGoogle from "../../../assets/img/p_login_auth_form_google.svg";
import ImgYandex from "../../../assets/img/p_login_auth_form_yandex.svg";
import {Link} from "react-router-dom";
import LoginULogin from "./LoginULogin";


const LoginContent = () => {


    //Открыть окно авторизации
    const openPopup = async (e, elemStr) => {

        //
        e.preventDefault();

        //
        document.querySelector(elemStr).click();
    }


    //Вывод компонента
    return (
        <div id="p_login_auth">

            <div id="p_login_auth_left">

                <div id="p_login_auth_obg">
                    <div id="p_login_auth_obg_booble">
                        Все мои скрипты продаж находятся
                        в удобном формате онлайн.
                        А как работаешь со скриптами ты?
                    </div>
                    <div id="p_login_auth_obg_fon"></div>
                    <div id="p_login_auth_obg_stool"></div>
                    <div id="p_login_auth_obg_man"></div>
                </div>

            </div>

            <div id="p_login_auth_right">

                <div id="p_login_auth_form">

                    <div id="p_login_form_title">
                        Добро<br />
                        пожаловать!
                    </div>

                    <div id="p_login_form_desc">
                        Зарегистрируйтесь или авторизуйтесь<br />
                        в системе для дальнейшей работы
                    </div>

                    <div id="p_login_auth_form_body">

                        <LoginForm/>

                        <div id="p_login_auth_form_soc_title">
                            Или войдите через:
                        </div>

                        <div id="p_login_auth_form_soc_list_hide">
                            <LoginULogin />
                        </div>

                        <div id="p_login_auth_form_soc_list">

                            <div className="p_login_auth_form_soc_item_out">
                                <a
                                    href=""
                                    className="p_login_auth_form_soc_item"
                                    onClick={ e => openPopup(e, '.ulogin-button-vkontakte') }
                                >
                                    <img src={ImgVK} alt=""/>
                                </a>
                            </div>

                            <div className="p_login_auth_form_soc_item_out">
                                <a
                                    href=""
                                    className="p_login_auth_form_soc_item"
                                    onClick={ e => openPopup(e, '.ulogin-button-google') }
                                >
                                    <img src={ImgGoogle} alt=""/>
                                </a>
                            </div>

                            <div className="p_login_auth_form_soc_item_out">
                                <a
                                    href=""
                                    className="p_login_auth_form_soc_item"
                                    onClick={ e => openPopup(e, '.ulogin-button-yandex') }
                                >
                                    <img src={ImgYandex} alt=""/>
                                </a>
                            </div>

                        </div>

                        <div className="last_btn_label last_btn_label_center">
                            У вас нет аккаунта? <Link to="/register">Зарегистрируйтесь</Link>
                        </div>

                    </div>


                </div>

                <div id="p_login_form_copy">
                    ⓒ 2024
                </div>

            </div>

        </div>
    );
};

export default LoginContent;