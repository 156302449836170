import React from 'react';
import {Link} from "react-router-dom";

const RestoreStep4 = ({item, setItem, step, setStep}) => {


    //Вывод компонента
    return (
        <>

            <div id="p_login_form_title">
                Пароль<br/>
                восстановлен
            </div>
            <div id="p_login_form_desc">
                Вы можете вернуться к авторизации<br/>
                и войти в аккаунт используя новые данные
            </div>

            <div id="p_login_rec_form_body">

                <div className="field_tr btn_tr">
                    <div className="field_td field_td1">

                        <Link to="/login" className="btn">К авторизации</Link>

                    </div>
                </div>

            </div>

        </>
    );
};

export default RestoreStep4;