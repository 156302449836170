import React from 'react';
import HomeContent from "../../components/Pages/Home/HomeContent";

const HomePage = () => {
    return (
        <div>
            <HomeContent />
        </div>
    );
};

export default HomePage;