import HomePage from "../pages/Home/HomePage";
import ScriptsPage from "../pages/Scripts/ScriptsPage";
import ScriptsEditPage from "../pages/ScriptsEdit/ScriptsEditPage";
import ScriptsViewPage from "../pages/ScriptsView/ScriptsViewPage";
import ScriptsBuyPage from "../pages/ScriptsBuy/ScriptsBuyPage";
import TariffsPage from "../pages/Tariffs/TariffsPage";
import BonusesPage from "../pages/Bonuses/BonusesPage";
import ManagementPage from "../pages/Management/ManagementPage";
import ManagementAnalyticsPage from "../pages/Management/Analytics/ManagementAnalyticsPage";
import ManagementUsersPage from "../pages/Management/Users/ManagementUsersPage";
import ManagementTariffsPage from "../pages/Management/Tariffs/ManagementTariffsPage";
import ManagementBannersPage from "../pages/Management/Banners/ManagementBannersPage";
import ManagementBonusesPage from "../pages/Management/Bonuses/ManagementBonusesPage";
import ManagementTemplatesPage from "../pages/Management/Templates/ManagementTemplatesPage";
import ManagementPromoPage from "../pages/Management/Promo/ManagementPromoPage";
import ManagementPromoStandardPage from "../pages/Management/Promo/Standard/ManagementPromoStandardPage";
import ManagementPromoUniversalPage from "../pages/Management/Promo/Universal/ManagementPromoUniversalPage";
import ManagementSettingsPage from "../pages/Management/Settings/ManagementSettingsPage";
import ProfilePage from "../pages/Profile/ProfilePage";
import AccessDeniedPage from "../pages/AccessDeniedPage";
import NotFoundPage from "../pages/NotFoundPage";
import LoginPage from "../pages/Login/LoginPage";
import LogoutPage from "../pages/Logout/LogoutPage";
import RegisterPage from "../pages/Register/RegisterPage";
import RestorePage from "../pages/Restore/RestorePage";
import ULoginPage from "../pages/ULogin/ULoginPage";


export const privateRoutes = [

    //Главная
    {path: '', element: <HomePage />, title: 'Главная'},

    //Скрипты
    {path: 'scripts', element: <ScriptsPage />, title: 'Скрипты'},
    {path: 'scripts/:id/buy', element: <ScriptsBuyPage />, title: 'Скрипты / Покупка'},

    //Тарифы и оплата
    {path: 'tariffs', element: <TariffsPage />, title: 'Тарифы и оплата'},

    //Бонусная программа
    {path: 'bonuses', element: <BonusesPage />, title: 'Бонусная программа'},

    //Управление
    {path: 'management', element: <ManagementPage />, title: 'Управление'},
    {path: 'management/analytics', element: <ManagementAnalyticsPage />, title: 'Управление / Аналитика'},
    {path: 'management/users', element: <ManagementUsersPage />, title: 'Управление / Пользователи'},
    {path: 'management/tariffs', element: <ManagementTariffsPage />, title: 'Управление / Тарификация'},
    {path: 'management/banners', element: <ManagementBannersPage />, title: 'Управление / Настройка баннеров'},
    {path: 'management/bonuses', element: <ManagementBonusesPage />, title: 'Управление / Бонусная программа'},
    {path: 'management/templates', element: <ManagementTemplatesPage />, title: 'Управление / Шаблоны'},
    {path: 'management/promo', element: <ManagementPromoPage />, title: 'Управление / Промокоды'},
    {path: 'management/promo/standard', element: <ManagementPromoStandardPage />, title: 'Управление / Промокоды / Стандартные промокоды'},
    {path: 'management/promo/universal', element: <ManagementPromoUniversalPage />, title: 'Управление / Промокоды / Универсальные промокоды'},
    {path: 'management/settings', element: <ManagementSettingsPage />, title: 'Управление / Общие настройки'},


    //Мой профиль
    {path: 'profile', element: <ProfilePage />, title: 'Личный кабинет'},

    //Доступ запрещен
    {path: 'accessdenied', element: <AccessDeniedPage />, title: 'Доступ запрещен'},

    //Страница не найдена
    {path: '*', element: <NotFoundPage />, title: 'Страница не найдена'},
]


export const privateRoutesWL = [
    {path: 'scripts/:id/edit', element: <ScriptsEditPage />, title: 'Скрипты / Редактор'},
    {path: 'scripts/:id/view', element: <ScriptsViewPage />, title: 'Скрипты / Просмотр'},
]


export const publicRoutes = [

    //Авторизация
    {path: 'login', element: <LoginPage />, title: 'Авторизация'},

    //Выход
    {path: 'logout', element: <LogoutPage />, title: 'Выход'},

    //Регистрация
    {path: 'register', element: <RegisterPage />, title: 'Регистрация'},

    //Восстановление
    {path: 'restore', element: <RestorePage />, title: 'Восстановление'},

    //Авторизация через соц. сети
    {path: 'ulogin', element: <ULoginPage />, title: 'Авторизация'},
]