export const SubText = (text, length) => {

    if( (text === undefined) || (text === null) || (text === '') )
    {
        return text;
    }

    //Текст без тегов
    let ClearText = text.replace(/<(.|\n)*?>/g, '');

    //
    ClearText = ClearText.replace('&ldquo;', '«');
    ClearText = ClearText.replace('&rdquo;', '»');

    //Обрезанный текст
    const CropText =  ClearText.substring(0, length);;

    //Добавить многоточие
    let ResText = CropText;

    if(CropText.length < ClearText.length)
    {
        ResText = ResText + ' ...';
    }

    return ResText;
}