import React from 'react';
import SubmenuArrow from "../../../assets/img/submenu_arrow.svg";


const SubMenu = ({children, active, setActive}) => {

    //
    const rootClasses = ['submenu_out'];

    if (active) {
        rootClasses.push('active');
    }

    //
    return (
        <div className={rootClasses.join(' ')} onClick={() => setActive(false)}>

            <div className="submenu">
                <div className="submenu_close"></div>
                <div className="submenu_arrow">
                    <img src={SubmenuArrow} />
                </div>
                <div className="submenu_body">
                    <div className="submenu_menu">
                        {children}
                    </div>
                </div>
            </div>

        </div>
    );
};

export default SubMenu;