import React from 'react';
import ScriptsEditWorkAreaReplicAnswersList from "./ScriptsEditWorkAreaReplicAnswersList";

const ScriptsEditWorkAreaReplicContent = ({item, setReplicId}) => {


    //Выбор реплики
    const selectReplic = (e, id) => {
        e.preventDefault();
        setReplicId(id)
    }


    //Передача функций
    const func = {
        select: selectReplic
    }

    //Вывод компонента
    return (
        <div className="p_editor_center_item">

            <div className="p_editor_center_item_title">
                {item.name}
            </div>


            <div className="p_editor_center_item_desc"
                 dangerouslySetInnerHTML={{__html: item.desc}}>
            </div>


            <div className="p_editor_center_item_answers">

                <div className="p_editor_center_item_answers_list">

                    <ScriptsEditWorkAreaReplicAnswersList
                        items={item.childs_replics}
                        func={func}
                    />

                </div>

            </div>


        </div>
    );
};

export default ScriptsEditWorkAreaReplicContent;