import React, {useContext} from 'react';
import {AuthContext} from "../../context/AuthContext";
import {observer} from "mobx-react-lite"
import Avatar from "../../assets/images/users/1.jpg";
import ImgUpanelUser from "../../assets/img/p_account_upanel_user.svg";

const UpanelUser = () => {

    //
    const {AppAuthStore} = useContext(AuthContext);

    //Вывод компонента
    return (
        <>

            <div id="p_account_upanel_user_photo">
                <img src={Avatar} />
            </div>
            <div id="p_account_upanel_user_name">
                <span>{AppAuthStore.user.first_name}</span>
            </div>
            <div id="p_account_upanel_user_btn">
                <img src={ImgUpanelUser} />
            </div>

        </>
    );
};

export default observer(UpanelUser);