import React from 'react';
import LinkImg from "../../../../../assets/img/Link.svg";

const ScriptsEditLeftFormLinksItem = ({item, func, status}) => {

    //Вывод компонента
    return (
        <div
            onClick={
                e => func.changeAttach(item.id)
            }
            className={
                'p_editor_left_item_answers_item ' + ( (status[item.id] !== undefined) && (status[item.id] === 1)  ? '' : 'disabled' )
            }
        >
            <img src={LinkImg} alt=""/>
            {item.name}
        </div>
    );
};

export default ScriptsEditLeftFormLinksItem;