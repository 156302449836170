import React from 'react';
import ModalWindow from "../../Interface/ModalWindow/ModalWindow";

const ScriptsShopHideForm = ({modal, setModal, tekItemId, setUpdTrigger}) => {


    //Скрытие шаблона
    const handleSubmit = async () => {

        //
        localStorage.setItem('hide_template_' + tekItemId, '1');
        setUpdTrigger( Date.now() );

        //
        setModal(false);
    }


    //Отмена
    const cancelSubmit = () => {
        setModal(false);
    }


    //Вывод компонента
    return (
        <ModalWindow title="Вы действительно хотите скрыть шаблон?" active={modal} setActive={setModal} type="forbidden">

            <div className="modal_action_text">
                После скрытия данного шаблона вернуть его обратно можно будет только обратившись в<br />
                поддержку ScriptOn
            </div>

            <div className="modal_action_btns">
                <div className="btn" onClick={ () => handleSubmit() }>Не показывать шаблон</div>
                <div className="btn btn_c1" onClick={ () => cancelSubmit() }>Оставить шаблон</div>
            </div>

        </ModalWindow>
    );

};

export default ScriptsShopHideForm;