export const NotificationsAdd = (items, setItems, text, type = 'ok') => {
    setItems(
        items.concat([
            {
                id: Date.now(),
                time: Date.now(),
                text: text,
                type: type
            }
        ])
    );
}


export const NotificationsArrAdd = (items, setItems, arrText, type = 'ok') => {

    //Добавляемый массив
    let addArr = [];

    //Формирование массива
    arrText.forEach(function(itemText, itemIndex) {

        addArr = addArr.concat([
            {
                id: Date.now() + '_' + itemIndex,
                time: Date.now(),
                text: itemText,
                type: type
            }
        ]);

    });

    //Добавление массива
    setItems(
        items.concat(addArr)
    );
}
