import React from 'react';
import ScriptsEditContent from "../../components/Pages/ScriptsEdit/ScriptsEditContent";

const ScriptsEditPage = () => {
    return (
        <>
            <ScriptsEditContent/>
        </>
    );
};

export default ScriptsEditPage;