import React from 'react';
import BonusesOperationsItem from "./BonusesOperationsItem";

const BonusesOperationsList = ({items, isLoading, func, references}) => {

    //
    if (!items.length && !isLoading) {
        return (
            <div>Записи не найдены!</div>
        )
    }


    //Вывод компонента
    return (
        <div id="p_account_bonuses_table">

            <div className="table_wrap">


                <table cellPadding="0" cellSpacing="0" border="0">
                    <thead>
                    <tr>
                        <th>Тип операции</th>
                        <th>Примечание</th>
                        <th>Пользователь</th>
                        <th>Сумма</th>
                        <th>Дата операции</th>
                        <th>Статус</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        items.map(
                            (item, index) =>
                                <BonusesOperationsItem
                                    key={item.id}
                                    item={item}
                                    func={func}
                                    references={references}
                                />
                        )
                    }
                    </tbody>
                </table>


            </div>

        </div>
    );
};

export default BonusesOperationsList;