import React from 'react';
import EditorSkeleton from "../../../assets/img/p_editor_skeleton.svg";

const ScriptsViewWorkAreaReplicLoad = () => {

    //Вывод компонента
    return (
        <div className="p_editor_center_item p_editor_center_item_skeleton">

            <div className="p_editor_center_item_skeleton_title">
            </div>

            <div className="p_editor_center_item_skeleton_img">
                <img src={EditorSkeleton} alt=""/>
            </div>

        </div>
    );
};

export default ScriptsViewWorkAreaReplicLoad;