import React from 'react';
import ManagementSettingsContent from "../../../components/Pages/Management/Settings/ManagementSettingsContent";
import {managementMenu} from "../../../menu/ManagementMenu";
import ManagementMenu from "../../../components/Workarea/ManagementMenu/ManagementMenu";

const ManagementSettingsPage = () => {
    return (
        <div>
            <ManagementMenu menu={managementMenu}/>
            <div id="p_account_workarea_title">Общие настройки</div>
            <ManagementSettingsContent />
        </div>
    );
};

export default ManagementSettingsPage;