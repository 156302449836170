export const stepPinCode = (event) => {

    let goIndex = 0;

    if (event.target.value !== '')
    {
        if(event.target.tabIndex < 4)
        {
            goIndex = event.target.tabIndex + 1;
        }
    }
    else
    {
        if(event.target.tabIndex > 1)
        {
            goIndex = event.target.tabIndex - 1;
        }
    }

    if(goIndex !== 0)
    {
        document.querySelector('[tabindex="'+goIndex+'"]').focus();
    }

}