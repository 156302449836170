import React from 'react';
import {useParams} from "react-router-dom";

const ScriptsBuyContent = () => {

    //
    const {id} = useParams();


    //Вывод компонента
    return (
        <div>
            BUY: ID{id}
        </div>
    );
};

export default ScriptsBuyContent;