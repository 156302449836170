import React from 'react';
import {dateFormat} from "../../../../utils/DateFormat";
import {searchInArray} from "../../../../utils/SearchInArray";
import {checkboxChecked, isChecked} from "../../../../utils/CheckboxFun";
import ToolsEdit from "../../../../assets/img/tools_edit.svg";
import ToolsDelete from "../../../../assets/img/tools_delete.svg";

const ManagementUsersItem = ({item, func, references, selected, setSelected}) => {

    //Вывод компонента
    return (
        <tr>
            <td>
                <input
                    type="checkbox"
                    name="users"
                    className="select_user"
                    checked={ isChecked(selected, item.id) }
                    onChange={ e => setSelected(checkboxChecked(e, selected, item.id)) }
                />
            </td>
            <td>{item.last_name} {item.first_name}</td>
            <td>
                <span className={'p_manager_users_table_status p_manager_users_table_status' + item.status}>
                    {item.email}
                </span>
            </td>
            <td>{item.phone}</td>
            <td>{ dateFormat(item.created_at, 'd.m.Y в H:i') }</td>
            <td>{ dateFormat(item.last_auth, 'd.m.Y в H:i') }</td>
            <td>
                <span className={'p_manager_users_table_tarif p_manager_users_table_tarif' + item.tarif_id}>
                    { searchInArray(references.tarifsList, item.tarif_id, 0, 1) }
                </span>
                {
                    item.date_end !== null
                        ?
                        <> до { dateFormat(item.date_end, 'd.m.Y') }</>
                        :
                        <></>
                }
            </td>
            <td>

                <div className="p_manager_table_action_out">

                    <span
                        className="p_manager_table_action"
                        onClick={ e => func.edit(e, item.id) }
                    >
                        <img src={ToolsEdit} alt=''/>
                    </span>

                    <span
                        className="p_manager_table_action"
                        onClick={ e => func.del(e, item.id) }
                    >
                        <img src={ToolsDelete} alt=''/>
                    </span>

                </div>



            </td>
        </tr>
    );
};

export default ManagementUsersItem;