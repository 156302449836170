import React from 'react';
import ManagementUsersItem from "./ManagementUsersItem";
import TableSort from "../../../Interface/Table/TableSort";

const ManagementUsersList = ({items, isLoading, func, references, sort, direction, selected, setSelected}) => {

    //
    if (!items.length && !isLoading) {
        return (
            <div>Записи не найдены!</div>
        )
    }

    //Вывод компонента
    return (
        <div id="p_manager_users_table">

            <div className="table_wrap table_wrap_v2 table_wrap_with_chb">

                <table cellPadding="0" cellSpacing="0" border="0">
                    <thead>
                    <tr className="nowrap">
                        <th>
                            <input type="checkbox" />
                        </th>
                        <th>
                            Фамилия, Имя
                            <TableSort field="last_name" sort={sort} direction={direction} setSort={func.setOrderBy}/>
                        </th>
                        <th>
                            Почта
                            <TableSort field="email" sort={sort} direction={direction} setSort={func.setOrderBy}/>
                        </th>
                        <th>
                            Телефон
                            <TableSort field="phone" sort={sort} direction={direction} setSort={func.setOrderBy}/>
                        </th>
                        <th>
                            Регистрация
                            <TableSort field="created_at" sort={sort} direction={direction} setSort={func.setOrderBy}/>
                        </th>
                        <th>
                            Последний вход
                            <TableSort field="last_auth" sort={sort} direction={direction} setSort={func.setOrderBy}/>
                        </th>
                        <th>
                            Тариф
                            <TableSort field="tarif_id" sort={sort} direction={direction} setSort={func.setOrderBy}/>
                        </th>
                        <th>
                            Действия
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        items.map(
                            (item, index) =>
                                <ManagementUsersItem
                                    key={item.id}
                                    item={item}
                                    func={func}
                                    references={references}
                                    selected={selected}
                                    setSelected={setSelected}
                                />
                        )
                    }
                    </tbody>
                </table>

            </div>

        </div>
    );
};

export default ManagementUsersList;