import React, {useContext} from 'react';
import {AuthContext} from "../../../context/AuthContext";
import {Navigate} from "react-router-dom";
import {observer} from "mobx-react-lite"
import Preloader from "../../Interface/Preloader";

const LogoutContent = () => {

    //
    const {AppAuthStore} = useContext(AuthContext);

    //
    AppAuthStore.logout();

    //
    return (

        AppAuthStore.isAuth
            ?
            <Preloader />
            :
            <Navigate to="/login" replace />

    );

};

export default observer(LogoutContent);