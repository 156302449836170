import React from 'react';
import LogoutContent from "../../components/Pages/Logout/LogoutContent";

const LogoutPage = () => {

    //
    return (
        <LogoutContent />
    );
};

export default LogoutPage;