import React from 'react';
import ImgItemAdd from "../../../assets/img/p_account_scripts_item_add.svg";

const ScriptsAddBtn = ({funcAdd}) => {
    return (
        <div className="p_account_scripts_item_out">
            <div
                className="p_account_scripts_item p_account_scripts_item_add"
                onClick={ () => funcAdd() }
            >

                <div className="p_account_scripts_item_add_img">
                    <img src={ImgItemAdd} alt="" />
                </div>

                <div className="p_account_scripts_item_add_title">
                    <span>Создать новый скрипт продаж</span>
                </div>

            </div>
        </div>
    );
};

export default ScriptsAddBtn;