import React from 'react';
import ImgNums from "../../../assets/img/p_account_scripts_item_svodka_item_nums.svg";
import ImgHelp from "../../../assets/img/p_account_scripts_item_svodka_item_help.svg";
import {Link} from "react-router-dom";
import {ItemsCountTitle} from "../../../utils/ItemsCountTitle";

const ScriptsShopItem = ({item, func, references}) => {
    return (
        <div className="p_account_scripts_item_out">
            <div className="p_account_scripts_item p_account_scripts_item_shop">

                <div className="p_account_scripts_item_title">{item.name}</div>
                <div className="p_account_scripts_item_vendor">{item.desc}</div>

                <div className="p_account_scripts_item_svodka">
                    <div className="p_account_scripts_item_svodka_item">
                        <img src={ImgNums} alt="" />
                        <span>
                            {
                                item.project !== null
                                    ?
                                    <>
                                        {item.project.replics_num} {ItemsCountTitle(item.replics_num, ['карточек', 'карточка', 'карточки', 'карточек'])}
                                    </>
                                    :
                                    <>0 карточек</>
                            }
                        </span>
                    </div>
                    <div className="p_account_scripts_item_svodka_item">
                        <img src={ImgHelp} alt="" />
                        <span>Поддержка 24/7</span>
                    </div>
                </div>


                <div className="p_account_scripts_item_btns">

                    <div className="p_account_scripts_item_btns_left">
                        {
                            item.no_price === 1
                                ?
                                <>
                                    <Link to={"/scripts/" + item.id + "/buy"} className="btn btn1">
                                        Получить бесплатно
                                    </Link>
                                </>
                                :
                                <>
                                    <Link to={"/scripts/" + item.id + "/buy"} className="btn btn1">
                                        Купить за {item.price} рублей
                                    </Link>
                                </>
                        }

                    </div>

                    <div className="p_account_scripts_item_btns_right">

                        <div
                            className="btn btn1 btn_c1"
                            onClick={ () => func.hide(item.id) }
                        >
                            Не показывать
                        </div>

                    </div>

                </div>

            </div>
        </div>
    );
};

export default ScriptsShopItem;