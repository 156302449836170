import $api from "../http";

export default class ProfileService {

    static async getById(id) {
        return await $api.get('/profile/' + id + '/read');
    }

    static async updateById(id, data) {
        return await $api.post('/profile/' + id + '/update', data);
    }

    static async passwordChangeById(id, data) {
        return await $api.post('/profile/' + id + '/password-change', data);
    }

}