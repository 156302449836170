import React from 'react';
import ScriptsViewWorkAreaAnswersItem from "./ScriptsViewWorkAreaAnswersItem";

const ScriptsViewWorkAreaAnswersList = ({items, func}) => {

    //Вывод компонента
    return (
        <>
            {
                items.map(
                    (item, index) =>
                        <ScriptsViewWorkAreaAnswersItem
                            key={item.id}
                            item={item}
                            func={func}
                        />
                )
            }
        </>
    );
};

export default ScriptsViewWorkAreaAnswersList;