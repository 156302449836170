import React from 'react';
import {Outlet} from "react-router-dom";

const UnauthorizedWorkArea = () => {
    return (
        <div id="p_login_workarea">
            <Outlet/>
        </div>
    );
};

export default UnauthorizedWorkArea;