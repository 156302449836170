import React from 'react';

const ScriptsEditFooter = ({id, replicId}) => {
    return (
        <div id="p_editor_footer">

        </div>
    );
};

export default ScriptsEditFooter;