import React from 'react';
import ScriptsBuyContent from "../../components/Pages/ScriptsBuy/ScriptsBuyContent";

const ScriptsBuyPage = () => {
    return (
        <div>
            <div id="p_account_workarea_title">Скрипты продаж - Покупка</div>
            <ScriptsBuyContent />
        </div>
    );
};

export default ScriptsBuyPage;