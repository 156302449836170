import React, {useState, useEffect} from 'react';
import UPromosService from "../../../../../services/UPromosService";
import {useFetching} from "../../../../../hooks/useFetching";
import {getPageCount} from "../../../../../utils/Pages";
import ManagementPromoUniversalAdd from "./ManagementPromoUniversalAdd";
import ManagementPromoUniversalList from "./ManagementPromoUniversalList";
import PaginationLine from "../../../../Interface/Pagination/PaginationLine";
import Preloader from "../../../../Interface/Preloader";
import ManagementPromoUniversalDel from "./ManagementPromoUniversalDel";

const ManagementPromoUniversalContent = () => {

    //Параметры выборки
    const [items, setItems] = useState([])
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);

    const [totalElems, setTotalElems] = useState(0);
    const [totalPages, setTotalPages] = useState(0);

    //Параметры редактора
    const [tekItemId, setTekItemId] = useState(0);
    const [updTrigger, setUpdTrigger] = useState(0);

    //Сортировка
    const [sort, setSort] = useState('id');
    const [direction, setDirection] = useState('DESC');

    //Модальные окна
    const [modalDel, setModalDel] = useState(false);

    //Показывать по
    const recordsCountArr = [
        {value: 10, name: 10},
        {value: 15, name: 15},
        {value: 20, name: 20},
        {value: 25, name: 25},
    ]


    //Передача доп. списков
    const references = {

    };


    //Удаление записи
    const delItemInit = (id) => {
        setTekItemId(id);
        setModalDel(true);
    }

    //Смена сортировки
    const setOrderBy = (field, direct) => {
        setSort(field);
        setDirection(direct);
    }

    //Передача функций
    const func = {
        del: delItemInit,
        setOrderBy: setOrderBy,
    }


    //Определение выборки списка
    const [fetchItems, isItemsLoading, itemsError] = useFetching(
        async (page, limit) => {
            const response = await UPromosService.getAll(page, limit, sort, direction);
            setItems(response.data.data.items);
            const totalCount = response.data.data.total;
            setTotalElems(totalCount);
            setTotalPages(getPageCount(totalCount, limit));
        }
    );


    //Инициализация выборки списка
    useEffect(() => {
        fetchItems(page, limit);
    }, [
        page,
        limit,
        sort,
        direction,
        updTrigger,
    ]);


    //Смена страницы
    const changePage = (page) => {
        setPage(page)
    }


    //Смена числа записей
    const changeLimit = (show) => {
        setLimit(show)
    }


    //Переход на первую страницу
    useEffect(() => {
        setPage(1);
    }, [
        limit,
        limit
    ]);


    //Вывод компонента
    return (
        <>

            {
                itemsError
                    ?
                    <div>Произошла ошибка ${itemsError}</div>
                    :
                    <></>
            }

            <ManagementPromoUniversalAdd
                setUpdTrigger={setUpdTrigger}
            />


            <ManagementPromoUniversalList
                items={items}
                isLoading={isItemsLoading}
                func={func}
                references={references}
                sort={sort}
                direction={direction}
            />


            <PaginationLine
                recCountVariants={recordsCountArr}
                recCountValue={limit}
                recCountFunc={changeLimit}
                page={page}
                changePage={changePage}
                totalElems={totalElems}
                totalPages={totalPages}
                itemsLimit={limit}
                itemsCount={items.length}
            />


            {
                isItemsLoading
                    ?
                    <Preloader />
                    :
                    <></>
            }


            <ManagementPromoUniversalDel
                modal={modalDel}
                setModal={setModalDel}
                tekItemId={tekItemId}
                setUpdTrigger={setUpdTrigger}
            />

        </>
    );
};

export default ManagementPromoUniversalContent;