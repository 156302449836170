import React from 'react';

const UnauthorizedFooter = () => {
    return (
        <div id="p_login_footer">

        </div>
    );
};

export default UnauthorizedFooter;