import React, {useContext, useEffect, useState} from 'react';
import UsersService from "../../../services/UsersService";
import BonusesUsersList from "./BonusesUsersList";
import {AuthContext} from "../../../context/AuthContext";
import {useFetching} from "../../../hooks/useFetching";

const BonusesUsers = () => {

    //Получение пользователя
    const {AppAuthStore} = useContext(AuthContext);


    //Параметры выборки
    const [items, setItems] = useState([])


    //Фильтры
    const filters = {
        ref_user_id: {v:AppAuthStore.user.id, t:'='},
    };


    //Определение выборки списка
    const [fetchItems, isItemsLoading, itemsError] = useFetching(
        async () => {
            const response = await UsersService.getAll(1, 100, 'id', 'desc', filters);
            setItems(response.data.data.items);
        }
    );


    //Инициализация выборки списка
    useEffect(() => {
        fetchItems();
    }, []);


    //Вывод компонента
    return (
        <>

            {
                itemsError
                    ?
                    <div>Произошла ошибка ${itemsError}</div>
                    :
                    <></>
            }


            <BonusesUsersList
                items={items}
                isLoading={isItemsLoading}
            />


        </>
    );
};

export default BonusesUsers;