import React from 'react';
import {userMenu} from "../../menu/UserMenu";
import UserMenuItem from "./UserMenuItem";

const UserMenu = () => {

    //Вывод компонента
    return (
        <ul>
            {
                userMenu.map(
                    (route, index) =>
                        <UserMenuItem
                            to={route.path}
                            icon={route.icon}
                            title={route.title}
                            key={index}
                        />
                )
            }
        </ul>
    );
};

export default UserMenu;