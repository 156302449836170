import React from 'react';
import ScriptsViewWorkAreaReplic from "./ScriptsViewWorkAreaReplic";
import ScriptsViewWorkAreaReplicLoad from "./ScriptsViewWorkAreaReplicLoad";

const ScriptsViewWorkArea = ({id, replicId, setReplicId, firstReplicId, notifications, setNotifications}) => {

    //Вывод компонента
    return (
        <div id="p_view_center">

            <div className="p_view_center_item_out horizontal_scroll">

                {
                    replicId === 0
                        ?
                        <ScriptsViewWorkAreaReplicLoad />
                        :
                        <ScriptsViewWorkAreaReplic
                            id={id}
                            replicId={replicId}
                            setReplicId={setReplicId}
                            firstReplicId={firstReplicId}
                            notifications={notifications}
                            setNotifications={setNotifications}
                        />
                }
            </div>

        </div>
    );
};

export default ScriptsViewWorkArea;