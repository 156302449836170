import React, {useState, useEffect} from 'react';
import SettingsService from "../../../../services/SettingsService";
import {useFetching} from "../../../../hooks/useFetching";
import {scrollToTop} from "../../../../utils/ScrollToTop";
import {Errors422} from "../../../../utils/Errors422";
import FormSuccess from "../../../Interface/FormMessage/FormSuccess";
import FormErrors from "../../../Interface/FormMessage/FormErrors";
import {InputChange} from "../../../../utils/InputChange";


const ManagementSettingsForm = () => {

    //Получение настроек
    const [settings, setSettings] = useState({
        email_for_notifications: '',
        time_deactivate: '',
        scripts_rubric_max: '',
    });

    const [fetchSettings, isLoading, error] = useFetching(
        async () => {
            const response = await SettingsService.getSettings('email_for_notifications,time_deactivate,scripts_rubric_max');
            setSettings(response.data.data);
        }
    );

    useEffect(() => {
        fetchSettings();
    }, []);


    //Сохранение настроек
    const [success, setSuccess] = useState(false);
    const [errors, setErrors] = useState([]);

    const handleSubmit = async () => {

        //
        scrollToTop();

        //
        let errArr = [];

        //
        if(
            (settings.email_for_notifications === '')
            ||
            (settings.time_deactivate === '')
            ||
            (settings.scripts_rubric_max === '')
        )
        {
            errArr.push('Заполнены не все поля');
        }

        //
        if(errArr.length === 0) {

            //
            try {
                const response = await SettingsService.setSettings(settings);

                if(response.data.status === 'ok')
                {
                    setSuccess(true);
                    setTimeout(() => {  setSuccess(false) }, 2500);
                }
                else
                {
                    errArr.push('Ошибка сохранения настроек.');
                }
            }
            catch (e) {
                Errors422(e, errArr, 'Ошибка сохранения настроек.');
            }

        }

        //
        setErrors(errArr);

    }


    //Вывод компонента
    return (
        <div>

            <FormSuccess ok={success} text="Настройки успешно сохранены." fading="true"/>

            <FormErrors errors={errors}/>


            <div id="p_manager_settings">

                <div className="field_tr">

                    <div className="field_td field_td2">
                        <div className="label">Почта для уведомлений</div>

                        <input
                            type="text"
                            name="email_for_notifications"
                            className="field"
                            value={settings.email_for_notifications}
                            onChange={e => setSettings(InputChange(e, settings))}
                        />

                    </div>

                    <div className="field_td field_td2">
                        <div className="label">Время неактивновсти (мин)</div>

                        <input
                            type="text"
                            name="time_deactivate"
                            className="field"
                            value={settings.time_deactivate}
                            onChange={e => setSettings(InputChange(e, settings))}
                        />

                    </div>

                    <div className="field_td field_td2">
                        <div className="label">Максимальное кол-во рубрик</div>

                        <input
                            type="text"
                            name="scripts_rubric_max"
                            className="field"
                            value={settings.scripts_rubric_max}
                            onChange={e => setSettings(InputChange(e, settings))}
                        />

                    </div>

                </div>


                <div className="field_tr btn_tr">
                    <div className="field_td field_td1 center">

                        <input
                            type="submit" className="btn"
                            value="Сохранить настройки"
                            onClick={() => handleSubmit()}
                        />

                    </div>
                </div>


            </div>


        </div>
    );
};

export default ManagementSettingsForm;