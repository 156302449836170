import React, {useEffect, useState} from 'react';
import ModalWindow from "../../../Interface/ModalWindow/ModalWindow";
import ScriptsService from "../../../../services/ScriptsService";
import ScriptsEditLeftFormAnswersList from "./FormsComponents/ScriptsEditLeftFormAnswersList";
import FormInputClear from "../../../Interface/Forms/FormInputClear";
import {NotificationsAdd, NotificationsArrAdd} from "../../../../utils/NotificationsAdd";
import ReplicsService from "../../../../services/ReplicsService";
import {Errors422} from "../../../../utils/Errors422";
import ScriptsEditLeftFormAnswersAdd from "./FormsComponents/ScriptsEditLeftFormAnswersAdd";


const ScriptsEditLeftAddMultiForm = ({modal, setModal, userId, projectId, setReplicId, setUpdTrigger, notifications, setNotifications, firstReplicId}) => {

    //Данные
    const nullReplicItem = {
        user_id: userId,
        project_id: projectId,
        names: '',
    };

    const nullProjectItem = {
        replics_num: '',
        replics: [],
    };


    //
    const [replicItem, setReplicItem] = useState(nullReplicItem);

    //
    const [projectItem, setProjectItem] = useState(nullProjectItem);


    //Получение данных
    useEffect(() => {

        const getItem = async (id) => {

            try {
                //
                const response = await ScriptsService.getById(id, 'replics');

                //
                if(response.data.status === 'ok')
                {
                    setProjectItem(response.data.data);
                }
            }

            catch (e) {
                //
            }
        }

        if(modal)
        {
            getItem(projectId);
        }

    }, [modal, modal]);


    //Сохранение данных
    const handleSubmit = async () => {

        //
        let errBeforeArr = [];
        let errAfterArr = [];

        //
        if(replicItem.names === '')
        {
            errBeforeArr.push('Поле «Добавить новые ответы» не заполнено');
        }

        if(errBeforeArr.length > 0)
        {
            NotificationsArrAdd(notifications, setNotifications, errBeforeArr, 'not1');
        }
        else
        {
            try {
                //
                let response = await ReplicsService.createMass(replicItem);

                //
                if(response.data.status === 'ok')
                {
                    NotificationsAdd(notifications, setNotifications, 'Новые карточки ответа были успешно созданы .', 'ok');

                    //
                    setReplicId(response.data.data.last_id);

                    //
                    setTimeout(
                        () => {
                            const listElem = document.getElementById('p_editor_left_list_out');
                            listElem.scrollTop = listElem.scrollHeight;
                        },
                        500
                    );

                    //
                    setModal(false);

                    //
                    setReplicItem(nullReplicItem);
                }
                else
                {
                    errAfterArr.push('Ошибка создания карточек ответа.');
                }
            }
            catch (e) {
                Errors422(e, errAfterArr, 'Ошибка создания карточек ответа.');
            }

            if(errAfterArr.length > 0)
            {
                NotificationsArrAdd(notifications, setNotifications, errAfterArr, 'not2');
            }

        }

    }


    //Отмена
    const cancelSubmit = () => {
        setModal(false);
    }


    //Передача функций
    const childs_replics_func = {
        changeAttach: () => {},
    }


    //Вывод компонента
    return (
        <ModalWindow
            title="Добавить новые ответы"
            active={modal}
            setActive={setModal}
            footer={
                {
                    leftBtn: {
                        title: 'Отменить',
                        func: cancelSubmit
                    },
                    rightBtn: {
                        title: 'Добавить ответы',
                        func: handleSubmit
                    }
                }
            }
        >


            <div className="p_editor_modal_form" id="p_editor_modal_form_edit">


                <div className="field_tr">
                    <div className="field_td field_td1">
                        <div className="label">Добавить новые ответы</div>
                        <div className="sub_label">
                            Вводите новые варианты ответа для добавления их в скрипт продаж, разделяя их запятой
                        </div>

                        <ScriptsEditLeftFormAnswersAdd
                            name="names"
                            value={replicItem.names}
                            setValue={setReplicItem}
                            item={replicItem}
                            items={projectItem.replics}
                            placeholder="Введите список новых ответов через запятую"
                        />

                    </div>
                </div>


                <div className="field_tr">
                    <div className="field_td field_td1">

                        <div className="label">
                            Имеющиеся ответы в скрипте продаж
                            <span className="label_count">({projectItem.replics_num})</span>
                        </div>

                        <ScriptsEditLeftFormAnswersList
                            items={projectItem.replics}
                            func={childs_replics_func}
                        />

                    </div>
                </div>


            </div>


        </ModalWindow>
    );
};

export default ScriptsEditLeftAddMultiForm;