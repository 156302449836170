import React, {useState} from 'react';
import {InputChange} from "../../../../utils/InputChange";
import FormSelect from "../../../Interface/Forms/FormSelect";
import TemplatesService from "../../../../services/TemplatesService";


const ManagementTemplatesItem = ({item, func, references, setUpdTrigger}) => {


    //Данные сохранения
    const nullItem = {
        name: '',
        desc: '',
        project_id: '',
        price: '',
        no_price: '',
    };


    //Текущий элемент
    const [tekItem, setTekItem] = useState(item);


    //Переключение "Бесплатно"
    const switchPrice = (noPriceFlag) => {

        //Объект шаблона
        let obj = tekItem;

        //Переключаем флаг
        const newFlag = 1 - noPriceFlag;
        obj = {...obj, ...{['no_price']: newFlag}};

        //Если бесплатно
        if(newFlag === 1)
        {
            obj = {...obj, ...{['price']: ''}};
        }

        //Обновить шаблон
        setTekItem(obj);
    }


    //Сохранение данных
    const handleSubmit = async () => {

        //Сохранение
        try {
            //
            let response = await TemplatesService.updateById(tekItem.id, tekItem);

            if(response.data.status === 'ok')
            {
                setUpdTrigger( Date.now() );
            }
            else
            {
                //
            }
        }
        catch (e) {
            //
        }

    }



    //Вывод компонента
    return (
        <div className="p_manager_templates_item_out">
            <div className="p_manager_templates_item">
                <div className="p_manager_templates_item_in">

                    <div className="field_tr">
                        <div className="field_td field_td1">
                            <div className="label">Название шаблона</div>
                            <input
                                type="text"
                                name="name"
                                className="field"
                                value={tekItem.name}
                                onChange={ e => setTekItem(InputChange(e, tekItem)) }
                            />
                        </div>
                    </div>

                    <div className="field_tr">
                        <div className="field_td field_td1">
                            <div className="label">Описание шаблона</div>
                            <textarea
                                name="desc"
                                className="field"
                                value={tekItem.desc}
                                onChange={ e => setTekItem(InputChange(e, tekItem)) }
                            ></textarea>
                        </div>
                    </div>

                    <div className="field_tr">
                        <div className="field_td field_td1">
                            <div className="label">Выберите шаблон</div>
                            <FormSelect
                                name="project_id"
                                className="field"
                                options={references.scriptsList}
                                defaultValue="Выберите значение"
                                value={tekItem.project_id}
                                onChange={ e => setTekItem(InputChange(e, tekItem)) }
                                valueKey="id"
                                nameKey="name"
                                defValue="0"
                            />
                        </div>
                    </div>

                    <div className="field_tr">
                        <div className="field_td field_td3x2">
                            <div className="label">Стоимость тарифа</div>
                            <input
                                type="text"
                                name="price"
                                className="field"
                                value={tekItem.price}
                                onChange={ e => setTekItem(InputChange(e, tekItem)) }
                                disabled={ tekItem.no_price === 1 }
                            />
                        </div>
                        <div className="field_td field_td3">
                            <div className="label">&nbsp;</div>
                            <div
                                className={ tekItem.no_price === 1 ? 'btn btn_c1' : 'btn btn_c7' }
                                onClick={ () => switchPrice(tekItem.no_price) }
                            >Бесплатно</div>
                        </div>
                    </div>

                    <div className="field_tr btn_tr">
                        <div className="field_td field_td1">
                            <div className="p_manager_templates_item_btns">

                                <input
                                    type="submit"
                                    className="btn"
                                    value="Сохранить изменения"
                                    onClick={ () => handleSubmit() }
                                />

                                <input
                                    type="submit"
                                    className="btn btn_c5"
                                    value="Удалить шаблон"
                                    onClick={ () => func.del(item.id) }
                                />

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default ManagementTemplatesItem;