import React from 'react';
import ScriptsEditLeftFormAnswersAddItem from "./ScriptsEditLeftFormAnswersAddItem";


const ScriptsEditLeftFormAnswersAddList = ({items, func}) => {

    //
    if (items.length === 0) {
        return (
            <></>
        )
    }

    //Вывод компонента
    return (
        <>
            {
                items.map(
                    (item, index) =>
                        <ScriptsEditLeftFormAnswersAddItem
                            key={index}
                            item={item}
                            func={func}
                        />
                )
            }
        </>
    );
};

export default ScriptsEditLeftFormAnswersAddList;