import React from 'react';
import ManagementTariffsContent from "../../../components/Pages/Management/Tariffs/ManagementTariffsContent";
import {managementMenu} from "../../../menu/ManagementMenu";
import ManagementMenu from "../../../components/Workarea/ManagementMenu/ManagementMenu";

const ManagementTariffsPage = () => {
    return (
        <div>
            <ManagementMenu menu={managementMenu}/>
            <div id="p_account_workarea_title">Тарификация</div>
            <ManagementTariffsContent />
        </div>
    );
};

export default ManagementTariffsPage;