export const MaskEmail = (email) => {

    //Разбиваем емейл на части
    const EmailArr = email.split('@');

    //Пользователь
    let EmailUser = EmailArr[0];

    //Длина имени пользователя
    const EmailUserLenght = EmailUser.length;

    //Начало замены
    const IndexBeg = Math.ceil(EmailUserLenght / 4);

    //Длина замены
    const IndexEnd = EmailUserLenght - IndexBeg;

    //Замена символов
    if(IndexBeg < IndexEnd)
    {
        for (let i = IndexBeg; i < IndexEnd; i++) {
            EmailUser = replaceAt(EmailUser, i, '*');
        }
    }

    //Собираем Email
    EmailArr[0] = EmailUser;
    const Output = EmailArr.join('@');


    //Вывод результата
    return Output;
}

const replaceAt = function(string, index, character) {
    return string.substr(0, index) + character + string.substr(index + character.length);
};