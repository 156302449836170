import React, {useContext}  from 'react';
import {useLocation} from "react-router-dom";
import {observer} from "mobx-react-lite"
import {Navigate} from "react-router-dom";
import {AuthContext} from "../../../context/AuthContext";
import Preloader from "../../Interface/Preloader";

const ULoginContent = () => {

    //Получить токен
    const param = useLocation();
    const token = new URLSearchParams(param.search).get("token");

    //Сохранить токен
    localStorage.setItem('token', token);


    //
    const {AppAuthStore} = useContext(AuthContext);

    //
    if(!AppAuthStore.isCheckAuth)
    {
        return '';
    }

    //Вывод компонента
    return (

        AppAuthStore.isAuth
            ?
            <Navigate to="/" replace />
            :
            <Preloader />

    );
};

export default observer(ULoginContent);