import React from 'react';
import ImgToolsRubricsAdd from "../../../assets/img/p_account_scripts_rubrics_add.svg";

const ScriptsToolsAddBtn = ({func}) => {
    return (
        <div
            className="workarea_tools_menu_item workarea_tools_menu_item_only_img"
            onClick={ () => func.add() }
        >
            <img src={ImgToolsRubricsAdd} alt="" />
        </div>
    );
};

export default ScriptsToolsAddBtn;