import React from 'react';
import ManagementTemplatesItem from "./ManagementTemplatesItem";

const ManagementTemplatesList = ({items, isLoading, references, func, setUpdTrigger}) => {
    return (
        <>
            {
                items.map(
                    (item, index) =>
                        <ManagementTemplatesItem
                            key={item.id}
                            item={item}
                            references={references}
                            func={func}
                            setUpdTrigger={setUpdTrigger}
                        />
                )
            }
        </>
    );
};

export default ManagementTemplatesList;