import React, {useEffect, useState} from 'react';
import PromosService from "../../../../../services/PromosService";
import {InputChange} from "../../../../../utils/InputChange";
import FormErrors from "../../../../Interface/FormMessage/FormErrors";
import {Errors422} from "../../../../../utils/Errors422";
import FormSuccess from "../../../../Interface/FormMessage/FormSuccess";

const ManagementPromoStandardAdd = ({setUpdTrigger}) => {


    //Данные сохранения
    const nullItem = {
        code: '',
        days: '',
    };

    const [item, setItem] = useState(nullItem);

    const [success, setSuccess] = useState(false);
    const [errors, setErrors] = useState([]);

    //Ошибки полей
    const [f1Error, setF1Error] = useState(0);
    const [f2Error, setF2Error] = useState(0);


    //Сохранение данных
    const handleSubmit = async () => {

        //Ошибки
        let errArr = [];

        //
        setF1Error(0);
        setF2Error(0);

        //
        if (item.code === '') {
            errArr.push('Поле «Промокод» не заполнено');
            setF1Error(1);
        }

        //
        if (item.days === '') {
            errArr.push('Поле «Кол-во дней» не заполнено');
            setF2Error(1);
        }

        //Отправка запроса
        if(errArr.length === 0)
        {
            try {
                //
                let response = await PromosService.create(item);

                //
                if(response.data.status === 'ok')
                {
                    //
                    setSuccess(true);
                    setTimeout(
                        () => {
                            setSuccess(false);
                        },
                        1500
                    );

                    //
                    setUpdTrigger( Date.now() );
                }
                else
                {
                    errArr.push('Ошибка создания промокода.');
                }
            }
            catch (e) {
                Errors422(e, errArr, 'Ошибка создания промокода.');
            }

        }

        //
        setErrors(errArr);
    }


    //Вывод компонента
    return (
        <div className="workarea_tools">

            <FormSuccess ok={success} text="Данные сохранены." fading="true"/>

            <FormErrors errors={errors}/>

            <div className="workarea_tools_left" id="p_manager_codes_tools_left1">

                <input
                    type="text"
                    name="code"
                    className={f1Error === 1 ? 'field error' : 'field'}
                    placeholder="Укажите промокод"
                    value={item.code}
                    onChange={ e => setItem(InputChange(e, item)) }
                />

                <input
                    type="text"
                    name="days"
                    className={f2Error === 1 ? 'field error' : 'field'}
                    placeholder="Укажите кол-во дней"
                    value={item.days}
                    onChange={ e => setItem(InputChange(e, item)) }
                />

            </div>

            <div className="workarea_tools_right" id="p_manager_codes_tools_right">

                <div
                    className="btn"
                    onClick={ () => handleSubmit() }
                >Создать промокод</div>

            </div>

        </div>
    );
};

export default ManagementPromoStandardAdd;