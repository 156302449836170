import $api from "../http";

export default class ReplicsService {

    static async getAll(page = 1, limit = 100, sort = 'id', direction = 'asc', filters = {}, search = '', fields = '', withRel = '', withCount = '') {
        return await $api.get('/replics/list', {
            params: {
                page: page,
                limit: limit,
                sort: sort,
                direction: direction,
                filters: filters,
                search: search,
                with: withRel,
                withCount: withCount
            }
        });
    }

    static async create(data) {
        return await $api.post('/replics/create', data);
    }

    static async getById(id, withRel = '') {
        return await $api.get('/replics/' + id + '/read', {
            params: {
                with: withRel,
            }
        });
    }

    static async updateById(id, data) {
        return await $api.post('/replics/' + id + '/update', data);
    }

    static async deleteById(id) {
        return await $api.delete('/replics/' + id + '/delete');
    }

    static async positionsUpdate(project_id, data) {
        return await $api.post('/replics/positions-update/', {
            project_id: project_id,
            data: data,
        });
    }

    static async createMass(data) {
        return await $api.post('/replics/create-mass', data);
    }

    static async linksUpdate(id, type, data) {
        return await $api.post('/replics/' + id + '/links-update/', {
            type: type,
            data: data,
        });
    }

}