export const daysAgo = (inputDate, withLabel = 0, label2 = '', label3 = '') => {

    //Текущая дата
    const tekDate = new Date();
    const tekDateMilliseconds = tekDate.getTime();

    //Дата создания скрипта
    inputDate = inputDate.replace('T', ' ');
    inputDate = inputDate.replace('.000000Z', '');
    const tekDateObj = new Date(inputDate);
    const inputDateMilliseconds = tekDateObj.getTime();

    //Разница дат
    const dateDiff = tekDateMilliseconds - inputDateMilliseconds;

    //Форматирование разницы в дни
    let Output = Math.round (dateDiff / 86400000 );

    //Подпись к дате
    if(withLabel === 1)
    {
        if(Output === 0)
        {
            Output = label3
        }
        else
        {
            const OutputLabel = dayTitle(Output);
            Output = Output + ' ' + OutputLabel + ' ' + label2;
        }

    }

    //Вывод результата
    return Output;
}

function dayTitle(number) {
    if (number > 10 && [11, 12, 13, 14].includes(number%100)) return 'дней';
    let last_num = number%10;
    if (last_num === 1) return 'день';
    if ([2,3,4].includes(last_num)) return 'дня';
    if ([5,6,7,8,9, 0].includes(last_num)) return 'дней';
}