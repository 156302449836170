import React, {useEffect, useState} from 'react';
import ModalWindow from "../../../Interface/ModalWindow/ModalWindow";
import UsersService from "../../../../services/UsersService";
import {Errors422} from "../../../../utils/Errors422";
import UsesService from "../../../../services/UsersService";


const ManagementUsersEmailForm = ({modal, setModal, tekItemId, setUpdTrigger, references}) => {

    //Данные сохранения
    const nullItem = {
        status: '',
    };

    const [item, setItem] = useState(nullItem);


    //Получение данных
    useEffect(() => {

        if(modal)
        {
            const getItem = async (id) => {
                const response = await UsersService.getById(id);
                response.data.data.status = 1;
                setItem(response.data.data);
            }
            getItem(tekItemId);
        }

    }, [modal, modal]);


    //Сохранение данных
    const handleSubmit = () => {
        setModal(false);
        UsersService.updateById(tekItemId, item);
        setUpdTrigger( Date.now() );
    }


    //Отмена сохранения
    const cancelSubmit = () => {
        setModal(false);
    }


    //Вывод компонента
    return (
        <ModalWindow
            title="Подтвердить почту"
            active={modal}
            setActive={setModal}
            type="forbidden"
        >

            <div className="modal_action_text">
                Вы действительно хотите подтвердить этот адрес электронной почты?
            </div>

            <div className="modal_action_btns">
                <div className="btn" onClick={ () => handleSubmit() }>Подтвердить</div>
                <div className="btn btn_c6" onClick={ () => cancelSubmit() }>Отменить</div>
            </div>

        </ModalWindow>
    );
};

export default ManagementUsersEmailForm;