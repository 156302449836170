import React, {useState} from 'react';
import ModalWindow from "../../Interface/ModalWindow/ModalWindow";
import ScriptsService from "../../../services/ScriptsService";
import NotificationsList from "../../Interface/Notifications/NotificationsList";
import {NotificationsAdd} from "../../../utils/NotificationsAdd";

const ScriptsDelForm = ({modal, setModal, tekItemId, setUpdTrigger}) => {

    //Уведомления
    const [notifications, setNotifications] = useState([]);

    //Удаление
    const handleSubmit = async () => {

        try {
            let response = await ScriptsService.deleteById(tekItemId);

            if(response.data.status === 'ok')
            {
                setUpdTrigger( Date.now() );
            }

            //
            NotificationsAdd(notifications, setNotifications, 'Скрипт был успешно удален.', 'ok');
        }
        catch (e) {
            //
        }

        setModal(false);

    }


    //Отмена
    const cancelSubmit = () => {
        setModal(false);
    }


    //Вывод компонента
    return (
        <>

            <ModalWindow title="Удалить скрипт" active={modal} setActive={setModal} type="del">

                <div className="modal_action_text">
                    Вы действительно хотите удалить скрипт продаж?<br />
                    После удаления действие невозможно будет отменить.
                </div>

                <div className="modal_action_btns">
                    <div className="btn btn_c5" onClick={ () => handleSubmit() }>Удалить</div>
                    <div className="btn btn_c6" onClick={ () => cancelSubmit() }>Отменить</div>
                </div>

            </ModalWindow>


            <NotificationsList
                items={notifications}
                setItems={setNotifications}
            />


        </>



    );
};

export default ScriptsDelForm;