import React, {useState, useEffect} from 'react';
import ReplicsService from "../../../../services/ReplicsService";
import {useFetching} from "../../../../hooks/useFetching";
import ScriptsEditRightList from "./ScriptsEditRightList";

const ScriptsEditRight = ({id, replicId, setReplicId, firstReplicId, updTrigger, setUpdTrigger, notifications, setNotifications}) => {

    //Параметры выборки
    const [items, setItems] = useState([]);


    //Фильтры
    const [filters, setFilters] = useState({
        project_id: {v:id, t:'='},
    });


    //Определение выборки списка
    const [fetchItems, isItemsLoading, itemsError] = useFetching(
        async () => {
            const response = await ReplicsService.getAll(1, 1000, 'position', 'asc', filters);
            setItems(response.data.data.items);
        }
    );


    //Инициализация выборки списка
    useEffect(() => {
        fetchItems();
    }, [
        replicId,
        updTrigger,
    ]);


    //Выбор реплики
    const selectReplic = (e, id) => {
        e.preventDefault();
        setReplicId(id)
    }


    //Передача функций
    const func = {
        select: selectReplic
    }


    //Вывод компонента
    return (
        <div id="p_editor_right">

            <div id="p_editor_right_title">Навигация</div>

            <div id="p_editor_right_list_out" className="horizontal_scroll">
                <div id="p_editor_right_list">

                    <ScriptsEditRightList
                        items={items}
                        isLoading={isItemsLoading}
                        func={func}
                        replicId={replicId}
                    />

                </div>
            </div>

        </div>
    );
};

export default ScriptsEditRight;