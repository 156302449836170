import React, {useContext} from 'react';
import ImgWarning from "../../../assets/img/workarea_msg_warning.svg";
import {AuthContext} from "../../../context/AuthContext";


const Tarif = ({msg}) => {


    //Получение пользователя
    const {AppAuthStore} = useContext(AuthContext);


    //Действующий тариф
    if(AppAuthStore.user.tarif_status === 1)
    {
        return '';
    }


    //Вывод компонента
    return (
        <div className="workarea_msg workarea_msg_warning">
            <img src={ImgWarning} alt="" />
            <span>{msg}</span>
        </div>
    );
};

export default Tarif;