export const SumFormat = function (sum) {
    sum = 1 * sum;
    const sumFormat = sum
        .toString()
        .replace(
            /(\d{3})+$/,
            (g, g1, i) => (i ? " " : "") + g.match(/.{3}/g).join(" ")
        );

    return sumFormat;
};