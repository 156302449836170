import React, {useState, useContext} from 'react';
import FormInputClear from "../../Interface/Forms/FormInputClear";
import FormInputHide from "../../Interface/Forms/FormInputHide";
import {Link} from "react-router-dom";
import {AuthContext} from "../../../context/AuthContext";
import NotificationsList from "../../Interface/Notifications/NotificationsList";
import {NotificationsArrAdd} from "../../../utils/NotificationsAdd";

const LoginForm = () => {

    //Данные формы
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');


    //Уведомления
    const [notifications, setNotifications] = useState([]);


    const handleSubmit = async (e) => {

        //
        e.preventDefault();

        //
        let errBeforeArr = [];
        let errAfterArr = [];

        //
        if(username === '')
        {
            errBeforeArr.push('Поле «Почта» не заполнено');
        }

        //
        if(password === '')
        {
            errBeforeArr.push('Поле «Пароль» не заполнено');
        }

        //
        if(errBeforeArr.length > 0)
        {
            NotificationsArrAdd(notifications, setNotifications, errBeforeArr, 'not1');
        }
        else
        {
            const res = await AppAuthStore.login(username, password);

            if(!res)
            {
                errAfterArr.push('Неверный логин или пароль');
            }
        }

        //
        if(errAfterArr.length > 0)
        {
            NotificationsArrAdd(notifications, setNotifications, errAfterArr, 'not2');
        }

    };


    const {AppAuthStore} = useContext(AuthContext);


    //Вывод компонентов
    return (
        <>

            <form>

                <div className="field_tr">
                    <div className="field_td field_td1">
                        <div className="label">Почта</div>
                        <FormInputClear
                            name="username"
                            value={username}
                            setValue={setUsername}
                        />
                    </div>
                </div>

                <div className="field_tr">
                    <div className="field_td field_td1">
                        <div className="label">Пароль</div>
                        <FormInputHide
                            name="password"
                            value={password}
                            setValue={setPassword}
                        />
                    </div>
                </div>

                <div className="field_tr">
                    <div className="field_td field_td1">
                        <div className="last_label last_label_right">
                            <Link to="/restore" className="last_label_bold">Забыли пароль?</Link>
                        </div>
                    </div>
                </div>

                <div className="field_tr btn_tr1">
                    <div className="field_td field_td1">
                        <input
                            type="submit"
                            className="btn"
                            value="Войти"
                            onClick={ e => handleSubmit(e) }
                        />
                    </div>
                </div>

            </form>


            <NotificationsList
                items={notifications}
                setItems={setNotifications}
            />


        </>
    );
};

export default LoginForm;