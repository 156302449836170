import React, {useState, useEffect} from 'react';
import NotificationsItem from "./NotificationsItem";

const NotificationsList = ({items, setItems}) => {

    //Вывод компонента
    return (
        <div id="modal_msg_list">
            {
                items.toReversed().filter(
                    (item) => item.time > Date.now() - 6000
                ).map(
                    (item, index) =>
                        <NotificationsItem
                            key={item.id}
                            item={item}
                            items={items}
                            setItems={setItems}
                        />
                )
            }
        </div>
    );
};

export default NotificationsList;