import React from 'react';
import Pagination from "./Pagination";
import FormSelect from "../Forms/FormSelect";

const PaginationLine = ({recCountVariants, recCountValue, recCountFunc, totalElems, totalPages, page, changePage, itemsLimit, itemsCount}) => {
    return (
        <div className="pagination_line">

            <div className="pagination_left">

                <div className="pagination_show">
                    <span>Показывать по</span>
                    <FormSelect
                        name="records_count"
                        options={recCountVariants}
                        defaultValue=""
                        value={recCountValue}
                        onChange={ e => recCountFunc(e.target.value) }
                    />
                </div>

            </div>

            <div className="pagination_right">

                <Pagination
                    page={page}
                    changePage={changePage}
                    totalElems={totalElems}
                    totalPages={totalPages}
                    itemsLimit={itemsLimit}
                    itemsCount={itemsCount}
                />

            </div>

        </div>
    );
};

export default PaginationLine;