import React, {useState, useEffect} from 'react';
import ModalWindow from "../../../Interface/ModalWindow/ModalWindow";
import UsersService from "../../../../services/UsersService";
import {Errors422} from "../../../../utils/Errors422";
import FormSuccess from "../../../Interface/FormMessage/FormSuccess";
import FormErrors from "../../../Interface/FormMessage/FormErrors";
import {InputChange} from "../../../../utils/InputChange";


const ManagementUsersPassForm = ({modal, setModal, tekItemId, setUpdTrigger, references}) => {


    //Данные сохранения
    const nullItem = {
        password: '',
        password_confirmation: ''
    };

    const [item, setItem] = useState(nullItem);

    const [success, setSuccess] = useState(false);
    const [errors, setErrors] = useState([]);


    //Получение данных
    useEffect(() => {

        if(modal)
        {
            const getItem = async (id) => {
                const response = await UsersService.getById(id);
                response.data.data.password = '';
                response.data.data.password_confirmation = '';
                setItem(response.data.data);
            }
            getItem(tekItemId);
        }

    }, [modal, modal]);


    //Сохранение данных
    const handleSubmit = async () => {

        //Ошибки
        let errArr = [];

        //
        if (item.password === '')
        {
            errArr.push('Поле «Пароль» не заполнено');
        }

        //
        if (item.password_confirmation === '')
        {
            errArr.push('Поле «Подтверждение пароля» не заполнено');
        }

        //
        if( (item.password !== '') && (item.password_confirmation !== '') && (item.password !== item.password_confirmation) )
        {
            errArr.push('Поле «Пароль» и «Подтверждение пароля не совпадают');
        }


        //Отправка запроса
        if(errArr.length === 0)
        {
            try {
                //
                let response = await UsersService.updateById(tekItemId, item);

                if(response.data.status === 'ok')
                {
                    //
                    setSuccess(true);
                    setTimeout(
                        () => {
                            setSuccess(false);
                            setModal(false);
                        },
                        1500
                    );

                    //
                    setUpdTrigger( Date.now() );
                }
                else
                {
                    errArr.push('Ошибка сохранения пользователя.');
                }
            }
            catch (e) {
                Errors422(e, errArr, 'Ошибка сохранения пользователя.');
            }

        }

        //
        setErrors(errArr);
    }


    //Отмена сохранения
    const cancelSubmit = () => {
        setModal(false);
    }

    //Вывод компонента
    return (
        <ModalWindow
            title="Смена пароля"
            active={modal}
            setActive={setModal}
            footer={
                {
                    leftBtn: {
                        title: 'Отменить',
                        func: cancelSubmit
                    },
                    rightBtn: {
                        title: 'Сменить пароль',
                        func: handleSubmit
                    }
                }
            }
        >

            <div id="p_manager_users_modal_pass">


                <FormSuccess ok={success} text="Пароль изменен." fading="true"/>

                <FormErrors errors={errors}/>

                <form>

                    <div className="field_tr">

                        <div className="field_td field_td1">
                            <div className="label">Пароль</div>
                            <input
                                type="password"
                                name="password"
                                className="field"
                                value={item.password}
                                onChange={ e => setItem(InputChange(e, item)) }
                                autoComplete="off"
                            />
                        </div>

                    </div>

                    <div className="field_tr">

                        <div className="field_td field_td1">
                            <div className="label">Подтверждение пароля</div>
                            <input
                                type="password"
                                name="password_confirmation"
                                className="field"
                                value={item.password_confirmation}
                                onChange={ e => setItem(InputChange(e, item)) }
                                autoComplete="off"
                            />
                        </div>

                    </div>

                </form>

            </div>


        </ModalWindow>
    );
};

export default ManagementUsersPassForm;