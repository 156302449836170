export const responseDownload = (responseData, fileName) => {

    let downloadUrl = window.URL.createObjectURL(
        new Blob([
            responseData
        ])
    );
    let downloadLink = document.createElement('a');
    downloadLink.href = downloadUrl;
    downloadLink.setAttribute('download', fileName);
    document.body.appendChild(downloadLink);
    downloadLink.click();

}