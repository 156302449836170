import React from 'react';
import PromosService from "../../../../../services/PromosService";
import ModalWindow from "../../../../Interface/ModalWindow/ModalWindow";

const ManagementPromoStandardDel = ({modal, setModal, tekItemId, setUpdTrigger}) => {


    //Удаление
    const handleSubmit = async () => {

        try {
            let response = await PromosService.deleteById(tekItemId);

            if(response.data.status === 'ok')
            {
                setUpdTrigger( Date.now() );
            }
        }
        catch (e) {
            //
        }

        setModal(false);

    }


    //Отмена удаления
    const cancelSubmit = () => {
        setModal(false);
    }


    //Вывод компонента
    return (
        <ModalWindow title="Удаление промокода" active={modal} setActive={setModal} type="del">

            <div className="modal_action_text">
                Вы действительно хотите промокод?
            </div>

            <div className="modal_action_btns">
                <div className="btn btn_c5" onClick={ () => handleSubmit() }>Удалить</div>
                <div className="btn btn_c6" onClick={ () => cancelSubmit() }>Отменить</div>
            </div>

        </ModalWindow>
    );
};

export default ManagementPromoStandardDel;