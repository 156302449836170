import React from 'react';

const ManagementAnalyticsContent = () => {
    return (
        <div>
            ManagementAnalyticsContent
        </div>
    );
};

export default ManagementAnalyticsContent;