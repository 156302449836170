import React, {useState} from 'react';
import ScriptsEditWorkAreaStart from "./ScriptsEditWorkAreaStart";
import ScriptsEditWorkAreaReplic from "./ScriptsEditWorkAreaReplic";

const ScriptsEditWorkArea = ({id, replicId, setReplicId, firstReplicId, updTrigger, setUpdTrigger, notifications, setNotifications}) => {

    //Вывод компонента
    return (
        <div id="p_editor_center">
            <div className="p_editor_center_item_out horizontal_scroll">
                {
                    replicId === 0
                        ?
                        <ScriptsEditWorkAreaStart
                            setReplicId={setReplicId}
                            firstReplicId={firstReplicId}
                        />
                        :
                        <ScriptsEditWorkAreaReplic
                            id={id}
                            replicId={replicId}
                            setReplicId={setReplicId}
                            firstReplicId={firstReplicId}
                            updTrigger={updTrigger}
                            setUpdTrigger={setUpdTrigger}
                            notifications={notifications}
                            setNotifications={setNotifications}
                        />
                }
            </div>
        </div>
    );
};

export default ScriptsEditWorkArea;