import React, {useState} from 'react';
import ImgPanelItem2 from "../../../assets/img/p_account_bonuses_actions_item2_img.svg";
import {SumFormat} from "../../../utils/SumFormat";
import BonusesPanelItem2Form from "./BonusesPanelItem2Form";

const BonusesPanelItem2 = ({available, setUpdTrigger}) => {


    //Модальные окна
    const [modalForm, setModalForm] = useState(false);


    //Заказать вывод
    const formInit = (e) => {
        e.preventDefault();
        setModalForm(true);
    }


    //Вывод компонента
    return (
        <>

            <div className="p_account_bonuses_actions_item_out">
                <div className="p_account_bonuses_actions_item p_account_bonuses_actions_item2">
                    <div className="p_account_bonuses_actions_label">Доступно к выводу</div>
                    <div className="p_account_bonuses_actions_title">{ SumFormat(available) } ₽</div>
                    <div className="p_account_bonuses_actions_panel">
                        <a
                            href=""
                            onClick={ e => formInit(e) }
                        >Заказать вывод</a>
                    </div>
                    <div className="p_account_bonuses_actions_img">
                        <img src={ImgPanelItem2} alt=""/>
                    </div>
                </div>
            </div>


            <BonusesPanelItem2Form
                modal={modalForm}
                setModal={setModalForm}
                available={available}
                setUpdTrigger={setUpdTrigger}
            />


        </>
    );
};

export default BonusesPanelItem2;