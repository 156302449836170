import React from 'react';

const TableSort = ({field, sort, direction, setSort}) => {
    return (
        <div className="table_sort_out">

            <span
                className={'table_sort table_sort_asc ' + (sort === field && direction === 'ASC' ? 'active' : '') }
                onClick={ () => setSort(field, 'ASC') }
                >
                &#9650;
            </span>

            <span
                className={'table_sort table_sort_desc ' + (sort === field && direction === 'DESC' ? 'active' : '') }
                onClick={ () => setSort(field, 'DESC') }
                >
                &#9660;
            </span>

        </div>
    );
};

export default TableSort;