import React from 'react';
import ManagementPromoStandardContent from "../../../../components/Pages/Management/Promo/Standard/ManagementPromoStandardContent";
import {managementMenu} from "../../../../menu/ManagementMenu";
import ManagementMenu from "../../../../components/Workarea/ManagementMenu/ManagementMenu";
import {promoTabsMenu} from "../../../../menu/PromoTabsMenu";
import TabsMenu from "../../../../components/Workarea/TabsMenu/TabsMenu";

const ManagementPromoStandardPage = () => {
    return (
        <div>
            <ManagementMenu menu={managementMenu}/>
            <div id="p_account_workarea_title">Промокоды</div>
            <TabsMenu menu={promoTabsMenu}/>
            <ManagementPromoStandardContent />
        </div>
    );
};

export default ManagementPromoStandardPage;