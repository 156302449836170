import React from 'react';
import ScriptsItem from "./ScriptsItem";


const ScriptsList = ({items, isLoading, func, references}) => {

    //
    if (!items.length && !isLoading) {
        return (
            <div id="p_account_scripts_list_null">Записи не найдены!</div>
        )
    }

    //Вывод компонента
    return (
        <>
            {
                items.map(
                    (item, index) =>
                        <ScriptsItem
                            key={item.id}
                            item={item}
                            func={func}
                            references={references}
                        />
                )
            }
        </>
    );
};

export default ScriptsList;