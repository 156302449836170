import React, {useEffect, useState} from 'react';
import {useFetching} from "../../../../hooks/useFetching";
import UsersService from "../../../../services/UsersService";
import {getPageCount} from "../../../../utils/Pages";
import ManagementUsersTools from "./ManagementUsersTools";
import ManagementUsersList from "./ManagementUsersList";
import PaginationLine from "../../../Interface/Pagination/PaginationLine";
import Preloader from "../../../Interface/Preloader";
import {responseDownload} from "../../../../utils/ResponseDownload";
import ManagementUsersEditForm from "./ManagementUsersEditForm";
import ManagementUsersDelForm from "./ManagementUsersDelForm";
import ManagementUsersEmailForm from "./ManagementUsersEmailForm";
import ManagementUsersPassForm from "./ManagementUsersPassForm";
import ManagementUsersAccessAddForm from "./ManagementUsersAccessAddForm";
import ManagementUsersAccessResetForm from "./ManagementUsersAccessResetForm";
import ManagementUsersTarifForm from "./ManagementUsersTarifForm";


const ManagementUsersContent = () => {

    //Параметры выборки
    const [items, setItems] = useState([])
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);

    const [totalElems, setTotalElems] = useState(0);
    const [totalPages, setTotalPages] = useState(0);

    //Параметры редактора
    const [tekItemId, setTekItemId] = useState(0);
    const [updTrigger, setUpdTrigger] = useState(0);

    //Сортировка
    const [sort, setSort] = useState('id');
    const [direction, setDirection] = useState('DESC');


    //Поиск
    const searchField = 'first_name,last_name,email,phone::';
    const [search, setSearch] = useState('');


    //Фильтры
    const [filters, setFilters] = useState({
        last_name: {v:'', t:'%'},
        first_name: {v:'', t:'%'},
        phone: {v:'', t:'%'},
        email: {v:'', t:'%'},
        tarif_id: {v:'null', t:'='},
    });

    //Выбранные элементы
    const [selected, setSelected] = useState([]);


    //Модальные окна
    const [modalFilters, setModalFilters] = useState(false);
    const [modalEdit, setModalEdit] = useState(false);
    const [modalDel, setModalDel] = useState(false);

    const [modalEmail, setModalEmail] = useState(false);
    const [modalPass, setModalPass] = useState(false);
    const [modalAccessAdd, setModalAccessAdd] = useState(false);
    const [modalAccessReset, setModalAccessReset] = useState(false);
    const [modalTarif, setModalTarif] = useState(false);


    //Показывать по
    const recordsCountArr = [
        {value: 10, name: 10},
        {value: 15, name: 15},
        {value: 20, name: 20},
        {value: 25, name: 25},
    ]


    //Список тарифов
    const tarifsList = [
        ['0', 'Без подписки'],
        ['1', 'Стандарт'],
    ];

    //Передача доп. списков
    const references = {
        tarifsList: tarifsList,
    };


    //Редактирование записи
    const editItemInit = (e, id) => {
        e.preventDefault();
        setTekItemId(id);
        setModalEdit(true);
    }


    //Удаление записи
    const delItemInit = (e, id) => {
        e.preventDefault();
        setTekItemId(id);
        setModalDel(true);
    }


    //Смена сортировки
    const setOrderBy = (field, direct) => {
        setSort(field);
        setDirection(direct);
    }

    //Передача функций
    const func = {
        edit: editItemInit,
        del: delItemInit,
        setOrderBy: setOrderBy,
    }


    //Определение выборки списка
    const [fetchItems, isItemsLoading, itemsError] = useFetching(
        async (page, limit) => {
            const response = await UsersService.getAll(page, limit, sort, direction, filters, searchField + search);
            setItems(response.data.data.items);
            const totalCount = response.data.data.total;
            setTotalElems(totalCount);
            setTotalPages(getPageCount(totalCount, limit));
        }
    );


    //Инициализация выборки списка
    useEffect(() => {
        fetchItems(page, limit);
    }, [
        page,
        limit,
        sort,
        direction,
        search,
        filters,
        updTrigger,
    ]);


    //Смена страницы
    const changePage = (page) => {
        setPage(page)
    }


    //Смена числа записей
    const changeLimit = (show) => {
        setLimit(show)
    }


    //Экспорт записей
    const exportFun = async () => {

        try {
            //
            let responseExport = await UsersService.exportAll(1, 1000, sort, direction, filters, searchField + search, 'last_name,first_name,email,phone');

            //
            responseDownload(responseExport.data,  'Users.xlsx');
        }
        catch (e) {
            //
        }

    }

    
    //Переход на первую страницу при применении фильтров
    useEffect(() => {
        setPage(1);
    }, [
        limit,
        search,
        filters
    ]);


    //Вывод компонента
    return (
        <>
		
		    {
                itemsError
                    ?
                    <div>Произошла ошибка ${itemsError}</div>
                    :
                    <></>
            }
		

            <ManagementUsersTools
                search={search}
                setSearch={setSearch}
                modalFilters={modalFilters}
                setModalFilters={setModalFilters}
                filters={filters}
                setFilters={setFilters}
                exportFun={exportFun}
                references={references}
            />


            <ManagementUsersList
                items={items}
                isLoading={isItemsLoading}
                func={func}
                references={references}
                sort={sort}
                direction={direction}
                selected={selected}
                setSelected={setSelected}
            />


            <PaginationLine
                recCountVariants={recordsCountArr}
                recCountValue={limit}
                recCountFunc={changeLimit}
                page={page}
                changePage={changePage}
                totalElems={totalElems}
                totalPages={totalPages}
                itemsLimit={limit}
                itemsCount={items.length}
            />


            {
                isItemsLoading
                    ?
                    <Preloader />
                    :
                    <></>
            }


            <ManagementUsersEditForm
                modal={modalEdit}
                setModal={setModalEdit}
                tekItemId={tekItemId}
                setUpdTrigger={setUpdTrigger}
                references={references}
                switchActionList={
                    {
                        Email: setModalEmail,
                        Pass: setModalPass,
                        AccessAdd: setModalAccessAdd,
                        AccessReset: setModalAccessReset,
                        Tarif: setModalTarif,
                        Del: setModalDel
                    }
                }
            />


            <ManagementUsersDelForm
                modal={modalDel}
                setModal={setModalDel}
                tekItemId={tekItemId}
                setUpdTrigger={setUpdTrigger}
            />


            <ManagementUsersEmailForm
                modal={modalEmail}
                setModal={setModalEmail}
                tekItemId={tekItemId}
                setUpdTrigger={setUpdTrigger}
            />


            <ManagementUsersPassForm
                modal={modalPass}
                setModal={setModalPass}
                tekItemId={tekItemId}
                setUpdTrigger={setUpdTrigger}
            />


            <ManagementUsersAccessAddForm
                modal={modalAccessAdd}
                setModal={setModalAccessAdd}
                tekItemId={tekItemId}
                setUpdTrigger={setUpdTrigger}
            />


            <ManagementUsersAccessResetForm
                modal={modalAccessReset}
                setModal={setModalAccessReset}
                tekItemId={tekItemId}
                setUpdTrigger={setUpdTrigger}
            />


            <ManagementUsersTarifForm
                modal={modalTarif}
                setModal={setModalTarif}
                tekItemId={tekItemId}
                setUpdTrigger={setUpdTrigger}
                references={references}
            />


        </>
    );
};

export default ManagementUsersContent;