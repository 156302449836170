import React from 'react';
import UnauthorizedHeader from "./UnauthorizedHeader";
import UnauthorizedWorkArea from "./UnauthorizedWorkArea";
import UnauthorizedFooter from "./UnauthorizedFooter";

const UnauthorizedLayout = () => {
    return (
        <div id="p_login_page">
            <div id="p_login_page_in" className="block">
                <UnauthorizedHeader />
                <UnauthorizedWorkArea />
                <UnauthorizedFooter />
            </div>
        </div>
    );
};

export default UnauthorizedLayout;