import React from 'react';
import ScriptsEditLeftFormAnswersItem from "./ScriptsEditLeftFormAnswersItem";

const ScriptsEditLeftFormAnswersList = ({items, func, status, excludeId}) => {

    //
    if (items.length === 0) {
        return (
            <></>
        )
    }

    //Вывод компонента
    return (
        <>
            {
                items.filter(
                    (item) => item.id !== excludeId
                ).map(
                    (item, index) =>
                        <ScriptsEditLeftFormAnswersItem
                            key={item.id}
                            item={item}
                            func={func}
                            status={status}
                        />
                )
            }
        </>
    );
};

export default ScriptsEditLeftFormAnswersList;
