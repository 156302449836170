import React from 'react';
import TabsMenuItem from "./TabsMenuItem";

const TabsMenu = (props) => {
    return (
        <div id="workarea_tabs">
            <div id="workarea_tabs_menu">
                <ul>
                    {
                        props.menu.map(
                            (route, index) =>
                                <TabsMenuItem
                                    to={route.path}
                                    title={route.title}
                                    key={index}
                                />
                        )
                    }
                </ul>
            </div>
        </div>
    );
};

export default TabsMenu;