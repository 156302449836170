import {NotificationsAdd} from "../../../../utils/NotificationsAdd";


//Скачать скрипт
const downloadItemInit = (e, setModal) => {
    e.preventDefault();
    setModal(true);
}


//Переименовать скрипт
const renameItemInit = (e, setModal) => {
    e.preventDefault();
    setModal(true);
}


//Создать дубликат
const duplItemInit = (e, setModal) => {
    e.preventDefault();
    setModal(true);
}


//Переместить в...
const moveItemInit = (e, setModal) => {
    e.preventDefault();
    setModal(true);
}


//Удаление записи
const delItemInit = (e, setModal) => {
    e.preventDefault();
    setModal(true);
}

//Поделиться ссылкой
const shareLink = (e, id, notifications, setNotifications) => {
    e.preventDefault();

    //Ссылка на просмотр
    const LinkStr = process.env.REACT_APP_BASE_URL + '/scripts/' + id + '/view';

    //Копирование в буфер
    navigator.clipboard.writeText(LinkStr);

    //Вывод уведомления
    NotificationsAdd(
        notifications,
        setNotifications,
        'Ссылка на скрипт скопирована в буфер обмена.',
        'ok'
    );
}


//Передача функций
export const ScriptsEditProjectFunc = {
    share: shareLink,
    download: downloadItemInit,
    rename: renameItemInit,
    dubl: duplItemInit,
    move: moveItemInit,
    del: delItemInit,
}