import React from 'react';
import ManagementBannersContent from "../../../components/Pages/Management/Banners/ManagementBannersContent";
import {managementMenu} from "../../../menu/ManagementMenu";
import ManagementMenu from "../../../components/Workarea/ManagementMenu/ManagementMenu";

const ManagementBannersPage = () => {
    return (
        <div>
            <ManagementMenu menu={managementMenu}/>
            <div id="p_account_workarea_title">Настройка баннеров</div>
            <ManagementBannersContent />
        </div>
    );
};

export default ManagementBannersPage;