import React, {useState, useEffect} from 'react';
import ModalWindow from "../../../Interface/ModalWindow/ModalWindow";
import ReplicsService from "../../../../services/ReplicsService";
import ScriptsEditLeftFormLinksList from "./FormsComponents/ScriptsEditLeftFormLinksList";
import ScriptsService from "../../../../services/ScriptsService";
import {NotificationsAdd, NotificationsArrAdd} from "../../../../utils/NotificationsAdd";
import {Errors422} from "../../../../utils/Errors422";
import {Find2List} from "../../../../utils/Find2List";


const ScriptsEditLeftAttachForm = ({modal, setModal, projectId, tekItemId, setUpdTrigger, notifications, setNotifications}) => {


    //Данные сохранения
    const nullItem = {
        parents_replics_num: 0,
        parents_replics: [],
    };

    //Параметры выборки
    const [item, setItem] = useState(nullItem);
    const [items, setItems] = useState([]);
    const [attachStatus, setAttachStatus] = useState({});


    //Получение данных
    useEffect(() => {

        if(modal) {
            if (tekItemId === 0)
            {
                setItems([]);
                setItem(nullItem);
                setAttachStatus({});
            }
            else
            {
                const getData = async (id) => {

                    //Все реплики
                    const response1 = await ReplicsService.getAll(1, 1000, 'position', 'asc', {project_id: {v:projectId, t:'='}});
                    setItems(response1.data.data.items);

                    //Данные текущей реплики
                    const response2 = await ReplicsService.getById(id, 'parents_replics,childs_replics');
                    setItem(response2.data.data);

                    //Статус прикрепления реплик
                    setAttachStatus(
                        Find2List(response1.data.data.items, response2.data.data.parents_replics)
                    );
                }

                getData(tekItemId);
            }

        }

    }, [modal, modal]);


    //Сохранение данных
    const handleSubmit = async () => {

        //
        let errAfterArr = [];

        //
        try {
            //
            let response = await ReplicsService.linksUpdate(tekItemId, 'parents', attachStatus);

            //
            if(response.data.status === 'ok')
            {
                NotificationsAdd(notifications, setNotifications, 'Связи карточки с другими обновлены.', 'ok');
                setUpdTrigger( Date.now() );
            }
            else
            {
                errAfterArr.push('Ошибка обновления связи карточки с другими.');
            }
        }
        catch (e) {
            Errors422(e, errAfterArr, 'Ошибка обновления связи карточки с другими.');
        }

        if(errAfterArr.length > 0)
        {
            NotificationsArrAdd(notifications, setNotifications, errAfterArr, 'not2');
        }

        //
        setModal(false);
    }


    //Отмена
    const cancelSubmit = () => {
        setModal(false);
    }


    //Смена статуса прикрепления
    const changeAttach = (id) => {

        //Определить новый статус
        let newStatus = 1;
        if( (attachStatus[id] !== undefined) && (attachStatus[id] === 1) )
        {
            newStatus = 0;
        }

        //Сохранить новый статус
        setAttachStatus({...attachStatus, ...{[id]: newStatus}});
    }


    //Передача функций
    const func = {
        changeAttach: changeAttach,
    }


    //Вывод компонента
    return (
        <ModalWindow
            title="Прикрепление карточки к другим ответам"
            active={modal}
            setActive={setModal}
            footer={
                {
                    leftBtn: {
                        title: 'Отменить',
                        func: cancelSubmit
                    },
                    rightBtn: {
                        title: 'Сохранить',
                        func: handleSubmit
                    }
                }
            }
        >

            <div className="p_editor_modal_form" id="p_editor_modal_form_edit">

                <div className="field_tr">
                    <div className="field_td field_td1 p_editor_roll__state p_editor_roll__open">
                        <div className="label">Связи карточки с другими
                            <span className="label_count">({item.parents_replics_num})</span>
                        </div>
                        <div className="sub_label">Здесь показывается к каким еще карточкам прикреплен данный ответ
                        </div>

                        <div className="p_editor_left_item_answers_list">

                            <ScriptsEditLeftFormLinksList
                                items={items}
                                func={func}
                                status={attachStatus}
                                excludeId={tekItemId}
                            />

                        </div>

                    </div>
                </div>

            </div>

        </ModalWindow>
    );
};

export default ScriptsEditLeftAttachForm;