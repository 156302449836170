import React from 'react';
import {getPagesArray} from "../../../utils/Pages";
import PaginationPrev from "../../../assets/img/pagination_prev.svg";
import PaginationNext from "../../../assets/img/pagination_next.svg";

const Pagination = ({totalElems, totalPages, page, changePage, itemsLimit, itemsCount}) => {

    let razdCount = 2;
    let razdValue = '...';

    let pagesArray = getPagesArray(totalPages, page, razdCount, razdValue);

    if(totalPages <= 1)
    {
        return '';
    }

    let beg = (page - 1) * itemsLimit + 1;
    let end = (page - 1) * itemsLimit + itemsCount;



    return (
        <div className="paginator">

            <div className="pagination_nums">{beg}-{end} из {totalElems}</div>

            <div className="pagination_links">

                {
                    page > 1
                    ?
                        <span
                            onClick={ () => changePage(page - 1) }
                                className="pagination_prev">
                                <img src={PaginationPrev}  alt=""/>
                        </span>
                        :
                        <></>
                }


                <div className="pagination_list">

                    <ul>
                        {
                            pagesArray.map(
                                pagesItem =>
                                    <li key={pagesItem}>
                                        {
                                            pagesItem !== ' ' + razdValue && pagesItem !== razdValue + ' '
                                            ?
                                                <span
                                                   onClick={ () => changePage(pagesItem) }
                                                   className={page === pagesItem ? 'active' : ''}
                                                >{pagesItem}</span>
                                                :
                                                <span>{razdValue}</span>
                                        }
                                    </li>
                            )
                        }
                    </ul>

                </div>

                {
                    page < totalPages
                        ?
                        <span
                            onClick={ () => changePage(page + 1) }
                            className="pagination_next">
                            <img src={PaginationNext}  alt=""/>
                        </span>
                        :
                        <></>
                }


            </div>

        </div>

    );
};

export default Pagination;