import React from 'react';
import ItemAdd from "../../../../assets/img/p_manager_templates_item_add.svg";
import TemplatesService from "../../../../services/TemplatesService";

const ManagementTemplatesAdd = ({setUpdTrigger}) => {

    //Данные сохранения
    const nullItem = {
        name: 'Название шаблона',
        desc: 'Описание шаблона',
        project_id: 0,
        price: '',
        no_price: 0,
    };


    //Сохранение данных
    const handleSubmit = async () => {

        try {
            //
            let response = await TemplatesService.create(nullItem);

            if(response.data.status === 'ok')
            {
                setUpdTrigger( Date.now() );
            }
            else
            {
                //
            }
        }
        catch (e) {
            //
        }

    }


    //Вывод компонента
    return (
        <div className="p_manager_templates_item_out">
            <div
                className="p_manager_templates_item p_manager_templates_item_add"
                onClick={ () => handleSubmit() }
            >
                <div className="p_manager_templates_item_add_in">

                    <div className="p_manager_templates_item_add_img">
                        <img src={ItemAdd} alt="" />
                    </div>
                    <div className="p_manager_templates_item_add_title">Добавить новый шаблон</div>

                </div>
            </div>
        </div>
    );
};

export default ManagementTemplatesAdd;