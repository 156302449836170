import React from 'react';
import BonusesContent from "../../components/Pages/Bonuses/BonusesContent";

const BonusesPage = () => {
    return (
        <div>
            <div id="p_account_workarea_title">Бонусная программа</div>
            <BonusesContent />
        </div>
    );
};

export default BonusesPage;