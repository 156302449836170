import React, {useEffect, useState} from 'react';
import {useInView} from "react-intersection-observer";
import Preloader from "../Preloader";

const EndlessScrollingImitation = ({maxHeight, setMaxHeight}) => {


    //Предзагрузка
    const [isloading, setIsloading] = useState(false);


    //Отслеживание элемента инициилизации подгрузки
    const { ref, inView, entry } = useInView();


    //Отслеживание элемента
    useEffect(() => {
        if (inView) {

            setIsloading(true);

            setTimeout(() => {
                setMaxHeight(maxHeight + 400);
                setIsloading(false);
            }, 500);

        }
    }, [inView]);


    //Элемент инициилизации подгрузки
    return (
        <div ref={ref}>
            {
                isloading
                    ?
                    <Preloader />
                    :
                    <></>
            }
        </div>
    );

};

export default EndlessScrollingImitation;