import React from 'react';

const ScriptsViewLeftItem = ({item, func, replicId}) => {

    //Вывод компонента
    return (
        <li className={ item.id === replicId ? 'active' : ''}>
            <a
                href=""
                onClick={e => func.select(e, item.id)}
            >{item.name}</a>
        </li>
    );
};

export default ScriptsViewLeftItem;