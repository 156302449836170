import React from 'react';

const FormErrors = ({errors}) => {
    return (

        errors.length > 0 &&
        <div className="form_message form_message_error form_message_fading_">
            <div className="form_message_title">Ошибка!!!</div>
            <div className="form_message_text">
                <ul>
                    {
                        errors.map(
                            (error, index) =>
                                <li key={index}>{error}</li>
                        )
                    }
                </ul>
            </div>
        </div>

    );
};

export default FormErrors;