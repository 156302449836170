import React, {useState, useEffect} from 'react';
import {useFetching} from "../../../hooks/useFetching";
import ReplicsService from "../../../services/ReplicsService";
import ScriptsViewLeftList from "./ScriptsViewLeftList";

const ScriptsViewLeft = ({id, replicId, setReplicId}) => {

    //Параметры выборки
    const [items, setItems] = useState([]);


    //Фильтры
    const [filters, setFilters] = useState({
        project_id: {v:id, t:'='},
    });


    //Определение выборки списка
    const [fetchItems, isItemsLoading, itemsError] = useFetching(
        async () => {
            const response = await ReplicsService.getAll(1, 1000, 'position', 'asc', filters);
            setItems(response.data.data.items);
        }
    );


    //Инициализация выборки списка
    useEffect(() => {
        fetchItems();
    }, [
        replicId,
        replicId,
    ]);


    //Выбор реплики
    const selectReplic = (e, id) => {
        e.preventDefault();
        setReplicId(id);
    }


    //Передача функций
    const func = {
        select: selectReplic
    }


    //Вывод компонента
    return (
        <div id="p_view_left">

            <div id="p_view_left_title">Навигация</div>

            <div id="p_view_left_list_out" className="horizontal_scroll">
                <div id="p_view_left_list">

                    <ScriptsViewLeftList
                        items={items}
                        isLoading={isItemsLoading}
                        func={func}
                        replicId={replicId}
                    />

                </div>
            </div>

        </div>
    );
};

export default ScriptsViewLeft;