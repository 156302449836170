import {makeAutoObservable} from "mobx";
import AuthService from "../services/AuthService";

export default class AuthStore {

    isAuth = false;
    user = {};
    isAccess = true;
    isLoading = false;
    isCheckAuth = false;


    constructor() {
        makeAutoObservable(this);
    }


    setAuth(bool){
        this.isAuth = bool;
    }


    setUser(user){
        this.user = user;
    }


    setAccess(bool){
        this.isAccess = bool;
    }


    setLoading(bool){
        this.isLoading = bool;
    }


    setCheckAuth(bool){
        this.isCheckAuth = bool;
    }


    async login(username, password){

        let res = false;

        try {
            const response = await AuthService.login(username, password);
            localStorage.setItem('token', response.data.access_token);
            this.checkAuth();
            res = true;
        }
        catch (e) {
            //console.log(e);
        }

        return res;
    }


    async logout(username, password){

        try {
            await AuthService.logout();
            localStorage.removeItem('token');
            this.setUser({});
            this.setAuth(false);
        }
        catch (e) {
            //console.log(e);
        }

    }


    async checkAuth(){

        this.setLoading(true);

        try {
            const response = await AuthService.me();
            this.setUser(response.data);
            this.setAuth(true);
        }
        catch (e) {
            //console.log(e);
        }
        finally {
            this.setLoading(false);
            this.setCheckAuth(true);
        }

    }


    checkAccess(){
        this.setAccess(true);
    }

}
