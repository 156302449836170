import React, {useContext, useState} from 'react';
import ModalWindow from "../../Interface/ModalWindow/ModalWindow";
import FormInputClear from "../../Interface/Forms/FormInputClear";
import ImgAddformSposob1 from "../../../assets/img/p_account_scripts_addform_sposob1.svg";
import ImgAddformSposob2 from "../../../assets/img/p_account_scripts_addform_sposob2.svg";
import ImgInf from "../../../assets/img/field_select_panel_item_inf.svg";
import ScriptsService from "../../../services/ScriptsService";
import {Errors422} from "../../../utils/Errors422";
import {AuthContext} from "../../../context/AuthContext";
import NotificationsList from "../../Interface/Notifications/NotificationsList";
import {NotificationsAdd, NotificationsArrAdd} from "../../../utils/NotificationsAdd";


const ScriptsAddForm = ({modal, setModal, tekItemId, setUpdTrigger, references}) => {


    //Получение пользователя
    const {AppAuthStore} = useContext(AuthContext);
    const userId = AppAuthStore.user.id;


    //Данные сохранения
    const nullItem = {
        user_id: userId,
        rubric_id: 0,
        type: 0,
        name: '',
        desc: '',
    };

    //
    const [item, setItem] = useState(nullItem);


    //Уведомления
    const [notifications, setNotifications] = useState([]);


    //Сохранение данных
    const handleSubmit = async () => {

        //
        let errBeforeArr = [];
        let errAfterArr = [];


        //
        if(item.name === '')
        {
            errBeforeArr.push('Поле «Название скрипта продаж» не заполнено');
        }


        //
        if(item.type === 0)
        {
            errBeforeArr.push('Поле «Способ создания скрипта продаж» не заполнено');
        }


        if(errBeforeArr.length > 0)
        {
            NotificationsArrAdd(notifications, setNotifications, errBeforeArr, 'not1');
        }
        else
        {
            try {
                //
                let response = await ScriptsService.create(item);

                //
                if(response.data.status === 'ok')
                {
                    //
                    setModal(false);

                    //
                    NotificationsAdd(notifications, setNotifications, 'Скрипт продаж создан.', 'ok');

                    //
                    setUpdTrigger( Date.now() );
                }
                else
                {
                    errAfterArr.push('Ошибка сохранения скрипта.');
                }
            }
            catch (e) {
                Errors422(e, errAfterArr, 'Ошибка сохранения скрипта.', 1);
            }

        }

        //
        if(errAfterArr.length > 0)
        {
            NotificationsArrAdd(notifications, setNotifications, errAfterArr, 'not2');
        }

    }


    //Отмена
    const cancelSubmit = () => {
        setModal(false);
    }


    //Переключить действие
    const switchRubric = (rubricId) => {
        setItem({...item, ...{['rubric_id']: rubricId}});
    }


    //Переключить тип
    const switchType = (type) => {
        setItem({...item, ...{['type']: type}});
    }


    //Вывод компонента
    return (
        <>

            <ModalWindow
                title="Создание скрипта продаж"
                active={modal}
                setActive={setModal}
                footer={
                    {
                        leftBtn: {
                            title: 'Отменить',
                            func: cancelSubmit
                        },
                        rightBtn: {
                            title: 'Создать скрипт продаж',
                            func: handleSubmit
                        }
                    }
                }
            >

                <div id="p_account_scripts_addform">


                    <div className="field_tr">
                        <div className="field_td field_td1">
                            <div className="label">Название скрипта продаж</div>

                            <FormInputClear
                                name="name"
                                value={item.name}
                                setValue={setItem}
                                item={item}
                            />

                        </div>
                    </div>


                    <div className="field_tr">
                        <div className="field_td field_td1">
                            <div className="label">Расположение</div>

                            <div className="field_select_label">
                                <div
                                    className={item.rubric_id === 0 ? 'field_select_label_item active' : 'field_select_label_item'}
                                    onClick={ () => switchRubric(0) }
                                >Все скрипты</div>
                                {
                                    references.rubrics.map(
                                        (rubricsItem, rubricsIndex) =>
                                            <div
                                                className={item.rubric_id === rubricsItem.id ? 'field_select_label_item active' : 'field_select_label_item'}
                                                key={rubricsItem.id}
                                                onClick={ () => switchRubric(rubricsItem.id) }
                                            >{rubricsItem.name}</div>
                                    )
                                }
                            </div>

                        </div>
                    </div>


                    <div className="field_tr">
                        <div className="field_td field_td1">
                            <div className="label">Способ создания скрипта продаж</div>

                            <div className="field_select_panel">

                                <div className="field_select_panel_item_out field_td2">
                                    <div
                                        className={item.type === 1 ? 'field_select_panel_item active' : 'field_select_panel_item'}
                                        onClick={ () => switchType(1) }
                                    >
                                        <div className="field_select_panel_item_img">
                                            <img src={ImgAddformSposob1} alt=""/>
                                        </div>
                                        <div className="field_select_panel_item_info">
                                            <div className="field_select_panel_item_title">Скрипт продаж с нуля</div>
                                            <div className="field_select_panel_item_desc">Создайте самостоятельно скрипт
                                                продаж с помощью нашего функционального конструктора.
                                            </div>
                                            <div className="field_select_panel_item_inf">
                                                <img src={ImgInf} alt=""/>
                                                Услуга включена в тарифный план
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="field_select_panel_item_out field_td2">
                                    <div
                                        className={item.type === 2 ? 'field_select_panel_item active' : 'field_select_panel_item'}
                                        onClick={ () => switchType(2) }
                                    >
                                        <div className="field_select_panel_item_img">
                                            <img src={ImgAddformSposob2} alt=""/>
                                        </div>
                                        <div className="field_select_panel_item_info">
                                            <div className="field_select_panel_item_title">Создание с помощью ИИ</div>
                                            <div className="field_select_panel_item_desc">
                                                Ответьте на пару вопросов и мы составим нужный скрипт продаж для вашей ниши.
                                                На это уйдет не более 3х минут.
                                            </div>
                                            <div className="field_select_panel_item_inf">
                                                <img src={ImgInf} alt=""/>
                                                Услуга включена в тарифный план
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>


                </div>

            </ModalWindow>


            <NotificationsList
                items={notifications}
                setItems={setNotifications}
            />


        </>
    );

};

export default ScriptsAddForm;