export const dateFormat = function (date, format = 'Y-m-d H:i:s') {
    //"Y-m-d H:i:s"

    if( (date === undefined) || (date === null) || (date === '') )
    {
        return '';
    }

    //
    date = date.replace('T', ' ');
    date = date.replace('.000000Z', '');

    //
    const date_arr = date.split(' ');

    //
    const part_date = date_arr[0];
    let part_time = date_arr[1];

    //
    if( (part_time === undefined) || (part_time === null) || (part_time === '') )
    {
        part_time = '00:00:00';
    }

    //
    const part_date_arr = part_date.split('-');
    const part_time_arr = part_time.split(':');

    //
    const date_Y = part_date_arr[0];
    const date_m = part_date_arr[1];
    const date_d = part_date_arr[2];

    //
    const date_H = part_time_arr[0];
    const date_i = part_time_arr[1];
    const date_s = part_time_arr[2];

    //
    let df = format;
    df = df.replace('Y', date_Y);
    df = df.replace('m', date_m);
    df = df.replace('d', date_d);
    df = df.replace('H', date_H);
    df = df.replace('i', date_i);
    df = df.replace('s', date_s);

    //
    return df;
};