import React from 'react';
import Logo from "../../assets/img/Logo.svg";

const UnauthorizedHeader = () => {
    return (
        <div id="p_login_header">

            <a href="/" id="p_login_header_logo">
                <img src={Logo} alt="" />
            </a>

            <div id="p_login_header_menu">
                <ul>
                    <li><a href="" target="_blank">Поддержка</a></li>
                    <li><a href="" target="_blank">Наш телеграм</a></li>
                </ul>
            </div>

        </div>
    );
};

export default UnauthorizedHeader;