import React from 'react';
import ToolsFilter from "../../../../assets/img/tools_filter.svg";
import ToolsExport from "../../../../assets/img/tools_export.svg";
import ToolsMails from "../../../../assets/img/tools_mails.svg";
import ToolsItemV3Smbtn from "../../../../assets/img/tools_item_v3_smbtn.svg";
import ManagementUsersFiltersForm from "./ManagementUsersFiltersForm";
import FormInputSearch from "../../../Interface/Forms/FormInputSearch";

const ManagementUsersTools = ({search, setSearch, modalFilters, setModalFilters, filters, setFilters, exportFun, references}) => {
    return (
        <>

            <div className="workarea_tools">

                <div className="workarea_tools_left" id="p_manager_users_searsh">

                    <FormInputSearch
                        name='search'
                        value={search}
                        setValue={setSearch}
                    />

                </div>

                <div className="workarea_tools_right" id="p_manager_users_menu">
                    <div id="workarea_tools_menu">

                        <div
                            className="workarea_tools_menu_item tools_item_v1"
                            onClick={ () => setModalFilters(true) }
                            >
                            <img src={ToolsFilter}  alt=''/>
                            Фильтры
                        </div>

                        <div
                            className="workarea_tools_menu_item tools_item_v2"
                            onClick={ () => exportFun() }
                            >
                            <img src={ToolsExport}  alt=''/>
                            Экспорт
                        </div>

                        <div className="workarea_tools_menu_item tools_item_v3">
                            <img src={ToolsMails}  alt=''/>
                            Создать рассылку
                            <div className="tools_item_v3_smbtn">
                                <img src={ToolsItemV3Smbtn}  alt=''/>
                            </div>
                        </div>

                    </div>
                </div>

            </div>


            <ManagementUsersFiltersForm
                modal={modalFilters}
                setModal={setModalFilters}
                filters={filters}
                setFilters={setFilters}
                references={references}
            />


        </>


    );
};

export default ManagementUsersTools;