import React, {useEffect, useState} from 'react';
import ImgClose from "../../../assets/img/modal_msg_close.svg";
import ImgOk from "../../../assets/img/modal_msg_ok.svg";
import ImgNot1 from "../../../assets/img/modal_msg_not1.svg";
import ImgNot2 from "../../../assets/img/modal_msg_not2.svg";

const NotificationsItem = ({item, items, setItems}) => {

    //Статус скрытия
    const [hideStatus, setHideStatus] = useState(0);

    
    //Скрытие сообщения
    const delItem = (itemId) => {
        const newItems = items.filter(
            (tekItem) => tekItem.id !== itemId
        );

        setItems(newItems);
    }


    //Скрыть сообщение
    const hideItem = () => {
        //Проверка активности
        if(item.time < Date.now() - 6000)
        {
            setHideStatus(1);
        }
    }


    //Вызов функции очистки
    useEffect(() => {

        if(items.length > 0)
        {
            setInterval(
                () => {
                    hideItem();
                },
                1
            );
        }

    }, []);



    //Вывод компонента
    return (
        <>
            {
                hideStatus === 0
                    ?
                    <div className="modal_msg_item">
                        <div className="modal_msg">

                            {
                                item.type === 'ok'
                                    ?
                                    <div className="modal_msg_img">
                                        <img src={ImgOk} alt=""/>
                                    </div>
                                    :
                                    <></>
                            }

                            {
                                item.type === 'not1'
                                    ?
                                    <div className="modal_msg_img">
                                        <img src={ImgNot1} alt=""/>
                                    </div>
                                    :
                                    <></>
                            }

                            {
                                item.type === 'not2'
                                    ?
                                    <div className="modal_msg_img">
                                        <img src={ImgNot2} alt=""/>
                                    </div>
                                    :
                                    <></>
                            }

                            <div className="modal_msg_text">
                                {item.text}
                            </div>

                            <div className="modal_msg_close" onClick={() => delItem(item.id)}>
                                <img src={ImgClose} alt=""/>
                            </div>

                        </div>
                    </div>
                    :
                    <></>
            }
        </>
    );
};

export default NotificationsItem;