import React, {useState, useEffect} from 'react';
import BonusesUsers from "./BonusesUsers";
import BonusesOperations from "./BonusesOperations";
import Banner from "../../Workarea/Banner/Banner";
import Tarif from "../../Workarea/Tarif/Tarif";
import {TabsFunc} from "../../../utils/TabsFunc";
import BonusesPanelItem1 from "./BonusesPanelItem1";
import BonusesPanelItem2 from "./BonusesPanelItem2";
import BonusesPanelItem3 from "./BonusesPanelItem3";
import OperationsService from "../../../services/OperationsService";
import {useFetching} from "../../../hooks/useFetching";

const BonusesContent = () => {


    //Параметры выборки
    const nulItem = {
        profit: 0,
        received: 0,
        reserved: 0,
        available: 0
    }

    const [item, setItem] = useState(nulItem);


    //Параметры редактора
    const [updTriggerOperations, setUpdTriggerOperations] = useState(0);


    //Определение выборки списка
    const [fetchItems, isItemsLoading, itemsError] = useFetching(
        async () => {
            const response = await OperationsService.summary();
            setItem(response.data.data);
        }
    );


    //Инициализация выборки списка
    useEffect(() => {
        fetchItems();
    }, [
        updTriggerOperations,
        updTriggerOperations
    ]);


    //Вывод компонента
    return (
        <>

            <Banner
                place="2"
            />

            <Tarif
                msg="Действие вашего тарифного плана истекло. Для дальнейшей работы необходимо продлить подписку, чтобы снять ограничения работы платформы"
            />

            <div id="p_account_bonuses">
                

                <div id="p_account_bonuses_actions">

                    <div id="p_account_bonuses_actions_list">

                        <BonusesPanelItem1
                            profit={item.profit}
                            setUpdTrigger={setUpdTriggerOperations}
                        />

                        <BonusesPanelItem2
                            available={item.available}
                            setUpdTrigger={setUpdTriggerOperations}
                        />

                        <BonusesPanelItem3 />

                    </div>

                </div>


                <div id="p_account_bonuses_rubics">

                    <div id="p_account_bonuses_rubics_list">

                        <a
                            href=""
                            className="p_account_bonuses_rubics_item active"
                            onClick={ e => TabsFunc(e, '.p_account_bonuses_rubics_item', '.p_account_bonuses_tab', 'active') }
                        >Приглашенные пользователи</a>

                        <a
                            href=""
                            className="p_account_bonuses_rubics_item"
                            onClick={ e => TabsFunc(e, '.p_account_bonuses_rubics_item', '.p_account_bonuses_tab', 'active') }
                        >История операций</a>

                    </div>

                </div>


                <div id="p_account_bonuses_tabs">

                    <div className="p_account_bonuses_tab active">
                        <BonusesUsers />
                    </div>

                    <div className="p_account_bonuses_tab">

                        <BonusesOperations

                        />

                    </div>

                </div>

            </div>


        </>
    );
};

export default BonusesContent;