export const Find2List = function (ListAll, ListFind) {

    let ListRes = {};

    ListAll.forEach((ListAllItem) => {

        if( ListFind.filter(ListFindItem => ListFindItem.id === ListAllItem.id).length > 0 )
        {
            ListRes[ListAllItem.id] = 1;
        }
        else
        {
            ListRes[ListAllItem.id] = 0;
        }

    });

    return ListRes;
};