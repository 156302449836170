import React from 'react';
import {dateFormat} from "../../../utils/DateFormat";

const BonusesUsersItem = ({item}) => {

    //Вывод компонента
    return (
        <tr>
            <td>{item.last_name} {item.first_name}</td>
            <td>{item.email}</td>
            <td>0 ₽</td>
            <td><span className="p_account_bonuses_table_profit">0 ₽</span></td>
            <td>{ dateFormat(item.created_at, 'd.m.Y в H:i') }</td>
        </tr>
    );
};

export default BonusesUsersItem;