import React from 'react';
import ScriptsViewContent from "../../components/Pages/ScriptsView/ScriptsViewContent";

const ScriptsViewPage = () => {
    return (
        <>
            <ScriptsViewContent />
        </>
    );
};

export default ScriptsViewPage;