import $api from "../http";

export default class RegisterService {

    static async regUser(data) {
        return await $api.post('/registration/reg_user', data);
    }

    static async setPass(data) {
        return await $api.post('/registration/set_pass', data);
    }

    static async sendVeriCode(data) {
        return await $api.post('/registration/send_veri_code', data);
    }

    static async checkVeriCode(data) {
        return await $api.post('/registration/check_veri_code', data);
    }

}