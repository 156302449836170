import React from 'react';
import ScriptsViewLeftItem from "./ScriptsViewLeftItem";

const ScriptsViewLeftList = ({items, isLoading, func, replicId}) => {

    //Вывод компонента
    return (
        <ul>
            {
                items.map(
                    (item, index) =>
                        <ScriptsViewLeftItem
                            key={item.id}
                            item={item}
                            func={func}
                            replicId={replicId}
                        />
                )
            }
        </ul>
    );
};

export default ScriptsViewLeftList;