import React from 'react';

const ModalWindowEmpty = ({children, active, setActive, type}) => {

    //Классы оболочки
    const rootClasses = ['modal']


    //Типовые окна
    if(type === 'del')
    {
        rootClasses.push('modal_action');
        rootClasses.push('modal_action_del');
    }

    if(type === 'forbidden')
    {
        rootClasses.push('modal_action');
        rootClasses.push('modal_action_forbidden');
    }


    //Активность окна
    if (active) {
        rootClasses.push('active');
    }


    //Вывод компонента
    return (
        <div className={rootClasses.join(' ')} onClick={() => setActive(false)}>
            <div className="modal_content" onClick={(e) => e.stopPropagation()}>
                {children}
            </div>
        </div>
    );
};

export default ModalWindowEmpty;