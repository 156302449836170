import React from 'react';
import ScriptsEditLeftItem from "./ScriptsEditLeftItem";
import EditorSearchNull from "../../../../assets/img/p_editor_search_null.svg"
//import { Droppable } from 'react-beautiful-dnd';
import { Droppable } from '@hello-pangea/dnd';

const ScriptsEditLeftList = ({items, isLoading, func, replicId, setters, firstReplicId}) => {

    //Прокрутка до активного блока
    const activeItem = document.querySelector('.p_editor_left_item.active');

    if(activeItem)
    {
        document.getElementById('p_editor_left_list_out').scrollTop = activeItem.offsetTop;
    }


    //Записи не найдены
    if (!items.length && !isLoading) {
        return (
            <div className="p_editor_left_item_out">

                <div className="p_editor_left_item_null">

                    <div className="p_editor_left_item_null_img">
                        <img src={EditorSearchNull} alt=""/>
                    </div>

                    <div className="p_editor_left_item_null_text">
                        Карточек с таким названием<br/>
                        не найдено
                    </div>

                </div>

            </div>
        )
    }


    //Вывод компонента
    return (
        <Droppable droppableId="list_1">

            {
                (provided) => (

                    <div
                        id="p_editor_left_list"
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                    >

                        {
                            items.map(
                                (item, index) =>
                                    <ScriptsEditLeftItem
                                        key={item.id}
                                        item={item}
                                        index={index}
                                        func={func}
                                        replicId={replicId}
                                        setters={setters}
                                        firstReplicId={firstReplicId}
                                    />
                            )
                        }

                        {provided.placeholder}

                    </div>
                )
            }

        </Droppable>
    );
};

export default ScriptsEditLeftList;