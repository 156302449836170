import React from 'react';

const ScriptsViewWorkAreaAnswersItem = ({item, func}) => {

    //Вывод компонента
    return (
        <div className="p_view_center_item_answers_item_out">
            <div
                className="p_view_btn p_view_center_item_answers_item"
                onClick={e => func.select(e, item.id)}
            >
                {item.name}
            </div>
        </div>
    );
};

export default ScriptsViewWorkAreaAnswersItem;