import React from 'react';
import ImgFieldSearch from "../../../assets/img/search-alt-2.svg"

const FormInputSearch = ({name, value, setValue, placeholder}) => {
    return (
        <div className="field_ico_out">
            <input
                type="text"
                name={name}
                className="field"
                value={value}
                onChange={e => setValue(e.target.value)}
                autoComplete="off"
                placeholder={placeholder}
            />
            <div
                className="field_ico"
                onClick={() => {} }
            >
                <img src={ImgFieldSearch} alt="" />
            </div>
        </div>
    );
};

export default FormInputSearch;