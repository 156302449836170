import React from 'react';
import ProfileContent from "../../components/Pages/Profile/ProfileContent";

const ProfilePage = () => {
    return (
        <div>
            <div id="p_account_workarea_title">Личный кабинет</div>
            <ProfileContent />
        </div>
    );
};

export default ProfilePage;