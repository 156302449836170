import React from 'react';
import ModalWindow from "../../Interface/ModalWindow/ModalWindow";

const ScriptsDownloadForm = ({modal, setModal, tekItemId, setUpdTrigger}) => {


    //Сохранение данных
    const handleSubmit = async () => {

        alert('Сохранение данных - Скачать скрипт"');

    }


    //Отмена
    const cancelSubmit = () => {
        setModal(false);
    }


    //Вывод компонента
    return (
        <>

            <ModalWindow
                title="Скачать скрипт"
                active={modal}
                setActive={setModal}
                footer={
                    {
                        leftBtn: {
                            title: 'Отменить',
                            func: cancelSubmit
                        },
                        rightBtn: {
                            title: 'Скачать скрипт',
                            func: handleSubmit
                        }
                    }
                }
            >
                <div id="p_account_scripts_modal_download">

                    #ScriptsDownloadForm#

                </div>

            </ModalWindow>

        </>
);
};

export default ScriptsDownloadForm;