import React from 'react';
import ScriptsShopItem from "./ScriptsShopItem";

const ScriptsShopList = ({items, isLoading, func, references}) => {

    //Отображать шаблон
    const viewTemplateItem = (itemId) => {

        if( localStorage.getItem('hide_template_' + itemId) === '1')
        {
            return false;
        }

        return true;
    }


    //Вывод компонента
    return (
        <>
            {
                items.filter(
                    (item) => viewTemplateItem(item.id)
                ).map(
                    (item, index) =>
                        <ScriptsShopItem
                            key={item.id}
                            item={item}
                            func={func}
                            references={references}
                        />
                )
            }
        </>
    );

};

export default ScriptsShopList;