import React from 'react';
//import "../../../assets/js/ulogin";

const LoginULogin = () => {

    //
    return (
        <>

            <div id="uLogin"
                 data-ulogin="display=small;theme=classic;fields=first_name,last_name;providers=vkontakte,google,yandex;hidden=;redirect_uri=http%3A%2F%2Fapi.sendly.local%2Fapi%2Fulogin%2Flogin;mobilebuttons=0;"></div>

        </>
    );
};

export default LoginULogin;