import React from 'react';
import ScriptsEditRightItem from "./ScriptsEditRightItem";

const ScriptsEditRightList = ({items, isLoading, func, replicId}) => {

    //Вывод компонента
    return (
        <ul>
            {
                items.map(
                    (item, index) =>
                        <ScriptsEditRightItem
                            key={item.id}
                            item={item}
                            func={func}
                            replicId={replicId}
                        />
                )
            }
        </ul>
    );
};

export default ScriptsEditRightList;