import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import EditorBackImg from '../../../assets/img/p_editor_back.svg';
import LogoImg from '../../../assets/img/Logo.svg';
import EditorDownImg from '../../../assets/img/p_editor_down.svg';
import SubmenuPayImg from '../../../assets/img/submenu_pay.svg';
import EditorPlayImg from '../../../assets/img/p_editor_play.svg';
import SubMenu from "../../Interface/SubMenu/SubMenu";
import ScriptsService from "../../../services/ScriptsService";
import {ScriptsEditProjectFunc} from "./functions/ScriptsEditProjectFunc";
import ScriptsDownloadForm from "../Scripts/ScriptsDownloadForm";
import ScriptsRenameForm from "../Scripts/ScriptsRenameForm";
import ScriptsDuplForm from "../Scripts/ScriptsDuplForm";
import ScriptsMoveForm from "../Scripts/ScriptsMoveForm";
import ScriptsDelForm from "../Scripts/ScriptsDelForm";
import {useFetching} from "../../../hooks/useFetching";
import RubricsService from "../../../services/RubricsService";


const ScriptsEditHeader = ({id, replicId, setReplicId, setFirstReplicId, updTrigger, setUpdTrigger, notifications, setNotifications}) => {

    //Данные скрипта
    const nullItem = {
        name: ''
    };

    //
    const [item, setItem] = useState(nullItem);


    //Дополнительные списки
    const [rubricsList, setRubricsList] = useState([]);

    //Модальные окна
    const [modalDownload, setModalDownload] = useState(false);
    const [modalRename, setModalRename] = useState(false);
    const [modalDupl, setModalDupl] = useState(false);
    const [modalMove, setModalMove] = useState(false);
    const [modalDel, setModalDel] = useState(false);


    //Состояние меню
    const [submenu, setSubmenu] = useState(false);

    //Открытие меню
    const submenuToggle = (e) => {

        if(!submenu)
        {
            setSubmenu(true);
        }
        else
        {
            setSubmenu(false);
        }
    }


    //Создать дубликат - по результату
    const duplItemRes = (duplId) => {
        //Ссылка на просмотр
        window.location.href = process.env.REACT_APP_BASE_URL + '/scripts/' + duplId + '/edit';
    }


    //Получение данных
    useEffect(() => {

        const getItem = async (id) => {

            try {
                //
                const response = await ScriptsService.getById(id);

                //
                if(response.data.status === 'ok')
                {
                    setItem(response.data.data);
                    setFirstReplicId(response.data.data.first_replic.id);
                }
            }
            catch (e) {
                //
                if(e.code === 'ERR_BAD_REQUEST')
                {
                    window.location.href = '/scripts';
                }
            }
        }

        getItem(id);

    }, [updTrigger, updTrigger]);


    //Получение доп списков
    const [fetchAdds, isAddsLoading, addsError] = useFetching(
        async () => {
            const responseAdd = await RubricsService.getAll(1, 3, 'id', 'asc');
            setRubricsList(responseAdd.data.data.items);
        }
    );

    useEffect(() => {
        fetchAdds();
    }, []);


    //Передача доп. списков
    const references = {
        rubrics: rubricsList,
    };


    //Вывод компонента
    return (
        <>


            <div id="p_editor_header">

                <Link to="/scripts" id="p_editor_header_back">
                    <img src={EditorBackImg} alt=""/>
                </Link>

                <Link to="/scripts" id="p_editor_header_logo">
                    <img src={LogoImg} alt=""/>
                </Link>

                <div id="p_editor_header_name_line">

                    <div id="p_editor_header_name_text">
                        {item.name}
                    </div>

                    <div id="p_editor_header_name_down_out">

                        <div id="p_editor_header_name_down" onClick={() => submenuToggle()}>
                            <img src={EditorDownImg} alt=""/>
                        </div>

                        <div id="p_editor_header_name_submenu">
                            <SubMenu active={submenu} setActive={setSubmenu}>
                                <ul>
                                    <li>
                                        <a
                                            href=""
                                            onClick={e => ScriptsEditProjectFunc.download(e, setModalDownload)}
                                            className="submenu_menu_with_img"
                                        >
                                            Скачать скрипт
                                            <img src={SubmenuPayImg} alt=""/>
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href=""
                                            onClick={e => ScriptsEditProjectFunc.share(e, id, notifications, setNotifications)}
                                        >Поделиться ссылкой</a>
                                    </li>
                                </ul>
                                <div className="submenu_menu_razd"></div>
                                <ul>
                                    <li>
                                        <a
                                            href=""
                                            onClick={e => ScriptsEditProjectFunc.rename(e, setModalRename)}
                                        >Переименовать скрипт</a>
                                    </li>
                                    <li>
                                        <a
                                            href=""
                                            onClick={e => ScriptsEditProjectFunc.dubl(e, setModalDupl)}
                                        >Создать дубликат</a>
                                    </li>
                                    <li>
                                        <a
                                            href=""
                                            onClick={e => ScriptsEditProjectFunc.move(e, setModalMove)}
                                        >Переместить в...</a>
                                    </li>
                                    <li>
                                        <a
                                            href=""
                                            onClick={e => ScriptsEditProjectFunc.del(e, setModalDel)}
                                        >Удалить скрипт</a>
                                    </li>
                                </ul>
                            </SubMenu>
                        </div>

                    </div>

                </div>

                <Link to={"/scripts/" + id + "/view"} id="p_editor_header_play">
                    <img src={EditorPlayImg}/>
                    Просмотр
                </Link>

            </div>


            <ScriptsDownloadForm
                modal={modalDownload}
                setModal={setModalDownload}
                tekItemId={id}
                setUpdTrigger={setUpdTrigger}
            />


            <ScriptsRenameForm
                modal={modalRename}
                setModal={setModalRename}
                tekItemId={id}
                setUpdTrigger={setUpdTrigger}
            />


            <ScriptsDuplForm
                modal={modalDupl}
                setModal={setModalDupl}
                tekItemId={id}
                setUpdTrigger={setUpdTrigger}
                resFun={duplItemRes}
            />


            <ScriptsMoveForm
                modal={modalMove}
                setModal={setModalMove}
                tekItemId={id}
                setUpdTrigger={setUpdTrigger}
                references={references}
            />


            <ScriptsDelForm
                modal={modalDel}
                setModal={setModalDel}
                tekItemId={id}
                setUpdTrigger={setUpdTrigger}
            />


        </>


    );
};

export default ScriptsEditHeader;