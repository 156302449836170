import React, {useState, useEffect} from 'react';
import ReplicsService from "../../../services/ReplicsService";
import ScriptsViewWorkAreaReplicLoad from "./ScriptsViewWorkAreaReplicLoad";
import ScriptsViewWorkAreaAnswersList from "./ScriptsViewWorkAreaAnswersList";
import {NotificationsAdd} from "../../../utils/NotificationsAdd";
import CopyImg from "../../../assets/img/Copy.svg";

const ScriptsViewWorkAreaReplic = ({id, replicId, setReplicId, firstReplicId, notifications, setNotifications}) => {

    //Данные реплики
    const nullItem = {
        name: '',
        desc: '',
        childs_replics: [],
    };

    //
    const [item, setItem] = useState(nullItem);

    //Загрузка реплики
    const [isItemLoading, setIsAItemLoading] = useState(true);


    //Получение данных
    useEffect(() => {

        const getItem = async (id) => {

            try {
                //
                const response = await ReplicsService.getById(id, 'childs_replics');

                //
                if(response.data.status === 'ok')
                {
                    setItem(response.data.data);

                    setIsAItemLoading(false);
                }
            }
            catch (e) {
                //
            }
        }

        getItem(replicId);

    }, [replicId, replicId]);


    //Переход к первой реплике
    const goFirstReplic = (e) => {
        e.preventDefault();
        setReplicId(firstReplicId);
    }


    //Копирование реплики
    const copyReplic = (e, text) => {
        e.preventDefault();

        //Копирование в буфер
        navigator.clipboard.writeText(text);

        //Вывод уведомления
        NotificationsAdd(
            notifications,
            setNotifications,
            'Реплика менеджера скопирована в буфер обмена .',
            'ok'
        );
    }


    //Выбор реплики
    const selectReplic = (e, id) => {
        e.preventDefault();
        setReplicId(id)
    }

    //Передача функций
    const func = {
        select: selectReplic
    }


    //Вывод компонента
    return (
        <>
            {
                isItemLoading
                    ?
                    <ScriptsViewWorkAreaReplicLoad />
                    :
                    <>


                        <div className="p_view_center_item">

                            <div className="p_view_center_item_title">
                                {item.name}
                                <div
                                    onClick={e => copyReplic(e, item.desc)}
                                    className="p_view_center_item_copy"
                                >
                                    <img src={CopyImg} alt=""/>
                                </div>
                            </div>

                            <div className="p_view_center_item_desc" dangerouslySetInnerHTML={{__html: item.desc}}>
                            </div>

                            {
                                replicId !== firstReplicId
                                    ?
                                    <a
                                        href=""
                                        className="p_view_center_item_back"
                                        onClick={e => goFirstReplic(e)}
                                    >
                                        Вернуться к началу разговора
                                    </a>
                                    :
                                    <></>
                            }

                            <div className="p_view_center_item_answers">

                                <div className="p_view_center_item_answers_list">

                                    <ScriptsViewWorkAreaAnswersList
                                        items={item.childs_replics}
                                        func={func}
                                    />

                                </div>

                            </div>


                        </div>


                    </>
            }
        </>
    );
};

export default ScriptsViewWorkAreaReplic;