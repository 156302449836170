import React, {useState, useEffect} from 'react';
import {useFetching} from "../../../hooks/useFetching";
import BonusesOperationsList from "./BonusesOperationsList";
import OperationsService from "../../../services/OperationsService";
import BonusesOperationsViewType1 from "./BonusesOperationsViewType1";
import BonusesOperationsViewType2 from "./BonusesOperationsViewType2";

const BonusesOperations = (updTrigger) => {


    //Параметры выборки
    const [items, setItems] = useState([]);


    //Параметры редактора
    const [tekItemId, setTekItemId] = useState(0);


    //Модальные окна
    const [modalViewType1, setModalViewType1] = useState(false);
    const [modalViewType2, setModalViewType2] = useState(false);


    //Определение выборки списка
    const [fetchItems, isItemsLoading, itemsError] = useFetching(
        async () => {
            const response = await OperationsService.getAll(1, 100, 'id', 'desc', {}, '', '', 'user');
            setItems(response.data.data.items);
        }
    );


    //Инициализация выборки списка
    useEffect(() => {
        fetchItems();
    }, [
        updTrigger,
        updTrigger
    ]);


    //Список типов операций
    const typesList = [
        ['0', ''],
        ['1', 'Получение средств'],
        ['2', 'Вывод средств'],
    ];


    //Список татусов
    const statusList = [
        ['0', ''],
        ['1', 'Отклонено'],
        ['2', 'В процессе'],
        ['3', 'Готово'],
    ];


    //Передача доп. списков
    const references = {
        typesList: typesList,
        statusList: statusList,
    };


    //Просмотр записи
    const viewItemInit = (type, id) => {

        //ID записи
        setTekItemId(id);

        //Тип записи
        if(type === 1)
        {
            setModalViewType1(true);
        }
        else
        if(type === 2)
        {
            setModalViewType2(true);
        }

    }


    //Передача функций
    const func = {
        view: viewItemInit,
    }


    //Вывод компонента
    return (
        <>

            {
                itemsError
                    ?
                    <div>Произошла ошибка ${itemsError}</div>
                    :
                    <></>
            }


            <BonusesOperationsList
                items={items}
                isLoading={isItemsLoading}
                func={func}
                references={references}
            />


            <BonusesOperationsViewType1
                modal={modalViewType1}
                setModal={setModalViewType1}
                tekItemId={tekItemId}
                references={references}
            />


            <BonusesOperationsViewType2
                modal={modalViewType2}
                setModal={setModalViewType2}
                tekItemId={tekItemId}
                references={references}
            />


        </>
    );
};

export default BonusesOperations;