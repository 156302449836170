import React from 'react';

const ManagementBonusesContent = () => {
    return (
        <div>
            ManagementBonusesContent
        </div>
    );
};

export default ManagementBonusesContent;