export const ItemsCountTitle = (count = 0, titles = []) => {

    //
    return itemTitle(count, titles);
}

function itemTitle(number, titles) {

    if (number > 10 && [11, 12, 13, 14].includes(number%100)) return titles[0];

    let last_num = number%10;

    if (last_num === 1) return titles[1];

    if ([2,3,4].includes(last_num)) return titles[2];

    if ([5,6,7,8,9, 0].includes(last_num)) return titles[3];
}