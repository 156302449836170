import React from 'react';
import BonusesUsersItem from "./BonusesUsersItem";

const BonusesUsersList = ({items, isLoading}) => {

    //
    if (!items.length && !isLoading) {
        return (
            <div>Записи не найдены!</div>
        )
    }


    //Вывод компонента
    return (
        <div id="p_account_bonuses_table">

            <div className="table_wrap">

                <table cellPadding="0" cellSpacing="0" border="0">
                    <thead>
                    <tr>
                        <th>Фамилия и имя</th>
                        <th>Почта</th>
                        <th>Пополнения</th>
                        <th>Доход</th>
                        <th>Дата регистрации</th>
                    </tr>
                    </thead>
                    <tbody>
                        {
                            items.map(
                                (item, index) =>
                                    <BonusesUsersItem
                                        key={item.id}
                                        item={item}
                                    />
                            )
                        }
                    </tbody>
                </table>

            </div>

        </div>
    );
};

export default BonusesUsersList;