import React, {useState} from 'react';
import ImgBack from "../../../assets/img/p_login_back.svg";
import FormInputHide from "../../Interface/Forms/FormInputHide";
import {Errors422} from "../../../utils/Errors422";
import RegisterService from "../../../services/RegisterService";
import NotificationsList from "../../Interface/Notifications/NotificationsList";
import {NotificationsArrAdd} from "../../../utils/NotificationsAdd";

const RestoreStep3 = ({item, setItem, step, setStep}) => {


    //Уведомления
    const [notifications, setNotifications] = useState([]);


    const handleSubmit = async () => {

        //
        let errBeforeArr = [];
        let errAfterArr = [];


        //
        if(item.password === '')
        {
            errBeforeArr.push('Поле «Пароль» не заполнено');
        }

        //
        if(item.password_confirmation === '')
        {
            errBeforeArr.push('Поле «Повторите пароль» не заполнено');
        }

        //
        if( (item.password !== '') && (item.password_confirmation !== '') && (item.password !== item.password_confirmation) )
        {
            errBeforeArr.push('Поля «Пароль» и «Повторите пароль» не совпадают');
        }


        //
        if(errBeforeArr.length > 0)
        {
            NotificationsArrAdd(notifications, setNotifications, errBeforeArr, 'not1');
        }
        else
        {
            console.log(item);

            //
            try {
                const response = await RegisterService.setPass(item);

                if(response.data.status === 'ok')
                {
                    //Переход на следующий шаг
                    setStep(4);
                }
                else
                {
                    errAfterArr.push('Ошибка смены пароля.');
                }
            }
            catch (e) {
                Errors422(e, errAfterArr, 'Ошибка смены пароля.');
            }

        }

        //
        if(errAfterArr.length > 0)
        {
            NotificationsArrAdd(notifications, setNotifications, errAfterArr, 'not2');
        }

    }


    //Вернуться назад
    const goBack = () => {
        setStep(2);
    }


    //Вывод компонента
    return (
        <>

            <div id="p_login_back" onClick={ () => goBack() }>
                <img src={ImgBack} alt=""/>
            </div>

            <div id="p_login_form_title">
                Придумайте<br/>
                пароль
            </div>

            <div id="p_login_rec_form_body">

                <div className="field_tr">
                    <div className="field_td field_td1">

                        <div id="form_msg">

                            <div className="form_msg_title">При создании пароля мы рекомендуем:</div>

                            <div className="form_msg_text">
                                <ul>
                                    <li>Составить пароль минимум от 6 символов</li>
                                    <li>Указать минимум одну заглавную букву</li>
                                </ul>
                            </div>

                        </div>

                    </div>
                </div>

                <div className="field_tr">
                    <div className="field_td field_td1">
                        <div className="label">Пароль <span className="zv">*</span></div>
                        <FormInputHide
                            name="password"
                            value={item.password}
                            setValue={setItem}
                            item={item}
                        />
                    </div>
                </div>

                <div className="field_tr">
                    <div className="field_td field_td1">
                        <div className="label">Повторите пароль <span className="zv">*</span></div>
                        <FormInputHide
                            name="password_confirmation"
                            value={item.password_confirmation}
                            setValue={setItem}
                            item={item}
                        />
                    </div>
                </div>

                <div className="field_tr btn_tr">
                    <div className="field_td field_td1">

                        <input
                            type="submit"
                            className="btn"
                            value="Восстановить пароль"
                            onClick={ () => handleSubmit() }
                        />

                    </div>
                </div>

            </div>


            <NotificationsList
                items={notifications}
                setItems={setNotifications}
            />


        </>
    );
};

export default RestoreStep3;