import React, {useEffect, useState} from 'react';
import ModalWindow from "../../../Interface/ModalWindow/ModalWindow";
import UsersService from "../../../../services/UsersService";
import {InputChange} from "../../../../utils/InputChange";
import FormSuccess from "../../../Interface/FormMessage/FormSuccess";
import FormErrors from "../../../Interface/FormMessage/FormErrors";
import {Errors422} from "../../../../utils/Errors422";

const ManagementUsersEditForm = ({modal, setModal, tekItemId, setUpdTrigger, references, switchActionList}) => {

    //Данные сохранения
    const nullItem = {
        last_name: '',
        first_name: '',
        email: '',
        phone: '',
    };

    const [item, setItem] = useState(nullItem);

    const [success, setSuccess] = useState(false);
    const [errors, setErrors] = useState([]);


    //Получение данных
    useEffect(() => {

        if(modal)
        {
            const getItem = async (id) => {
                const response = await UsersService.getById(id);
                setItem(response.data.data);
            }
            getItem(tekItemId);
        }

    }, [modal, modal]);


    //Сохранение данных
    const handleSubmit = async () => {

        //Ошибки
        let errArr = [];

        //
        if(item.last_name === '')
        {
            errArr.push('Поле «Фамилия» не заполнено');
        }

        //
        if(item.first_name === '')
        {
            errArr.push('Поле «Имя» не заполнено');
        }

        //
        if(item.email === '')
        {
            errArr.push('Поле «Почта» не заполнено');
        }

        //
        if(item.phone === '')
        {
            errArr.push('Поле «Телефон» не заполнено');
        }


        //Отправка запроса
        if(errArr.length === 0)
        {
            try {
                //
                let response = await UsersService.updateById(tekItemId, item);

                if(response.data.status === 'ok')
                {
                    //
                    setSuccess(true);
                    setTimeout(
                        () => {
                            setSuccess(false);
                            setModal(false);
                        },
                        1500
                    );

                    //
                    setUpdTrigger( Date.now() );
                }
                else
                {
                    errArr.push('Ошибка сохранения пользователя.');
                }
            }
            catch (e) {
                Errors422(e, errArr, 'Ошибка сохранения пользователя.');
            }

        }

        //
        setErrors(errArr);
    }


    //Отмена сохранения
    const cancelSubmit = () => {
        setModal(false);
    }


    //Переключить действие
    const switchAction = (action) => {

        //
        setModal(false);

        //
        switch (action)
        {
            case 1:
                switchActionList.Email(true);
                break;

            case 2:
                switchActionList.Pass(true);
                break;

            case 3:
                switchActionList.AccessAdd(true);
                break;

            case 4:
                switchActionList.AccessReset(true);
                break;

            case 5:
                switchActionList.Tarif(true);
                break;

            case 6:
                switchActionList.Del(true);
                break;
        }

    }


    //Вывод компонента
    return (
        <ModalWindow
            title="Редактирование пользователя"
            active={modal}
            setActive={setModal}
            footer={
                {
                    leftBtn: {
                        title: 'Отменить',
                        func: cancelSubmit
                    },
                    rightBtn: {
                        title: 'Сохранить',
                        func: handleSubmit
                    }
                }
            }
        >

            <div id="p_manager_users_modal_edit">


                <FormSuccess ok={success} text="Данные сохранены." fading="true"/>

                <FormErrors errors={errors}/>


                <div className="field_tr">

                    <div className="field_td field_td2">
                        <div className="label">Фамилия</div>
                        <input
                            type="text"
                            name="last_name"
                            className="field"
                            value={item.last_name}
                            onChange={ e => setItem(InputChange(e, item)) }
                        />
                    </div>

                    <div className="field_td field_td2">
                        <div className="label">Имя</div>
                        <input
                            type="text"
                            name="first_name"
                            className="field"
                            value={item.first_name}
                            onChange={ e => setItem(InputChange(e, item)) }
                        />
                    </div>

                </div>


                <div className="field_tr">

                    <div className="field_td field_td2">
                        <div className="label">Почта</div>
                        <input
                            type="text"
                            name="email"
                            className="field"
                            value={item.email}
                            onChange={ e => setItem(InputChange(e, item)) }
                        />
                    </div>

                    <div className="field_td field_td2">
                        <div className="label">Телефон</div>
                        <input
                            type="text"
                            name="phone"
                            className="field"
                            value={item.phone}
                            onChange={ e => setItem(InputChange(e, item)) }
                        />
                    </div>

                </div>


                <div className="field_tr">
                    <div className="field_td field_td1">
                        <div className="label">Другие действия</div>

                        <div className="field_select_label">

                            {
                                item.status === 0
                                ?
                                    <div
                                        className="field_select_label_item"
                                        onClick={ () => switchAction(1) }>Подтвердить почту</div>
                                    :
                                    <></>
                            }

                            <div
                                className="field_select_label_item"
                                onClick={ () => switchAction(2) }>Смена пароля</div>

                            <div
                                className="field_select_label_item"
                                onClick={ () => switchAction(3) }>Продлить доступ</div>

                            {
                                new Date(item.date_end).getTime() > new Date().getTime()
                                ?
                                    <div
                                        className="field_select_label_item"
                                        onClick={ () => switchAction(4) }>Обнулить доступ</div>
                                    :
                                    <></>
                            }

                            <div
                                className="field_select_label_item"
                                onClick={ () => switchAction(5) }>Смена тарифа</div>

                            <div
                                className="field_select_label_item"
                                onClick={ () => switchAction(6) }>Удалить</div>

                        </div>

                    </div>
                </div>


            </div>

        </ModalWindow>
    );
};

export default ManagementUsersEditForm;