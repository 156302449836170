import React from 'react';
import AnswersListEditItemCloseImg from "../../../../../assets/img/answers_list_edit_item_close.svg";

const ScriptsEditLeftFormAnswersAddItem = ({item, func}) => {

    //
    if(item === '')
    {
        return (
            <></>
        );
    }


    //Вывод компонента
    return (
        <div className="answers_list_edit_item" value={item}>
            {item}
            <div
                className="answers_list_edit_item_close"
                onClick={e => func.del(e, item)}
            >
                <img src={AnswersListEditItemCloseImg} alt=""/>
            </div>
        </div>
    );
};

export default ScriptsEditLeftFormAnswersAddItem;