export const managementMenu = [
    {
        title: 'Аналитика',
        path: 'management/analytics'
    },
    {
        title: 'Пользователи',
        path: 'management/users'
    },
    {
        title: 'Тарификация',
        path: 'management/tariffs'
    },
    {
        title: 'Настройка баннеров',
        path: 'management/banners'
    },
    {
        title: 'Бонусная программа',
        path: 'management/bonuses'
    },
    {
        title: 'Шаблоны',
        path: 'management/templates'
    },
    {
        title: 'Промокоды',
        path: 'management/promo'
    },
    {
        title: 'Общие настройки',
        path: 'management/settings'
    },
];