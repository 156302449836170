import React, {useEffect, useContext, useState} from 'react';
import {AuthContext} from "../../../context/AuthContext";
import ModalWindow from "../../Interface/ModalWindow/ModalWindow";
import FormInputClear from "../../Interface/Forms/FormInputClear";
import {useFetching} from "../../../hooks/useFetching";
import ProfileService from "../../../services/ProfileService";
import {Errors422} from "../../../utils/Errors422";
import NotificationsList from "../../Interface/Notifications/NotificationsList";
import {NotificationsAdd, NotificationsArrAdd} from "../../../utils/NotificationsAdd";


const ProfileEdit = ({modal, setModal}) => {


    //Получение пользователя
    const {AppAuthStore} = useContext(AuthContext);


    //Получение данных
    const nullItem = {
        last_name: '',
        first_name: '',
        email: '',
        phone: '',
    };

    const [profile, setProfile] = useState(nullItem);

    const [fetchProfileById, isLoading, error] = useFetching(
        async (id) => {
            const response = await ProfileService.getById(id);
            setProfile(response.data.data);
        }
    );

    useEffect(() => {
        fetchProfileById(AppAuthStore.user.id);
    }, []);


    //Уведомления
    const [notifications, setNotifications] = useState([]);


    const handleSubmit = async () => {

        //
        let errBeforeArr = [];
        let errAfterArr = [];

        //
        if(profile.last_name === '')
        {
            errBeforeArr.push('Поле «Фамилия» не заполнено');
        }

        //
        if(profile.first_name === '')
        {
            errBeforeArr.push('Поле «Имя» не заполнено');
        }

        //
        if(profile.email === '')
        {
            errBeforeArr.push('Поле «Почта» не заполнено');
        }

        //
        if(profile.phone === '')
        {
            errBeforeArr.push('Поле «Телефон» не заполнено');
        }

        if(errBeforeArr.length > 0)
        {
            NotificationsArrAdd(notifications, setNotifications, errBeforeArr, 'not1');
        }
        else
        {
            //
            try {
                //
                const response = await ProfileService.updateById(AppAuthStore.user.id, profile);

                if(response.data.status === 'ok')
                {
                    //
                    setModal(false);

                    //
                    NotificationsAdd(notifications, setNotifications, 'Личные данные были успешно изменены!', 'ok');

                    //
                    setTimeout(
                        () => {
                            AppAuthStore.checkAuth();
                        },
                        1500
                    );
                }
                else
                {
                    errAfterArr.push('Ошибка сохранения.');
                }
            }
            catch (e) {
                Errors422(e, errAfterArr, 'Ошибка сохранения.', 1);
            }

        }

        //
        if(errAfterArr.length > 0)
        {
            NotificationsArrAdd(notifications, setNotifications, errAfterArr, 'not2');
        }

    }


    //Отмена сохранения
    const cancelSubmit = () => {
        setModal(false);
    }


    //Вывод компонента
    return (
        <>

            <ModalWindow
                title="Личные данные"
                active={modal}
                setActive={setModal}
                footer={
                    {
                        leftBtn: {
                            title: 'Отменить',
                            func: cancelSubmit
                        },
                        rightBtn: {
                            title: 'Сохранить',
                            func: handleSubmit
                        }
                    }
                }
            >

                <div id="p_account_personal_editdata">

                    <div className="field_tr">
                        <div className="field_td field_td2">
                            <div className="label">Фамилия <span className="zv">*</span></div>
                            <FormInputClear
                                name="last_name"
                                value={profile.last_name}
                                setValue={setProfile}
                                item={profile}
                            />
                        </div>

                        <div className="field_td field_td2">
                            <div className="label">Имя <span className="zv">*</span></div>
                            <FormInputClear
                                name="first_name"
                                value={profile.first_name}
                                setValue={setProfile}
                                item={profile}
                            />
                        </div>
                    </div>


                    <div className="field_tr">
                        <div className="field_td field_td2">
                            <div className="label">Почта <span className="zv">*</span></div>
                            <FormInputClear
                                name="email"
                                value={profile.email}
                                setValue={setProfile}
                                item={profile}
                            />
                        </div>

                        <div className="field_td field_td2">
                            <div className="label">Номер телефона <span className="zv">*</span></div>
                            <FormInputClear
                                name="phone"
                                value={profile.phone}
                                setValue={setProfile}
                                item={profile}
                            />
                        </div>
                    </div>


                </div>

            </ModalWindow>


            <NotificationsList
                items={notifications}
                setItems={setNotifications}
            />


        </>
    );
};

export default ProfileEdit;