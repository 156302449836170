import React, {useEffect, useState} from 'react';
import ScriptsEditLeftFormAnswersAddList from "./ScriptsEditLeftFormAnswersAddList";
import {InputChange} from "../../../../../utils/InputChange";

const ScriptsEditLeftFormAnswersAdd = ({name, value, setValue, item, items, placeholder}) => {

    //
    const inputSizeDef = 32;

    const [inputItem, setInputItem] = useState('');
    const [inputSize, setInputSize] = useState(inputSizeDef);

    //
    useEffect(() => {

        //Длина поля
        if(value === '')
        {
            setInputSize(inputSizeDef);
        }


    }, [
        value,
        value,
    ]);

    //Подсветка элемента добавления
    const highlighItem = (className, val) => {

        const highlighElem = document.querySelector('.' + className + '[value="' + val + '"]')

        if( highlighElem !== null )
        {
            highlighElem.classList.add('error');

            setTimeout(() => {
                highlighElem.classList.remove('error');
            }, 2000);
        }
    }


    //Формирование для списка
    let valueArr = [];

    if( (value !== undefined) && (value !== null) && (value !== '') )
    {
        //Преобразовать в массив
        const valueArrPre = value.split(',');


        //Убрать пробелы
        for (let index = 0; index < valueArrPre.length; ++index) {
            valueArrPre[index] = valueArrPre[index].replace(/^\s\s*/, '').replace(/\s\s*$/, '');
        }


        //Удалить повторы
        valueArr = valueArrPre.reduce((result, item) => {
            return result.includes(item) ? result : [... result, item];
        }, []);

    }


    //Функция установки значения
    const compSetValue = (newValueArr) => {

        let newValue = newValueArr.join(', ');

        if( (item === null) || (item === undefined) )
        {
            setValue(newValue);
        }
        else
        {
            setValue(InputChange({target: {name: name, value: newValue}}, item));
        }

    }

    //Удаление элемента
    const delItem = (e, val) => {

        //
        let index = valueArr.indexOf(val);
        if (index !== -1) {
            valueArr.splice(index, 1);
        }

        //
        compSetValue(valueArr);

        //
        if(valueArr.length === 0)
        {
            setInputSize(inputSizeDef);
        }
    }


    //Передача функций
    const func = {
        del: delItem,
    };


    //Устаговить фокус на элементе ввода
    const setFocus = (e) => {
        if(e.target.classList.contains('answers_list_edit'))
        {
            e.target.querySelector('input').focus();
        }
    }


    //Устаговить ширину элемента ввода
    const changeInputSize = (e) => {

        //
        let newSize = e.target.value.length;

        //
        if(newSize === 0)
        {
            if(valueArr.length > 0)
            {
                newSize = 1;
            }
            else
            {
                newSize = inputSizeDef;
            }
        }

        //
        setInputSize(newSize);
    }


    //Изменение элемента ввода
    const changeInput = (e) => {

        //Устаговить ширину элемента ввода
        changeInputSize(e);

        //Устаговить значение элемента ввода
        const InputValue = e.target.value;
        setInputItem(InputValue);

        //Добавить в список
        if(InputValue.indexOf(',') > 0)
        {
            //Получить значение без запятой
            const AddValue = InputValue.replace(',', '');

            //Очистить поле
            setInputItem('');
            setInputSize(1);

            //Проверка наличия
            if( valueArr.includes(AddValue) )
            {
                //Подсвет повтора поле ввода
                highlighItem('answers_list_edit_item', AddValue);
            }
            else
            {
                if( items.filter(item => item.name === AddValue).length > 0 )
                {
                    //Подсветка имеющегося ответа
                    highlighItem('p_editor_left_item_answers_item', AddValue);
                }
                else
                {
                    //Добавить значение в массив
                    valueArr.push(AddValue);
                    compSetValue(valueArr);
                }
            }

            //Длина поля
            if(valueArr.length === 0)
            {
                setInputSize(inputSizeDef);
            }

        }
    }


    //Вывод компонента
    return (
        <>

            <div className="field answers_list_edit" onClick={(e) => setFocus(e)}>

                <ScriptsEditLeftFormAnswersAddList
                    items={valueArr}
                    func={func}
                    noresult={placeholder}
                />

                <input
                    type="text"
                    name="input_in_list"
                    value={inputItem}
                    onChange={e => changeInput(e) }
                    autoComplete="off"
                    size={inputSize}
                    placeholder={
                        valueArr.length === 0
                            ?
                            placeholder
                            :
                            ''
                    }
                />

            </div>


        </>
    );
};

export default ScriptsEditLeftFormAnswersAdd;