import React, {useEffect, useState} from 'react';
import ScriptsTools from "./ScriptsTools";
import ScriptsList from "./ScriptsList";
import ScriptsService from "../../../services/ScriptsService";
import {useFetching} from "../../../hooks/useFetching";
import {getPageCount} from "../../../utils/Pages";
import ScriptsAddBtn from "./ScriptsAddBtn";
import Preloader from "../../Interface/Preloader";
import ScriptsRenameForm from "./ScriptsRenameForm";
import ScriptsDuplForm from "./ScriptsDuplForm";
import ScriptsMoveForm from "./ScriptsMoveForm";
import ScriptsDelForm from "./ScriptsDelForm";
import RubricsService from "../../../services/RubricsService";
import ScriptsAddForm from "./ScriptsAddForm";
import ScriptsShop from "./ScriptsShop";
import Banner from "../../Workarea/Banner/Banner";
import Tarif from "../../Workarea/Tarif/Tarif";
import EndlessScrolling from "../../Interface/Pagination/EndlessScrolling";
import ScriptsDownloadForm from "./ScriptsDownloadForm";
import NotificationsList from "../../Interface/Notifications/NotificationsList";
import {NotificationsAdd} from "../../../utils/NotificationsAdd";
import SettingsService from "../../../services/SettingsService";
import EndlessScrollingImitation from "../../Interface/Pagination/EndlessScrollingImitation";


const ScriptsContent = () => {


    //Параметры выборки
    const [items, setItems] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [maxHeight, setMaxHeight] = useState(600);


    //Параметры редактора
    const [tekItemId, setTekItemId] = useState(0);
    const [updTrigger, setUpdTrigger] = useState(0);
    const [updTriggerRubrics, setUpdTriggerRubrics] = useState(0);


    //Поиск
    const searchField = 'name,desc::';
    const [search, setSearch] = useState('');


    //Дополнительные списки
    const [references, setReferences] = useState({
        rubrics: [],
    });


    //Фильтры
    const [filters, setFilters] = useState({
        rubric_id: {v:'null', t:'='},
    });


    //Модальные окна
    const [modalDownload, setModalDownload] = useState(false);
    const [modalRename, setModalRename] = useState(false);
    const [modalDupl, setModalDupl] = useState(false);
    const [modalMove, setModalMove] = useState(false);
    const [modalDel, setModalDel] = useState(false);
    const [modalAdd, setModalAdd] = useState(false);


    //Уведомления
    const [notifications, setNotifications] = useState([]);


    //Получение настроек
    const [settings, setSettings] = useState({
        scripts_rubric_max: 3,
    });

    const [fetchSettings, isLoading, error] = useFetching(
        async () => {
            const response = await SettingsService.getSettings('scripts_rubric_max');
            setSettings(response.data.data);
        }
    );

    useEffect(() => {
        fetchSettings();
    }, []);


    //Получение доп списков
    const [fetchAdds, isAddsLoading, addsError] = useFetching(
        async () => {

            //Список рубрик
            const responseAdd = await RubricsService.getAll(1, settings.scripts_rubric_max, 'id', 'asc');

            //Передача доп. списков
            setReferences({
                rubrics: responseAdd.data.data.items
            });
        }
    );


    useEffect(() => {
        fetchAdds();
    }, [
        updTriggerRubrics
    ]);


    //Скачать скрипт
    const downloadItemInit = (e, id) => {
        e.preventDefault();
        setTekItemId(id);
        setModalDownload(true);
    }


    //Переименовать скрипт
    const renameItemInit = (e, id) => {
        e.preventDefault();
        setTekItemId(id);
        setModalRename(true);
    }


    //Создать дубликат
    const duplItemInit = (e, id) => {
        e.preventDefault();
        setTekItemId(id);
        setModalDupl(true);
    }


    //Переместить в...
    const moveItemInit = (e, id) => {
        e.preventDefault();
        setTekItemId(id);
        setModalMove(true);
    }

    //Удаление записи
    const delItemInit = (e, id) => {
        e.preventDefault();
        setTekItemId(id);
        setModalDel(true);
    }


    //Поделиться ссылкой
    const shareLink = (e, id) => {
        e.preventDefault();

        //Ссылка на просмотр
        const LinkStr = process.env.REACT_APP_BASE_URL + '/scripts/' + id + '/view';

        //Копирование в буфер
        navigator.clipboard.writeText(LinkStr);

        //Вывод уведомления
        NotificationsAdd(notifications, setNotifications, 'Ссылка на скрипт скопирована в буфер обмена.', 'ok');
    }


    //Передача функций
    const func = {
        download: downloadItemInit,
        rename: renameItemInit,
        dupl: duplItemInit,
        move: moveItemInit,
        del: delItemInit,
        share: shareLink,
    }


    //Создать скрипт
    const addItemInit = () => {
        setModalAdd(true);
    }


    //Смена страницы
    const changePage = (page) => {
        setPage(page);
    }


    //Переключить рубрику
    const switchRubric = (rubricId) => {
        setItems([]);
        setPage(1);
        setSearch('');
        setFilters({...filters, ...{['rubric_id']: {v:rubricId, t:'='} }});
    }


    //Определение выборки списка
    const [fetchItems, isItemsLoading, itemsError] = useFetching(

        async (page, limit) => {
            const response = await ScriptsService.getAll(page, limit, 'id', 'desc', filters, searchField + search);
            //setItems( items.concat(response.data.data.items) );
            setItems(response.data.data.items);
            const totalCount = response.data.data.total;
            setTotalPages(getPageCount(totalCount, limit));
        }
    );


    //Инициализация выборки списка
    useEffect(() => {
        fetchItems(page, 10000);
    }, [
        page,
        filters,
        search,
        updTrigger
    ]);


    //Переход на первую страницу при применении фильтров
    useEffect(() => {
        setMaxHeight(800);
        //setItems([]);
        //setPage(1);
    }, [
        filters,
        search,
        //updTrigger,
    ]);


    //Вывод компонента
    return (
        <>

            {
                itemsError
                    ?
                    <div>Произошла ошибка ${itemsError}</div>
                    :
                    <></>
            }


            <Banner
                place="1"
            />

            <Tarif
                msg="Действие вашего тарифного плана истекло. Для дальнейшей работы необходимо продлить подписку, чтобы снять ограничения работы платформы"
            />


            <div id="p_account_scripts">


                <ScriptsTools
                    references={references}
                    updTrigger={updTriggerRubrics}
                    setUpdTrigger={setUpdTriggerRubrics}
                    search={search}
                    setSearch={setSearch}
                    filters={filters}
                    switchRubric={switchRubric}
                    limitRubrics={settings.scripts_rubric_max}
                />


                <div id="p_account_scripts_line2">

                    <div id="p_account_scripts_list_out" style={ {maxHeight: maxHeight} }>

                        <div id="p_account_scripts_list">

                            <ScriptsAddBtn
                                funcAdd={addItemInit}
                            />

                            {
                                filters.rubric_id.v === 'null'
                                    ?
                                    <ScriptsShop />
                                    :
                                    <></>
                            }

                            <ScriptsList
                                items={items}
                                isLoading={isItemsLoading}
                                func={func}
                                references={references}
                            />

                        </div>

                    </div>

                </div>


                {
                    !isItemsLoading
                        ?
                        <EndlessScrollingImitation
                            maxHeight={maxHeight}
                            setMaxHeight={setMaxHeight}
                        />
                        :
                        <></>
                }

                {
                    isItemsLoading
                        ?
                        <Preloader />
                        :
                        <></>
                }


                <ScriptsDownloadForm
                    modal={modalDownload}
                    setModal={setModalDownload}
                    tekItemId={tekItemId}
                    setUpdTrigger={setUpdTrigger}
                />


                <ScriptsRenameForm
                    modal={modalRename}
                    setModal={setModalRename}
                    tekItemId={tekItemId}
                    setUpdTrigger={setUpdTrigger}
                />


                <ScriptsDuplForm
                    modal={modalDupl}
                    setModal={setModalDupl}
                    tekItemId={tekItemId}
                    setUpdTrigger={setUpdTrigger}
                />


                <ScriptsMoveForm
                    modal={modalMove}
                    setModal={setModalMove}
                    tekItemId={tekItemId}
                    setUpdTrigger={setUpdTrigger}
                    references={references}
                />


                <ScriptsDelForm
                    modal={modalDel}
                    setModal={setModalDel}
                    tekItemId={tekItemId}
                    setUpdTrigger={setUpdTrigger}
                />


                <ScriptsAddForm
                    modal={modalAdd}
                    setModal={setModalAdd}
                    tekItemId={tekItemId}
                    setUpdTrigger={setUpdTrigger}
                    references={references}
                />


                <NotificationsList
                    items={notifications}
                    setItems={setNotifications}
                />


            </div>

        </>
    );
};

export default ScriptsContent;