import React from 'react';

const ScriptsViewFooter = ({id}) => {
    return (
        <div>

        </div>
    );
};

export default ScriptsViewFooter;