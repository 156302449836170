import React, {useState, useEffect, useContext} from 'react';
import EditorCopy from '../../../../assets/img/p_editor_copy.svg';
import FormInputSearch from "../../../Interface/Forms/FormInputSearch";
import ScriptsEditLeftList from "./ScriptsEditLeftList";
import ReplicsService from "../../../../services/ReplicsService";
import {useFetching} from "../../../../hooks/useFetching";
import ScriptsEditLeftDelForm from "./ScriptsEditLeftDelForm";
import ScriptsEditLeftEditForm from "./ScriptsEditLeftEditForm";
import {ScriptsEditReplicFunc} from "../functions/ScriptsEditReplicFunc";
import ScriptsEditLeftAttachForm from "./ScriptsEditLeftAttachForm";
//import { DragDropContext } from 'react-beautiful-dnd';
import { DragDropContext } from '@hello-pangea/dnd';
import {ArrayMove} from "../../../../utils/ArrayMove";
import {MakeUpdateIndexArr} from "../../../../utils/MakeUpdateIndexArr";
import {NotificationsAdd, NotificationsArrAdd} from "../../../../utils/NotificationsAdd";
import {AuthContext} from "../../../../context/AuthContext";
import {Errors422} from "../../../../utils/Errors422";
import ScriptsEditLeftAddMultiForm from "./ScriptsEditLeftAddMultiForm";


const ScriptsEditLeft = ({id, replicId, setReplicId, firstReplicId, updTrigger, setUpdTrigger, notifications, setNotifications}) => {


    //Получение пользователя
    const {AppAuthStore} = useContext(AuthContext);
    const userId = AppAuthStore.user.id;


    //Поиск
    const searchField = 'name::';//,desc
    const [search, setSearch] = useState('');


    //Параметры выборки
    const [items, setItems] = useState([]);


    //Параметры редактора
    const [tekItemId, setTekItemId] = useState(0);


    //Фильтры
    const [filters, setFilters] = useState({
        project_id: {v:id, t:'='},
    });


    //Модальные окна
    const [modalDel, setModalDel] = useState(false);
    const [modalEdit, setModalEdit] = useState(false);
    const [modalAttach, setModalAttach] = useState(false);
    const [modalAddMulti, setAddMulti] = useState(false);

    //Определение выборки списка
    const [fetchItems, isItemsLoading, itemsError] = useFetching(
        async () => {
            const response = await ReplicsService.getAll(1, 1000, 'position', 'asc', filters, searchField + search, '', 'childs_replics');
            setItems(response.data.data.items);
        }
    );


    //Инициализация выборки списка
    useEffect(() => {
        fetchItems();
    }, [
        search,
        replicId,
        updTrigger,
    ]);


    //Передача сеттеров
    const setters = {
        setReplicId: setReplicId,
        setTekItemId: setTekItemId,
        setModalDel: setModalDel,
        setModalEdit: setModalEdit,
        setModalAttach: setModalAttach,
    }


    //Изменение ширины колонки
    const [initialPos,   setInitialPos] = useState(null);
    const [initialSize, setInitialSize] = useState(null);

    const initial = (e) => {
        let resizable = document.getElementById('p_editor_left');
        setInitialPos(e.clientX);
        setInitialSize(resizable.offsetWidth);
    }

    const resize = (e) => {
        let resizable = document.getElementById('p_editor_left');
        resizable.style.width = `${parseInt(initialSize) + parseInt(e.clientX - initialPos)}px`;
    }

    //Обновление порядка карточек
    const onDragEnd = (result) => {

        //
        const { destination, source, draggableId } = result;

        //
        if (!destination) {
            return;
        }

        //
        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        //
        const itemId = result.draggableId;
        const oldPos = result.source.index;
        const newPos = result.destination.index !== 0 ? result.destination.index : 1;


        //Обновляем локальный список
        setItems(
            ArrayMove(items, oldPos, newPos)
        );


        //Массив  для обновлении на сервере
        const updateArr = MakeUpdateIndexArr(items);

        //Обновляем список на сервере
        ReplicsService.positionsUpdate(id, updateArr);

        //Обновление
        setUpdTrigger( Date.now() );
    };


    //Создание новой реплики
    const addItem = async (e) => {

        e.preventDefault();

        let errAfterArr = [];

        try {
            let response = await ReplicsService.create({
                user_id: userId,
                project_id: id,
                name: 'default',
            });

            if(response.data.status === 'ok')
            {
                NotificationsAdd(notifications, setNotifications, 'Новая карточка ответа была успешна создана.', 'ok');

                //
                setReplicId(response.data.data.id);

                //
                setTimeout(
                    () => {
                        const listElem = document.getElementById('p_editor_left_list_out');
                        listElem.scrollTop = listElem.scrollHeight;
                    },
                    500
                );
            }
            else
            {
                errAfterArr.push('Ошибка создания карточки ответа.');
            }
        }
        catch (e) {
            Errors422(e, errAfterArr, 'Ошибка создания карточки ответа.');
        }

        if(errAfterArr.length > 0)
        {
            NotificationsArrAdd(notifications, setNotifications, errAfterArr, 'not2');
        }

    }


    //Удаление записи
    const addMultiItemInit = (e) => {
        e.preventDefault();
        setAddMulti(true);
    }


    //Вывод компонента
    return (
        <>

            <div id="p_editor_left">

                <div id="p_editor_left_search">

                    <div id="p_editor_left_search_form">

                        <FormInputSearch
                            name='search'
                            value={search}
                            setValue={setSearch}
                            placeholder="Искать по ответам"
                        />

                    </div>

                </div>


                <div id="p_editor_left_list_out" className="horizontal_scroll">

                    <DragDropContext onDragEnd={onDragEnd}>

                        <ScriptsEditLeftList
                            items={items}
                            isLoading={isItemsLoading}
                            func={ScriptsEditReplicFunc}
                            replicId={replicId}
                            setters={setters}
                            firstReplicId={firstReplicId}
                        />

                    </DragDropContext>

                </div>


                <div id="p_editor_left_add">

                    <a
                        href=""
                        onClick={ e => addMultiItemInit(e) }
                        id="p_editor_left_add_btn2"
                    >
                        <img src={EditorCopy}/>
                    </a>

                    <a
                        href=""
                        onClick={ e => addItem(e) }
                        id="p_editor_left_add_btn1"
                    >Создать новую карточку</a>

                </div>

            </div>

            <div id="p_editor_left_draggable" draggable="true" onDragStart={initial} onDrag={resize}></div>


            <ScriptsEditLeftDelForm
                modal={modalDel}
                setModal={setModalDel}
                tekItemId={tekItemId}
                setUpdTrigger={setUpdTrigger}
                notifications={notifications}
                setNotifications={setNotifications}
                setReplicId={setReplicId}
                firstReplicId={firstReplicId}
            />


            <ScriptsEditLeftEditForm
                modal={modalEdit}
                setModal={setModalEdit}
                userId={userId}
                projectId={id}
                tekItemId={tekItemId}
                updTrigger={updTrigger}
                setUpdTrigger={setUpdTrigger}
                notifications={notifications}
                setNotifications={setNotifications}
                firstReplicId={firstReplicId}
            />


            <ScriptsEditLeftAttachForm
                modal={modalAttach}
                setModal={setModalAttach}
                projectId={id}
                tekItemId={tekItemId}
                setUpdTrigger={setUpdTrigger}
                notifications={notifications}
                setNotifications={setNotifications}
                firstReplicId={firstReplicId}
            />


            <ScriptsEditLeftAddMultiForm
                modal={modalAddMulti}
                setModal={setAddMulti}
                userId={userId}
                projectId={id}
                setReplicId={setReplicId}
                setUpdTrigger={setUpdTrigger}
                notifications={notifications}
                setNotifications={setNotifications}
                firstReplicId={firstReplicId}
            />


        </>
    );
};

export default ScriptsEditLeft;