export const TabsFunc = (e, tabRubicSelector, tabBodySelector, classActtive) => {

    //
    e.preventDefault();


    //
    const thisElem = e.target;


    //
    let t_i =  getNodeIndex(thisElem);


    //
    const tabHeadElems = thisElem.parentNode.querySelectorAll(tabRubicSelector);
    for (let i = 0; i < tabHeadElems.length; i++) {
        tabHeadElems[i].classList.remove(classActtive);
    }

    tabHeadElems[t_i].classList.add('active');


    //
    const tabBodyElems = thisElem.parentNode.parentNode.parentNode.querySelectorAll(tabBodySelector);
    for (let i = 0; i < tabBodyElems.length; i++) {
        tabBodyElems[i].classList.remove(classActtive);
    }

    tabBodyElems[t_i].classList.add(classActtive);

}

const getNodeIndex = (elem) => {
    var c = elem.parentNode.children,
        i = c.length;

    while(i--)
        if( c[i] == elem )
            return i
}