import React from 'react';
import {Route, Routes} from "react-router-dom";
import {privateRoutes, privateRoutesWL, publicRoutes} from "../routes";
import Layout from "../layouts/Layout";
import UnauthorizedLayout from "../layouts/unauthorized/UnauthorizedLayout";
import NullLayout from "../layouts/null/NullLayout";

const AppRouter = () => {
    return (
        <Routes>

            <Route path="/" element={<Layout/>}>
                {
                    privateRoutes.map(
                        (route, index) =>
                            <Route path={route.path} element={route.element} key={index}/>
                    )
                }
            </Route>

            <Route path="/" element={<NullLayout/>}>
                {
                    privateRoutesWL.map(
                        (route, index) =>
                            <Route path={route.path} element={route.element} key={index}/>
                    )
                }
            </Route>

            <Route path="/" element={<UnauthorizedLayout/>}>
                {
                    publicRoutes.map(
                        (route, index) =>
                            <Route path={route.path} element={route.element} key={index}/>
                    )
                }
            </Route>

        </Routes>
    );
};

export default AppRouter;