import React, {useState} from 'react';
import ImgFieldHide1 from "../../../assets/img/field_hide.svg"
import ImgFieldHide2 from "../../../assets/img/field_hide_active.svg"
import {InputChange} from "../../../utils/InputChange";

const FormInputHide = ({name, value, setValue, item}) => {


    //Скрытие формы
    const [hide, setHide] = useState(1);


    //Скрытие поля
    const fieldHide = () => {
        setHide(1 - hide);
    }


    //Изменение значения
    const onChange = (e) => {

        if( (item === null) || (item === undefined) )
        {
            setValue(e.target.value);
        }
        else
        {
            setValue(InputChange(e, item));
        }

    }


    //Вывод компонента
    return (
        <div className="field_ico_out">
            <input
                type={hide === 1 ? 'password' : 'text'}
                name={name}
                className="field"
                value={value}
                onChange={ e => onChange(e) }
                autoComplete="off"
            />
            <div
                className="field_ico"
                onClick={() => fieldHide() }
            >
                <img src={hide === 1 ? ImgFieldHide1 : ImgFieldHide2} alt="" />
            </div>
        </div>
    );
};

export default FormInputHide;