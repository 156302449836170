import $api from "../http";

export default class ScriptsService {

    static async getAll(page = 1, limit = 100, sort = 'id', direction = 'asc', filters = {}, search = '', fields = '', withRel = '', withCount = '') {
        return await $api.get('/projects/list', {
            params: {
                page: page,
                limit: limit,
                sort: sort,
                direction: direction,
                filters: filters,
                search: search,
                with: withRel,
                withCount: withCount
            }
        });
    }

    static async create(data) {
        return await $api.post('/projects/create', data);
    }

    static async getById(id, withRel = '') {
        return await $api.get('/projects/' + id + '/read', {
            params: {
                with: withRel,
            }
        });
    }

    static async updateById(id, data) {
        return await $api.post('/projects/' + id + '/update', data);
    }

    static async deleteById(id) {
        return await $api.delete('/projects/' + id + '/delete');
    }

    static async duplicateById(id, data) {
        return await $api.post('/projects/' + id + '/duplicate', data);
    }

}