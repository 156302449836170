import React from 'react';
import {Navigate} from "react-router-dom";

const HomeContent = () => {
    return (
        <>
            <Navigate to="/scripts" replace />
        </>
    );
};

export default HomeContent;