import React, {useState, useEffect} from 'react';
import ImgClose from "../../../assets/img/Close.svg"
import BannersService from "../../../services/BannersService";
import ModalWindow from "../../Interface/ModalWindow/ModalWindow";


const Banner = ({place}) => {

    //
    const FILE_SERVER = process.env.REACT_APP_FILE_HOST;

    //Параметры выборки
    const nullItem = {
    };

    //
    const [item, setItem] = useState(nullItem);
    const [isItemLoading, setIsItemLoading] = useState(false);

    //Модальные окна
    const [modalContent, setModalContent] = useState(false);

    //Фильтр по месту размещения
    const filters = {
        place: {v:place, t:'='},
    };

    //Получение данных
    useEffect(() => {

        const getItem = async () => {
            const response = await BannersService.getAll(1, 1, 'id', 'desc', filters);

            if(response.data.data.total === 1)
            {
                if( localStorage.getItem('close_banner_' + response.data.data.items[0].id) !== '1')
                {
                    setItem(response.data.data.items[0]);
                }
            }
        }

        getItem();

    }, []);


    //Если баннер не найден
    if(item.id === undefined)
    {
        return '';
    }


    //Закрытие баннера
    const bannerClose = (bannerId) => {
        setItem(nullItem);
        localStorage.setItem('close_banner_' + bannerId, '1');
    }


    //Открытие модального окна
    const modalOpen = () => {
        setModalContent(true);
    }


    //Вывод компонента
    return (
        <>


            <div id="p_account_workarea_banner_out">

                <div id="p_account_workarea_banner" style={ {background: "linear-gradient(89deg, #" + item.bg1 + " 0%, #" + item.bg2 + " 100%)"} }>

                    <div id="p_account_workarea_banner_img">
                        {
                            item.img_path !== ''
                                ?
                                <img src={FILE_SERVER + '/' + item.img_path} alt="" />
                                :
                                <></>
                        }
                    </div>

                    <div id="p_account_workarea_banner_info">

                        <div id="p_account_workarea_banner_title" dangerouslySetInnerHTML={{ __html: item.name }}></div>

                        <div id="p_account_workarea_banner_desc" dangerouslySetInnerHTML={{ __html: item.desc }}></div>

                    </div>

                    <div id="p_account_workarea_banner_btn">
                        {
                            item.btn_link === '#modal'
                                ?
                                <div
                                    className="btn btn1"
                                    onClick={ () => modalOpen() }
                                >
                                    {item.btn_title}
                                </div>
                                :
                                <a href={item.btn_link} target="_blank" className="btn btn1">{item.btn_title}</a>
                        }
                    </div>

                    <div
                        id="p_account_workarea_banner_close"
                        onClick={ () => bannerClose(item.id) }
                    >
                        <img src={ImgClose} alt="" />
                    </div>

                </div>

            </div>


            <ModalWindow
                title={item.modal_title}
                active={modalContent}
                setActive={setModalContent}
            >
                <div id="p_account_bonuses_modal_about" dangerouslySetInnerHTML={{ __html: item.modal_content }}></div>
            </ModalWindow>

        </>
    );
};

export default Banner;