export const MakeUpdateIndexArr = function (inputArr) {

    //
    const outputArr = [];

    //
    for( let i = 0, len = inputArr.length; i < len; i++ ) {

        outputArr.push({
            'id': inputArr[i].id,
            'position': i
        });

    }

    //
    return outputArr;
};