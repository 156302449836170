export const getPageCount = (totalCount, limit) => {
    return Math.ceil(totalCount / limit)
}

export const getPagesArray = (totalPages, tekPage, razdCount, razdValue) => {

    //
    let result = [];
    let resultPartBeg = [];
    let resultPartMiddle = [];
    let resultPartEnd = [];

    //
    let resultPartBeg_start = 1;
    //let resultPartBeg_stop = 1 + razdCount;
    let resultPartBeg_stop = 1;

    //
    let resultPartMiddle_start = tekPage - razdCount;
    let resultPartMiddle_stop = tekPage + razdCount;

    //
    //let resultPartEnd_start = totalPages - razdCount;
    let resultPartEnd_start = totalPages;
    let resultPartEnd_stop = totalPages;

    //
    if(totalPages > 0)
    {
        //
        if(resultPartBeg_stop >= resultPartMiddle_start)
        {
            resultPartBeg_stop = resultPartMiddle_start - razdCount;
        }

        if(resultPartBeg_start === 1 && resultPartBeg_stop === 0)
        {
            resultPartBeg_stop = 1;
        }


        //
        if(resultPartMiddle_start < 1)
        {
            resultPartMiddle_start = 1;
        }

        if(resultPartMiddle_stop > totalPages)
        {
            resultPartMiddle_stop = totalPages;
        }


        //
        if( resultPartEnd_start <= resultPartMiddle_stop )
        {
            resultPartEnd_start = resultPartMiddle_stop + razdCount;
        }

        if(resultPartEnd_start === totalPages + 1 && resultPartEnd_stop === totalPages)
        {
            resultPartEnd_start = totalPages;
        }
    }


    //
    if(resultPartBeg_start > 0)
    {
        for (let i = resultPartBeg_start; i <= resultPartBeg_stop; i++)
        {
            resultPartBeg.push(i);
        }
    }


    //
    if(resultPartMiddle_start > 0)
    {
        for (let i = resultPartMiddle_start; i <= resultPartMiddle_stop; i++)
        {
            resultPartMiddle.push(i);
        }
    }


    //
    if(resultPartMiddle_start <= totalPages)
    {
        for (let i = resultPartEnd_start; i <= resultPartEnd_stop; i++)
        {
            resultPartEnd.push(i);
        }
    }


    //
    if(resultPartBeg.length > 0)
    {
        result = result.concat(resultPartBeg);
    }

    //
    if(resultPartMiddle.length > 0)
    {
        if(result.length > 0)
        {
            if(resultPartMiddle_start - resultPartBeg_stop > 1)
            {
                result.push(razdValue + ' ');
            }
        }

        result = result.concat(resultPartMiddle);
    }

    //
    if(resultPartEnd.length > 0)
    {
        if(result.length > 0)
        {
            if(resultPartEnd_start - resultPartMiddle_stop > 1)
            {
                result.push(' ' + razdValue);
            }
        }

        result = result.concat(resultPartEnd);
    }


    return result;
}