import React from 'react';
import { Editor } from '@tinymce/tinymce-react';

const FormTinyMceEditor = ({name, value, setValue, item, placeholder}) => {

    const handleEditorChange = (content, editor) => {

        //
        const newItem = {...item, ...{[name]: content}};

        //
        setValue(newItem);
    }

    //Вывод компонента
    return (
        <>

            <Editor
                apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
                init={{
                    height: 300,
                    language : 'ru',
                    menubar: 'edit view insert format',
                    plugins: 'preview importcss searchreplace autolink directionality visualblocks visualchars fullscreen link codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap emoticons',
                    toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent  | emoticons | numlist bullist | forecolor backcolor | fullscreen  preview save | link',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
                value={value}
                onEditorChange={handleEditorChange}
            />

        </>
    );
};

export default FormTinyMceEditor;