import React, {useContext} from 'react';
import {AuthContext} from "../context/AuthContext";
import {observer} from "mobx-react-lite"
import Header from "./Header";
import Footer from "./Footer";
import WorkArea from "./WorkArea";
import {Navigate} from "react-router-dom";


const Layout = () => {

    //
    const {AppAuthStore} = useContext(AuthContext);

    //
    if(!AppAuthStore.isCheckAuth)
    {
        return '';
    }


    //
    return (

        AppAuthStore.isAuth
            ?
            <div id="p_account_page">
                <div id="p_account_wrap">
                    <Header />
                    <WorkArea />
                    <Footer />
                </div>
            </div>
            :
            <Navigate to="/login" replace />

    );
};

export default observer(Layout);