import React, {useContext, useEffect} from 'react';
import {observer} from "mobx-react-lite";
import usePageTitle from "./hooks/usePageTitle";
import {AuthContext} from "./context/AuthContext";
import Preloader from "./components/Interface/Preloader";
import AppRouter from "./components/AppRouter";
import useRef from "./hooks/useRef";


function App() {

    //
    useRef();

    //
    document.title = usePageTitle('Sendly');


    //
    const {AppAuthStore} = useContext(AuthContext);


    //
    useEffect(() => {
        AppAuthStore.checkAuth();
    }, []);


    //
    if(AppAuthStore.isLoading)
    {
        return (
            <Preloader />
        );
    }


    //
    return (
        <AppRouter />
    );
}

export default observer(App);