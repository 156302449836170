import React, {useState, useEffect} from 'react';
import EditorCheckImg from '../../../../assets/img/p_editor_check.svg';
import EditorCheckActiveImg from '../../../../assets/img/p_editor_check_active.svg';
import EditorAttachImg from '../../../../assets/img/p_editor_attach.svg';
import EditorEditImg from '../../../../assets/img/p_editor_edit.svg';
import EditorDelImg from '../../../../assets/img/p_editor_del.svg';
import EditorRollDownImg from '../../../../assets/img/p_editor_roll_down.svg';
import EditorRollUpImg from '../../../../assets/img/p_editor_roll_up.svg';
import EditorAnswersImg from '../../../../assets/img/p_editor_answers.svg';
import EditorLinksImg from '../../../../assets/img/p_editor_links.svg';
import {SubText} from "../../../../utils/SubText";
import {ItemsCountTitle} from "../../../../utils/ItemsCountTitle";
import ScriptsEditLeftItemAnswersList from "./ScriptsEditLeftItemAnswersList";
//import { Draggable } from 'react-beautiful-dnd';
import { Draggable } from '@hello-pangea/dnd';

const ScriptsEditLeftItem = ({item, index, func, replicId, setters, firstReplicId}) => {

    //Состояние кариочки
    const [open, setOpen] = useState(false);


    //Открытие кариочки
    const openToggle = () => {

        if(!open)
        {
            setOpen(true);
        }
        else
        {
            setOpen(false);
        }
    }


    //Развернуть нижнюю часть карточки в "Начало разговора" после создания проекта
    useEffect(() => {

        if(item.id === firstReplicId && item.childs_replics_num === 0)
        {
            setOpen(true);
        }

    }, []);


    //Наведение курсора на тултип
    const tooltipMouseOver = (e) => {

        //Метка
        const TooltipLabelElem = e.target;

        //Тултип
        const TooltipBodyElem = e.target.querySelector('.p_editor_tooltip_out');

        if(TooltipBodyElem !== null)
        {
            //Получить координаты
            const domRect = TooltipLabelElem.getBoundingClientRect();
            const tooltipLeft = domRect.x - 104 + 'px';
            const tooltipTop = domRect.y  - TooltipBodyElem.offsetHeight - 10 + 'px';

            //Переместить тултип
            TooltipBodyElem.style.left = tooltipLeft;
            TooltipBodyElem.style.top = tooltipTop;
        }
    }


    //Передача функций
    const aFunc ={
        select: func.select,
    };

    //Передача сеттеров
    const aSetters = {
        setReplicId: setters.setReplicId,
    }


    //Вывод компонента
    return (
        <Draggable draggableId={ (item.id).toString() } index={index}>

            {
                (provided) => (


                    <div className="p_editor_left_item_out" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        <div
                            onClick={e => func.select(e, item.id, setters.setReplicId)}
                            onDoubleClick={e => func.edit(e, item.id, setters.setTekItemId, setters.setModalEdit)}
                            className={'p_editor_left_item' + ' ' + (item.id === replicId ? 'active' : '') + ' ' + (open === true ? 'open' : '')}
                        >


                            <div className="p_editor_left_item_info">

                                <div className="p_editor_left_item_title">
                                    {item.name}
                                </div>

                                <div className="p_editor_left_item_desc">
                                    {
                                        item.desc !== '' && item.desc !== null
                                            ?
                                            <>
                                                {SubText(item.desc, 90)}
                                            </>
                                            :
                                            <>
                                                Нажмите два раза, чтобы добавить описание или нажмите на «Карандаш» для
                                                редактирования
                                            </>
                                    }
                                </div>

                                {
                                    item.name !== '' && item.desc !== '' & item.desc !== null && item.childs_replics_num > 0
                                        ?
                                        <div className="p_editor_left_item_check">
                                            <img src={EditorCheckImg} alt=""/>
                                            <img src={EditorCheckActiveImg} alt=""/>
                                        </div>
                                        :
                                        <></>
                                }

                            </div>


                            <div className="p_editor_left_item_tools">

                                <div
                                    onMouseOver={e => tooltipMouseOver(e)}
                                    className="p_editor_left_item_tools_item p_editor_left_item_tools_index p_editor_tooltip_elem"
                                >

                                    {index + 1}

                                    <div className="p_editor_tooltip_out">
                                        <div className="p_editor_tooltip">
                                            Цифра показывает расположение
                                            карточки в списке ответа
                                        </div>
                                    </div>

                                </div>

                                <div className="p_editor_left_item_tools_list">

                                    <div
                                        className="p_editor_left_item_tools_item p_editor_left_item_tools_item_attach"
                                        onClick={e => func.attach(e, item.id, setters.setTekItemId, setters.setModalAttach)}
                                    >
                                        <img src={EditorAttachImg} alt=""/>
                                        Прикрепить
                                    </div>

                                    <div
                                        className="p_editor_left_item_tools_item p_editor_left_item_tools_item_edit"
                                        onClick={e => func.edit(e, item.id, setters.setTekItemId, setters.setModalEdit)}
                                    >
                                        <img src={EditorEditImg} alt=""/>
                                    </div>

                                    {
                                        item.id !== firstReplicId
                                            ?
                                            <div
                                                className="p_editor_left_item_tools_item p_editor_left_item_tools_item_del"
                                                onClick={e => func.del(e, item.id, setters.setTekItemId, setters.setModalDel)}
                                            >
                                                <img src={EditorDelImg} alt=""/>
                                            </div>
                                            :
                                            <></>
                                    }


                                </div>

                            </div>


                            <div className="p_editor_left_item_separator"></div>


                            <div className="p_editor_left_item_summary">

                                <div
                                    className="p_editor_left_item_summary_item p_editor_left_item_summary_item_roll p_editor_left_item_summary_item_roll_down"
                                    onClick={() => openToggle()}
                                >
                                    Развернуть
                                    <img src={EditorRollDownImg} alt=""/>
                                </div>

                                <div
                                    className="p_editor_left_item_summary_item p_editor_left_item_summary_item_roll p_editor_left_item_summary_item_roll_up"
                                    onClick={() => openToggle()}
                                >
                                    Свернуть
                                    <img src={EditorRollUpImg} alt=""/>
                                </div>

                                <div className="p_editor_left_item_summary_list">

                                    <div className="p_editor_left_item_summary_item">
                                        <img src={EditorAnswersImg} alt=""/>
                                        {item.childs_replics_num} {ItemsCountTitle(item.childs_replics_num, ['ответов', 'ответ', 'ответа', 'ответов'])}
                                    </div>

                                    <div className="p_editor_left_item_summary_item">
                                        <img src={EditorLinksImg} alt=""/>
                                        {item.parents_replics_num} {ItemsCountTitle(item.parents_replics_num, ['связей', 'связь', 'связи', 'связей'])}
                                    </div>

                                </div>

                            </div>


                            <div className="p_editor_left_item_answers">

                                <div className="p_editor_left_item_answers_list">

                                    {
                                        item.childs_replics_num === 0
                                            ?
                                            <a href=""
                                               onClick={e => func.edit(e, item.id, setters.setTekItemId, setters.setModalEdit)}
                                               className="p_editor_left_item_answers_item p_editor_left_item_answers_item_new"
                                            >
                                                + Новый ответ
                                            </a>
                                            :
                                            <></>
                                    }

                                    <ScriptsEditLeftItemAnswersList
                                        items={item.childs_replics}
                                        func={aFunc}
                                        setters={aSetters}
                                    />

                                </div>

                            </div>


                        </div>
                    </div>


                )
            }

        </Draggable>
    );
};

export default ScriptsEditLeftItem;