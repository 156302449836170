import $api from "../http";

export default class UsersService {

    static async getAll(page = 1, limit = 100, sort = 'id', direction = 'asc', filters = {}, search = '', fields = '') {
        return await $api.get('/users/list', {
            params: {
                page: page,
                limit: limit,
                sort: sort,
                direction: direction,
                filters: filters,
                search: search,
            }
        });
    }

    static async create(data) {
        return await $api.post('/users/create', data);
    }

    static async getById(id) {
        return await $api.get('/users/' + id + '/read');
    }

    static async updateById(id, data) {
        return await $api.post('/users/' + id + '/update', data);
    }

    static async deleteById(id) {
        return await $api.delete('/users/' + id + '/delete');
    }

    static async exportAll(page = 1, limit = 100, sort = 'id', direction = 'asc', filters = {}, search = '', fields = '') {
        return await $api.get('/users/export', {
            params: {
                page: page,
                limit: limit,
                sort: sort,
                direction: direction,
                filters: filters,
                search: search,
                fields: fields,
            },
            responseType: 'blob'
        });
    }

}