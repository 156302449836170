import React from 'react';

const ModalWindowFooter = ({obj}) => {
    return (
        <div className="modal_window_footer">

            {
                obj.leftBtn
                    ?
                    <div className="modal_window_footer_lbtn">
                        <div className="btn btn1 btn_c3" onClick={ () => obj.leftBtn.func() }>
                            {obj.leftBtn.title}
                        </div>
                    </div>
                    :
                    <></>
            }

            {
                obj.rightBtn
                    ?
                    <div className="modal_window_footer_rbtn">
                        <div className="btn btn1" onClick={ () => obj.rightBtn.func() }>
                            {obj.rightBtn.title}
                        </div>
                    </div>
                    :
                    <></>
            }

        </div>
    );
};

export default ModalWindowFooter;