import React from 'react';
import RestoreContent from "../../components/Pages/Restore/RestoreContent";


const RestorePage = () => {
    return (
        <RestoreContent />
    );
};

export default RestorePage;