import React, {useContext, useState} from 'react';
import ImgPanelItem1 from "../../../assets/img/p_account_bonuses_actions_item1_img.svg";
import {SumFormat} from "../../../utils/SumFormat";
import {AuthContext} from "../../../context/AuthContext";
import BonusesPanelItem1Form from "./BonusesPanelItem1Form";

const BonusesPanelItem1 = ({profit, setUpdTrigger}) => {


    //Модальные окна
    const [modalForm, setModalForm] = useState(false);


    //Увеличить % бонуса
    const formInit = (e) => {
        e.preventDefault();
        setModalForm(true);
    }


    //Получение пользователя
    const {AppAuthStore} = useContext(AuthContext);
    const user = AppAuthStore.user;


    //Вывод компонента
    return (
        <>

            <div className="p_account_bonuses_actions_item_out">
                <div className="p_account_bonuses_actions_item p_account_bonuses_actions_item1">
                    <div className="p_account_bonuses_actions_label">Заработано всего</div>
                    <div className="p_account_bonuses_actions_title">{SumFormat(profit)} ₽</div>
                    <div className="p_account_bonuses_actions_desc">Вознаграждение - <span>{user.ref_proc}%</span></div>
                    <div className="p_account_bonuses_actions_panel">
                        <a
                            href=""
                            onClick={e => formInit(e)}
                        >Увеличить % бонуса</a>
                    </div>
                    <div className="p_account_bonuses_actions_img">
                        <img src={ImgPanelItem1} alt=""/>
                    </div>
                </div>
            </div>

            <BonusesPanelItem1Form
                modal={modalForm}
                setModal={setModalForm}
                profit={profit}
                setUpdTrigger={setUpdTrigger}
            />

        </>
    );
};

export default BonusesPanelItem1;