import React from 'react';
import ModalWindow from "../../Interface/ModalWindow/ModalWindow";

const BonusesPanelItem3Form = ({modal, setModal, setUpdTrigger}) => {


    //Сохранение данных
    const handleSubmit = async () => {

        alert('Сохранение данных');

    }


    //Отмена
    const cancelSubmit = () => {
        setModal(false);
    }


    //Вывод компонента
    return (
        <>

            <ModalWindow
                title="Увеличить % бонуса"
                active={modal}
                setActive={setModal}
                footer={
                    {
                        leftBtn: {
                            title: 'Отменить',
                            func: cancelSubmit
                        },
                        rightBtn: {
                            title: 'Увеличить % бонуса',
                            func: handleSubmit
                        }
                    }
                }
            >
                <div id="p_account_scripts_modal_download">

                    <div className="p_account_bonuses_modal_info">#BonusesPanelItem3Form#</div>

                </div>

            </ModalWindow>

        </>
    );
};

export default BonusesPanelItem3Form;