import React, {useState} from 'react';
import SubMenu from "../Interface/SubMenu/SubMenu";
import UserMenu from "./UserMenu";
import UpanelUser from "./UpanelUser";


const Upanel = () => {

    //Состояние меню
    const [submenu, setSubmenu] = useState(false);


    //Открытие меню
    const submenuToggle = (e) => {

        if(!submenu)
        {
            setSubmenu(true);
        }
        else
        {
            setSubmenu(false);
        }
    }


    //Вывод компонента
    return (
        <div id="p_account_upanel">

            <div id="p_account_upanel_user" onClick={ () => submenuToggle() }>
                <UpanelUser />
            </div>

            <SubMenu active={submenu} setActive={setSubmenu}>
                <UserMenu />
            </SubMenu>

        </div>
    );
};

export default Upanel;