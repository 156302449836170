import React, {useState} from 'react';
import ModalWindow from "../../Interface/ModalWindow/ModalWindow";
import {SumFormat} from "../../../utils/SumFormat";
import {InputChange} from "../../../utils/InputChange";
import {Errors422} from "../../../utils/Errors422";
import OperationsService from "../../../services/OperationsService";
import NotificationsList from "../../Interface/Notifications/NotificationsList";
import {NotificationsAdd, NotificationsArrAdd} from "../../../utils/NotificationsAdd";

const BonusesPanelItem2Form = ({modal, setModal, available, setUpdTrigger}) => {


    //Данные сохранения
    const nullItem = {
        sum: '',
        pasport_num: '',
        pasport_who: '',
        pasport_fio: '',
        pasport_birth: '',
        pasport_adres: '',
        bank_name: '',
        bank_bik: '',
        bank_inn: '',
        bank_kor: '',
        bank_ras: '',
    };

    const [item, setItem] = useState(nullItem);


    //Уведомления
    const [notifications, setNotifications] = useState([]);


    //Сохранение данных
    const handleSubmit = async () => {

        //
        let errBeforeArr = [];
        let errAfterArr = [];


        //
        if(item.sum === '')
        {
            errBeforeArr.push('Необходимо ввести сумму вывода');
        }

        //
        if(item.sum !== '' && item.sum < 1000)
        {
            errBeforeArr.push('Минимально возможная сумма вывода - 1 000 ₽');
        }

        //
        if(item.sum > available)
        {
            errBeforeArr.push('Указанная сумма на вывод превышает доступный лимит');
        }

        //
        if(
            item.pasport_num === ''
            ||
            item.pasport_who === ''
            ||
            item.pasport_fio === ''
            ||
            item.pasport_birth === ''
            ||
            item.pasport_adres === ''
        )
        {
            errBeforeArr.push('Необходимо полностью заполнить паспортные данные');
        }

        //
        if(
            item.bank_name === ''
            ||
            item.bank_bik === ''
            ||
            item.bank_inn === ''
            ||
            item.bank_kor === ''
            ||
            item.bank_ras === ''
        )
        {
            errBeforeArr.push('Необходимо полностью заполнить банковские реквизиты');
        }


        if(errBeforeArr.length > 0)
        {
            NotificationsArrAdd(notifications, setNotifications, errBeforeArr, 'not1');
        }
        else
        {
            try {
                //
                let response = await OperationsService.createRequest(item);

                //
                if(response.data.status === 'ok')
                {
                    //
                    setModal(false);

                    //
                    NotificationsAdd(notifications, setNotifications, 'Запрос на вывод средств успешно создан!', 'ok');

                    //
                    setUpdTrigger( Date.now() );
                }
                else
                {
                    errAfterArr.push('Ошибка создания! Проверьте корректность заполненных данных');
                }
            }
            catch (e) {
                Errors422(e, errAfterArr, 'Ошибка создания! Проверьте корректность заполненных данных', 1);
            }

        }

        //
        if(errAfterArr.length > 0)
        {
            NotificationsArrAdd(notifications, setNotifications, errAfterArr, 'not2');
        }

    }


    //Отмена
    const cancelSubmit = () => {
        setModal(false);
    }


    //Вывод компонента
    return (
        <>

            <ModalWindow
                title="Запрос на вывод средств"
                active={modal}
                setActive={setModal}
                footer={
                    {
                        leftBtn: {
                            title: 'Отменить',
                            func: cancelSubmit
                        },
                        rightBtn: {
                            title: 'Создать заявку на вывод',
                            func: handleSubmit
                        }
                    }
                }
            >

                <div className="p_account_bonuses_modal_info">

                    <div className="modal_info_label">Доступно: <span>{ SumFormat(available) } ₽</span></div>
                    <div className="modal_info_title">Сумма вывода</div>
                    <div className="modal_info_desc">Минимально доступная сумма вывода - 1 000 ₽</div>

                    <div className="modal_info_body">


                        <div className="field_tr">
                            <div className="field_td field_td1">

                                <input
                                    type="text"
                                    name="sum"
                                    className="field"
                                    value={item.sum}
                                    onChange={ e => setItem(InputChange(e, item)) }
                                    placeholder="Введите необходимую сумму"
                                />

                            </div>
                        </div>

                        <div className="modal_info_razd"></div>

                        <div className="modal_info_title">Паспортные данные</div>
                        <div className="modal_info_desc">Необходимы для составления агентского договора</div>

                        <div className="field_tr">
                            <div className="field_td field_td2">

                                <input
                                    type="text"
                                    name="pasport_num"
                                    className="field"
                                    value={item.pasport_num}
                                    onChange={ e => setItem(InputChange(e, item)) }
                                    placeholder="Серия и номер"
                                />

                            </div>
                            <div className="field_td field_td2">

                                <input
                                    type="text"
                                    name="pasport_who"
                                    className="field"
                                    value={item.pasport_who}
                                    onChange={ e => setItem(InputChange(e, item)) }
                                    placeholder="Кем выдан"
                                />

                            </div>
                        </div>

                        <div className="field_tr">
                            <div className="field_td field_td2">

                                <input
                                    type="text"
                                    name="pasport_fio"
                                    className="field"
                                    value={item.pasport_fio}
                                    onChange={ e => setItem(InputChange(e, item)) }
                                    placeholder="ФИО"
                                />

                            </div>
                            <div className="field_td field_td2">

                                <input
                                    type="text"
                                    name="pasport_birth"
                                    className="field"
                                    value={item.pasport_birth}
                                    onChange={ e => setItem(InputChange(e, item)) }
                                    placeholder="Год рождения"
                                />

                            </div>
                        </div>

                        <div className="field_tr">
                            <div className="field_td field_td1">

                                <input
                                    type="text"
                                    name="pasport_adres"
                                    className="field"
                                    value={item.pasport_adres}
                                    onChange={ e => setItem(InputChange(e, item)) }
                                    placeholder="Адрес прописки"
                                />

                            </div>
                        </div>

                        <div className="modal_info_razd"></div>

                        <div className="modal_info_title">Банковские реквизиты</div>
                        <div className="modal_info_desc">Необходимы для вывода на расчетный счет в банке</div>

                        <div className="field_tr">
                            <div className="field_td field_td3">

                                <input
                                    type="text"
                                    name="bank_name"
                                    className="field"
                                    value={item.bank_name}
                                    onChange={ e => setItem(InputChange(e, item)) }
                                    placeholder="Наименование банка"
                                />

                            </div>
                            <div className="field_td field_td3">

                                <input
                                    type="text"
                                    name="bank_bik"
                                    className="field"
                                    value={item.bank_bik}
                                    onChange={ e => setItem(InputChange(e, item)) }
                                    placeholder="БИК банка"
                                />

                            </div>
                            <div className="field_td field_td3">

                                <input
                                    type="text"
                                    name="bank_inn"
                                    className="field"
                                    value={item.bank_inn}
                                    onChange={ e => setItem(InputChange(e, item)) }
                                    placeholder="ИНН банка"
                                />

                            </div>
                        </div>

                        <div className="field_tr">
                            <div className="field_td field_td2">

                                <input
                                    type="text"
                                    name="bank_kor"
                                    className="field"
                                    value={item.bank_kor}
                                    onChange={ e => setItem(InputChange(e, item)) }
                                    placeholder="Корреспондентский счет"
                                />

                            </div>
                            <div className="field_td field_td2">

                                <input
                                    type="text"
                                    name="bank_ras"
                                    className="field"
                                    value={item.bank_ras}
                                    onChange={ e => setItem(InputChange(e, item)) }
                                    placeholder="Расчетный счет"
                                />

                            </div>
                        </div>

                    </div>

                </div>

            </ModalWindow>


            <NotificationsList
                items={notifications}
                setItems={setNotifications}
            />

        </>
    );
};

export default BonusesPanelItem2Form;