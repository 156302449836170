import React, {useState} from 'react';
import RestoreStep1 from "./RestoreStep1";
import RestoreStep2 from "./RestoreStep2";
import RestoreStep3 from "./RestoreStep3";
import RestoreStep4 from "./RestoreStep4";

const RestoreContent = () => {


    //Получение данных
    const nullItem = {
        email: '',
        password: '',
        password_confirmation: '',
    };

    const [item, setItem] = useState(nullItem);


    //Шаги восстановления
    const [step, setStep] = useState(1);


    //Вывод компонента
    return (
        <div id="p_login_rec">

            <div id="p_login_rec_fon"></div>

            <div id="p_login_rec_form">
                {
                    step === 1
                        ?
                        <RestoreStep1
                            item={item}
                            setItem={setItem}
                            step={step}
                            setStep={setStep}
                        />
                        :
                        <></>
                }
                {
                    step === 2
                        ?
                        <RestoreStep2
                            item={item}
                            setItem={setItem}
                            step={step}
                            setStep={setStep}
                        />
                        :
                        <></>
                }
                {
                    step === 3
                        ?
                        <RestoreStep3
                            item={item}
                            setItem={setItem}
                            step={step}
                            setStep={setStep}
                        />
                        :
                        <></>
                }
                {
                    step === 4
                        ?
                        <RestoreStep4
                            item={item}
                            setItem={setItem}
                            step={step}
                            setStep={setStep}
                        />
                        :
                        <></>
                }
            </div>

            <div id="p_login_form_copy">
                ⓒ 2023
            </div>

        </div>
    );
};

export default RestoreContent;