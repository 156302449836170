import React, {Component} from 'react';
import Logo from '../assets/img/Logo.svg';
import MainMenu from "../components/Header/MainMenu";
import Upanel from "../components/Header/Upanel";


class Header extends Component {
    render() {

        return (
            <div id="p_account_header_line" className="blockline">
                <div id="p_account_header" className="block">

                    <a href="/" id="p_account_logo">
                        <img src={Logo} />
                    </a>

                    <MainMenu />

                    <Upanel />

                </div>
            </div>
        );

    }
}

export default Header;