import React, {useContext, useState} from 'react';
import {AuthContext} from "../../../context/AuthContext";
import {observer} from "mobx-react-lite"
import ImgUserAvatar from "../../../assets/images/users/1.jpg";
import ProfileEdit from "./ProfileEdit";
import ProfilePass from "./ProfilePass";


const ProfileContent = () => {

    //Получение пользователя
    const {AppAuthStore} = useContext(AuthContext);
    const user = AppAuthStore.user;

    //Параметры редактора
    const [updTrigger, setUpdTrigger] = useState(0);

    //Модальные окна
    const [modalEdit, setModalEdit] = useState(false);
    const [modalPass, setModalPass] = useState(false);


    //Редактирование профиля
    const editItemInit = () => {
        setModalEdit(true);
    }


    //Смена Пароля
    const passItemInit = () => {
        setModalPass(true);
    }


    //Вывод компонента
    return (
        <div id="p_account_personal">

            <div id="p_account_personal_in">

                <div id="p_account_personal_photo_line">
                    <div id="p_account_personal_photo">
                        <img src={ImgUserAvatar} alt="" />
                    </div>
                </div>

                <div id="p_account_personal_name">
                    {user.first_name} {user.last_name}
                </div>

                <div id="p_account_personal_email">
                    {user.username}
                </div>

                <div id="p_account_personal_btns">

                    <div
                        className="btn btn_c1"
                        onClick={ () => editItemInit() }
                    >Личные данные</div>

                    <div
                        className="btn btn_c4"
                        onClick={ () => passItemInit() }
                    >Изменить пароль</div>

                </div>

            </div>

            <ProfileEdit
                modal={modalEdit}
                setModal={setModalEdit}
            />

            <ProfilePass
                modal={modalPass}
                setModal={setModalPass}
            />

        </div>
    );
};

export default observer(ProfileContent);