import React, {useState} from 'react';
import {useParams} from "react-router-dom";
import ScriptsEditHeader from "./ScriptsEditHeader";
import ScriptsEditLeft from "./ScriptsEditLeft/ScriptsEditLeft";
import ScriptsEditRight from "./ScriptsEditRight/ScriptsEditRight";
import ScriptsEditWorkArea from "./ScriptsEditWorkArea/ScriptsEditWorkArea";
import ScriptsEditFooter from "./ScriptsEditFooter";
import NotificationsList from "../../Interface/Notifications/NotificationsList";

const ScriptsEditContent = () => {

    //Получить ID скрипта
    const {id} = useParams();

    //Выбранная реплика
    const [replicId, setReplicId] = useState(0);

    //Первая реплика
    const [firstReplicId, setFirstReplicId] = useState(0);

    //Параметры редактора
    const [updTrigger, setUpdTrigger] = useState(0);

    //Уведомления
    const [notifications, setNotifications] = useState([]);

    //Вывод компонента
    return (
        <div id="p_editor_page">

            <ScriptsEditHeader
                id={id}
                replicId={replicId}
                setReplicId={setReplicId}
                setFirstReplicId={setFirstReplicId}
                updTrigger={updTrigger}
                setUpdTrigger={setUpdTrigger}
                notifications={notifications}
                setNotifications={setNotifications}
            />

            <ScriptsEditLeft
                id={id}
                replicId={replicId}
                setReplicId={setReplicId}
                firstReplicId={firstReplicId}
                updTrigger={updTrigger}
                setUpdTrigger={setUpdTrigger}
                notifications={notifications}
                setNotifications={setNotifications}
            />

            <ScriptsEditRight
                id={id}
                replicId={replicId}
                setReplicId={setReplicId}
                firstReplicId={firstReplicId}
                updTrigger={updTrigger}
                setUpdTrigger={setUpdTrigger}
                notifications={notifications}
                setNotifications={setNotifications}
            />

            <ScriptsEditWorkArea
                id={id}
                replicId={replicId}
                setReplicId={setReplicId}
                firstReplicId={firstReplicId}
                updTrigger={updTrigger}
                setUpdTrigger={setUpdTrigger}
                notifications={notifications}
                setNotifications={setNotifications}
            />

            <ScriptsEditFooter
                id={id}
                replicId={replicId}
            />

            <NotificationsList
                items={notifications}
                setItems={setNotifications}
            />

        </div>
    );
};

export default ScriptsEditContent;