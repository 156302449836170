export const mainMenu = [
    {
        title: 'Скрипты',
        icon: <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.66671 6.66659H6.00004C5.06662 6.66659 4.59991 6.66659 4.24339 6.84824C3.92979 7.00803 3.67482 7.263 3.51503 7.5766C3.33337 7.93312 3.33337 8.39983 3.33337 9.33325V13.9999C3.33337 14.9333 3.33337 15.4 3.51503 15.7566C3.67482 16.0702 3.92979 16.3251 4.24339 16.4849C4.59991 16.6666 5.06662 16.6666 6.00004 16.6666H10.6667C11.6001 16.6666 12.0668 16.6666 12.4234 16.4849C12.737 16.3251 12.9919 16.0702 13.1517 15.7566C13.3334 15.4 13.3334 14.9333 13.3334 13.9999V13.3333M9.33337 13.3333H14C14.9335 13.3333 15.4002 13.3333 15.7567 13.1516C16.0703 12.9918 16.3253 12.7368 16.4851 12.4232C16.6667 12.0667 16.6667 11.6 16.6667 10.6666V5.99992C16.6667 5.0665 16.6667 4.59979 16.4851 4.24327C16.3253 3.92966 16.0703 3.6747 15.7567 3.51491C15.4002 3.33325 14.9335 3.33325 14 3.33325H9.33337C8.39995 3.33325 7.93324 3.33325 7.57672 3.51491C7.26312 3.6747 7.00815 3.92966 6.84836 4.24327C6.66671 4.59979 6.66671 5.0665 6.66671 5.99992V10.6666C6.66671 11.6 6.66671 12.0667 6.84836 12.4232C7.00815 12.7368 7.26312 12.9918 7.57672 13.1516C7.93324 13.3333 8.39995 13.3333 9.33337 13.3333Z" stroke="#100F32" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/></svg>,
        path: 'scripts',
        admin: 0
    },
    {
        title: 'Тарифы и оплата',
        icon: <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15 6.66659V5.99992C15 5.0665 15 4.59979 14.8183 4.24327C14.6586 3.92966 14.4036 3.6747 14.09 3.51491C13.7335 3.33325 13.2668 3.33325 12.3333 3.33325H5.16667C4.23325 3.33325 3.76654 3.33325 3.41002 3.51491C3.09641 3.6747 2.84144 3.92966 2.68166 4.24327C2.5 4.59979 2.5 5.0665 2.5 5.99992V6.66659M17.5 9.99992H15.8333C14.9129 9.99992 14.1667 10.7461 14.1667 11.6666C14.1667 12.5871 14.9129 13.3333 15.8333 13.3333H17.5M2.5 6.66659V13.9999C2.5 14.9333 2.5 15.4 2.68166 15.7566C2.84144 16.0702 3.09641 16.3251 3.41002 16.4849C3.76654 16.6666 4.23325 16.6666 5.16667 16.6666H14.8333C15.7668 16.6666 16.2335 16.6666 16.59 16.4849C16.9036 16.3251 17.1586 16.0702 17.3183 15.7566C17.5 15.4 17.5 14.9333 17.5 13.9999V9.33325C17.5 8.39983 17.5 7.93312 17.3183 7.5766C17.1586 7.263 16.9036 7.00803 16.59 6.84824C16.2335 6.66659 15.7668 6.66659 14.8333 6.66659H2.5Z" stroke="#100F32" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/></svg>,
        path: 'tariffs',
        admin: 0
    },
    {
        title: 'Бонусная программа',
        icon: <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.3333 7.49992L9.99996 3.33325L1.66663 7.49992L9.99996 11.6666L18.3333 7.49992ZM18.3333 7.49992V12.4999M15.8333 8.74992V13.7499L9.99996 16.6666L4.16663 13.7499V8.74992" stroke="#100F32" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/></svg>,
        path: 'bonuses',
        admin: 0
    },
    {
        title: 'Управление',
        icon: <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.5 14.1667H12.5M2.5 12.1667V10.1085C2.5 9.1512 2.5 8.67257 2.62338 8.23179C2.73267 7.84134 2.91228 7.47411 3.15338 7.14813C3.42556 6.78013 3.80337 6.48628 4.55898 5.89858L6.72565 4.21339C7.89674 3.30255 8.48228 2.84713 9.12884 2.67206C9.69934 2.5176 10.3007 2.5176 10.8712 2.67206C11.5177 2.84713 12.1033 3.30255 13.2743 4.21339L15.441 5.89858C16.1966 6.48628 16.5744 6.78013 16.8466 7.14813C17.0877 7.47411 17.2673 7.84134 17.3766 8.23179C17.5 8.67257 17.5 9.1512 17.5 10.1085V12.1667C17.5 14.0335 17.5 14.9669 17.1367 15.68C16.8171 16.3072 16.3072 16.8171 15.68 17.1367C14.9669 17.5 14.0335 17.5 12.1667 17.5H7.83333C5.96649 17.5 5.03307 17.5 4.32003 17.1367C3.69282 16.8171 3.18289 16.3072 2.86331 15.68C2.5 14.9669 2.5 14.0335 2.5 12.1667Z" stroke="#100F32" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/></svg>,
        path: 'management',
        admin: 1
    },
];